import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ArrowLeft from "../../Icons/ArrowLeft";
import ResultCardFlightDirection from "./ResultCardFlightDirection";

import classes from "./ResultModule.module.css";

const Confirmation = (props) => {
  //const [storageData, setStorageData] = useState({});
  //const [pnrData, setPnrData] = useState();
  //const [bookData, setBookData] = useState({});
  //const [cartData, setCartData] = useState();

  const buyerInfo = useSelector((state) => state.form.buyerInfo);
  const storageData = useSelector((state) => state.form.storeReservation);
  const cartData = useSelector((state) => state.form.cartDetails);
  const pnrData = useSelector((state) => state.form.pnrResponse);

  console.log("buyerinfo", buyerInfo);
  console.log("storeReservation", storageData);
  console.log("cartData", cartData);
  console.log("pnrData", pnrData);

  /*
  const buyerInfo = {
    companyName: "Neka firma d.o.o.",
    pib: "112188885",
    address1: "Jevrejska 10",
    address2: "21000 Novi Sad, Srbija",
    mobile: "0641733488",
    phone: "0641733488",
    email: "e-mail@domain.com",
  };
*/

  /*
  useEffect(() => {
    const storageFetch = async () => {
      /*
      const confirmationResponse = await fetch("/storage-response.json");
      const confirmationData = await confirmationResponse.json();
      const resp = JSON.parse(confirmationData.data).Content;
      setStorageData(resp);
      

      const bookResponse = await fetch("/book-read.json");
      const bookData = await bookResponse.json();
      //console.log("book read", bookData);
      setBookData(bookData);

      /*
      const pnrResponse = await fetch("/pnr-read.json");
      const pnrData = await pnrResponse.json();
      console.log("pnr read", pnrData);
      //setPnrData(pnrData);
      setCartData(pnrData);

      const cartResponse = await fetch("/cart-details.json");
      const cartData = await cartResponse.json();
      console.log("cart read", cartData);
      //setCartData(cartData);
      setPnrData(cartData.ShoppingCart);
    };
    storageFetch();
  }, []);
  */

  const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
  const processEmailString = (obj) => {
    if (!obj) return;
    return obj.split(" ").map((el, ind) => {
      if (el.match(emailRegex)) {
        return (
          <Link
            key={ind}
            to="#"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "mailto:" + el;
            }}
          >
            {el + " "}
          </Link>
        );
      } else {
        return <span key={ind}>{el + " "}</span>;
      }
    });
  };

  // flight data
  let depInfo, divider;
  let arrInfo = <div style={{ height: "0.5rem" }}></div>;

  if (pnrData) {
    depInfo = (
      <ResultCardFlightDirection
        airlineLogo={
          pnrData.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments[0]
            .AirlineLogo
        }
        departureDate={
          pnrData.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments[0]
            .DepartureDate
        }
        arrivalDate={
          pnrData.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments[
            pnrData.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments
              .length - 1
          ].ArrivalDate
        }
        depAirport={
          pnrData.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments[0]
            .DepartureAirport
        }
        arrAirport={
          pnrData.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments[
            pnrData.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments
              .length - 1
          ].ArrivalAirport
        }
        midAirport={pnrData.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments.map(
          (el, ind) => ind + 1 + "." + el.ArrivalAirport + " "
        ).slice(0, -1)}
        departSegments={
          pnrData.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments
            .length
        }
        departJourneyTotalDuration={
          pnrData.Air.AirItinerary.OriginDestinationOptions[0]
            .JourneyTotalDuration
        }
        cabinLuggage={
          pnrData.Air.AirItinerary.OriginDestinationOptions[0].Cabin ===
          "Business"
            ? 2
            : 1
        }
        baggageInfo={
          pnrData.Air.AirItineraryPricingInfo.PTC_FareBreakdowns.length
          ? pnrData.Air.AirItineraryPricingInfo.PTC_FareBreakdowns[0].Baggages
          : null

        }
      />
    );
    if (pnrData.Air.AirItinerary.OriginDestinationOptions.length > 1) {
      divider = <div className={classes.dashedDivider}></div>;
      arrInfo = (
        <ResultCardFlightDirection
          povratak={true}
          // return flight

          airlineLogo2={
            pnrData.Air.AirItinerary.OriginDestinationOptions[1]
              .FlightSegments[0].AirlineLogo
          }
          departureDate2={
            pnrData.Air.AirItinerary.OriginDestinationOptions[1]
              .FlightSegments[0].DepartureDate
          }
          arrivalDate2={
            pnrData.Air.AirItinerary.OriginDestinationOptions[1].FlightSegments[
              pnrData.Air.AirItinerary.OriginDestinationOptions[1]
                .FlightSegments.length - 1
            ].ArrivalDate
          }
          depAirport2={
            pnrData.Air.AirItinerary.OriginDestinationOptions[1]
              .FlightSegments[0].DepartureAirport
          }
          arrAirport2={
            pnrData.Air.AirItinerary.OriginDestinationOptions[1].FlightSegments[
              pnrData.Air.AirItinerary.OriginDestinationOptions[1]
                .FlightSegments.length - 1
            ].ArrivalAirport
          }
          midAirport2={pnrData.Air.AirItinerary.OriginDestinationOptions[1].FlightSegments.map(
            (el, ind) => ind + 1 + "." + el.ArrivalAirport + " "
          ).slice(0, -1)}
          arrivalSegments={
            pnrData.Air.AirItinerary.OriginDestinationOptions[1].FlightSegments
              .length
          }
          arriveJourneyTotalDuration={
            pnrData.Air.AirItinerary.OriginDestinationOptions[1]
              .JourneyTotalDuration
          }
          cabinLuggage={
            pnrData.Air.AirItinerary.OriginDestinationOptions[0].Cabin ===
            "Business"
              ? 2
              : 1
          }
        baggageInfo={
          pnrData.Air.AirItineraryPricingInfo.PTC_FareBreakdowns.length
          ? pnrData.Air.AirItineraryPricingInfo.PTC_FareBreakdowns[0].Baggages
          : null
          
        }
        />
      );
    }
  }

  const passengerTypes = useMemo(() => {
    return {
      ADT: ["Odrasli", "Odrasli", 1],
      CHD: ["Dete", "Deca", 2],
      INF: ["Beba", "Bebe", 3],
    };
  }, []);

  // travelers
  let travelers;
  if (pnrData) {
    travelers = pnrData.Travellers.map((el, ind) => {
      const dobSplit = el.DateOfBirthString.split("/");
      const day = dobSplit[1] < 10 ? "0" + dobSplit[1] : dobSplit[1];
      const mon = dobSplit[0] < 10 ? "0" + dobSplit[0] : dobSplit[0];
      return (
        <div key={ind} className={classes.travelerRow + " travelerRow"}>
          <span>
            {passengerTypes[el.TypeCode][0]} / {el.Gender ? "M" : "Ž"}
          </span>
          <span>
            {el.FirstName}&nbsp;{el.LastName}
          </span>
          <span>
            {day}.{mon}.{dobSplit[2]}
          </span>
        </div>
      );
    });
  }

  let detailsRender, totalPriceRender, totalPriceRaw;

  if (pnrData) {
    //cartDetails = pnrData;
    detailsRender = pnrData.Air.AirItineraryPricingInfo.PTC_FareBreakdowns.map(
      (el, ind) => {
        return (
          <div
            className={classes.priceHolder}
            key={ind}
            style={ind === 0 ? { marginTop: 0 } : null}
          >
            <div className={classes.priceHeadline + " optionsHeadline bold"}>
              Broj karata: {passengerTypes[el.PassengerType][1]} x{" "}
              {el.PassengerCount}
            </div>
            <div className={classes.priceSummary + " darkText light"}>
              <span>Cena karte</span>
              <span>
                {el.BasePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                ,00 {pnrData.Air.AirItineraryPricingInfo.CurrencyCode}
              </span>
            </div>
            <div className={classes.priceSummary + " darkText light"}>
              <span>Takse</span>
              <span>
                {el.Tax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")},00{" "}
                {pnrData.Air.AirItineraryPricingInfo.CurrencyCode}
              </span>
            </div>
            <div className={classes.priceSummary + " darkText light"}>
              <span>Ukupno</span>
              <span>
                {el.TotalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                ,00 {pnrData.Air.AirItineraryPricingInfo.CurrencyCode}
              </span>
            </div>
          </div>
        );
      }
    );
    totalPriceRaw = pnrData.Air.AirItineraryPricingInfo.TotalPrice;
    totalPriceRender =
      totalPriceRaw.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
      ",00 " +
      pnrData.Air.AirItineraryPricingInfo.CurrencyCode;
  }

  return (
    <React.Fragment>
      <div className={classes.confirmationHolder + " confirmation"}>
        <div
          className={[classes.backButton + " backButton"]}
          //onClick={handleFormReset}
        >
          <span style={{ marginRight: "-6px", transform: "scaleX(-1)" }}>
            <ArrowLeft />
          </span>
          <span>
            <ArrowLeft />
          </span>
        </div>
        <div className={classes.resultCard + " resultCard grayBg"}>
          <div className={classes.confirmationSection}>
            <h3>{storageData.title}</h3>
            <h1>{pnrData.BookingReferenceNumber}</h1>
            <p>{processEmailString(storageData.email_info)}</p>
            <p>{processEmailString(storageData.contact_info)}</p>
            <h3>Pregled letova</h3>
          </div>
          {depInfo}
          {divider}
          {arrInfo}
          <div className={classes.confirmationSection}>
            <h3>Pregled putnika</h3>
          </div>
          {travelers}
          <div className={classes.confirmationSection}>
            <h3>Obračun cene</h3>
          </div>
          {detailsRender}
          <div
            className={
              classes.totalPriceHolder + " thinBorderTop totalPrice bold"
            }
            style={{ marginBottom: 0 }}
          >
            <span>Ukupno za naplatu</span>
            <span>{totalPriceRender}</span>
          </div>
          {buyerInfo ? (
            <div className={classes.confirmationSection}>
              <h3>Informacije o kupcu</h3>
              <div className={classes.buyerInfoHolder + " darkText light"}>
                {buyerInfo.companyName.length ? (
                  <div className={classes.buyerInfo}>
                    <span>Firma:</span>
                    <span>{buyerInfo.companyName}</span>
                  </div>
                ) : null}
                {buyerInfo.pib.length ? (
                  <div className={classes.buyerInfo}>
                    <span>PIB:</span>
                    <span>{buyerInfo.pib}</span>
                  </div>
                ) : null}
                <div className={classes.buyerInfo}>
                  <span>Adresa:</span>
                  <span>
                    {buyerInfo.address1}
                    {buyerInfo.address2.length ? ", " + buyerInfo.address2 : null}
                  </span>
                </div>
                <div className={classes.buyerInfo}>
                  <span>Mobilni telefon:</span>
                  <span>{buyerInfo.mobile}</span>
                </div>
                <div className={classes.buyerInfo}>
                  <span>Telefon:</span>
                  <span>{buyerInfo.phone}</span>
                </div>
                <div className={classes.buyerInfo}>
                  <span>e-mail:</span>
                  <span>{buyerInfo.email}</span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Confirmation;
