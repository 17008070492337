import React from "react";

import classes from "./DateInput.module.css";

const DateInput = (props) => {
  return (
    <div id="dateInputHolder" className={classes.inputHolder + " " + props.addClass}>
      <div className={classes.inputContainer}>
        <span>Dan</span>
        <input
          type="number"
          value={props.dayValue}
          autoComplete="off"
          min={1}
          max={31}
          name="day"
          onChange={props.inputChangeHandler}
          maxLength={2}
          placeholder="20"
        />
      </div>
      <div className={classes.inputContainer}>
        <span>Mesec</span>
        <input
          type="number"
          value={props.monthValue}
          autoComplete="off"
          min={1}
          max={12}
          name="month"
          onChange={props.inputChangeHandler}
          maxLength={2}
          placeholder="10"
        />
      </div>
      <div className={classes.inputContainer}>
        <span>Godina</span>
        <input
          type="number"
          value={props.yearValue}
          autoComplete="off"
          min={1900}
          max={new Date().getFullYear()}
          onChange={props.inputChangeHandler}
          name="year"
          maxLength={4}
          placeholder="1980"
        />
      </div>
    </div>
  );
};

export default DateInput;
