import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { setInfoWindow } from "../../store/ui-slice";

import Button from "./Button";

import classes from "./InfoModal.module.css";

const portalElement = document.getElementById("infoOverlay");

const Backdrop = (props) => {
  return <div className={classes.Backdrop} onClick={props.closeInfoModal} />;
};

const InfoModal = (props) => {
  const infoWindowMessage = useSelector((state) => state.ui.infoWindow);
  const infoWindowRefresh = useSelector((state) => state.ui.infoWindowRefresh);
  const dispatch = useDispatch();

  const closeInfoModalHandler = () => {
    dispatch(setInfoWindow(false));
    if (infoWindowRefresh) {
      //window.location.reload(true);
      document.location.href="/";
      return false;
    }
  };

  useEffect( () => {
    document.body.classList.add('noscroll');
    document.getElementById('infoOverlay').classList.add('active');
    
    return function cleanup () {
      document.body.classList.remove('noscroll');
      document.getElementById('infoOverlay').classList.remove('active');
    }
  }, [])

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <React.Fragment>
          <Backdrop closeInfoModal={closeInfoModalHandler} />
          <div className={classes.infoHolder}>
            <div id="infoWindow" className={classes.infoWindow}>
              <div id="infoWindowMessage" className={classes.infoWindowMessage}>{infoWindowMessage}</div>
              <Button onClick={closeInfoModalHandler} filled className={classes.infoButton}>
                OK
              </Button>
            </div>
          </div>
        </React.Fragment>,
        portalElement
      )}
    </React.Fragment>
  );
};

export default InfoModal;
