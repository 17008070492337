import React from "react";
import ArrowLeft from "../../../Icons/ArrowLeft";
import ArrowRight from "../../../Icons/ArrowRight";

import { useSwipeable } from "react-swipeable";

import "./PassangerChooserObject.css";

const PassangerChooserObject = (props) => {
  let displayNr = "00";
  if (props.data !== null) {
    displayNr = props.data < 10 ? "0" + props.data : props.data;
  }

  const decreaseHandler = () => {
    props.onChangePassanger(-1);
  };

  const increaseHandler = () => {
    props.onChangePassanger(1);
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir==="Right") {
        increaseHandler();
      } else if (eventData.dir==="Left") {
        decreaseHandler();
      }
    },
  });

  return (
    <div {...handlers} className="passangerChooserObject">
      <div className="passangerChooserObject_title bold">{props.title}</div>
      <div className="passangerChooserObject_controls">
        <div
          className="passangerChooserObject_controls_arrowLeft"
          onClick={decreaseHandler.bind(this)}
        >
          <ArrowLeft />
        </div>
        <div className="passangerChooserObject_controls_number oswald bold text-highlighted">
          {displayNr}
        </div>
        <div
          className="passangerChooserObject_controls_arrowRight"
          onClick={increaseHandler.bind(this)}
        >
          <ArrowRight />
        </div>
      </div>
    </div>
  );
};

export default PassangerChooserObject;
