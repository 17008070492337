import React from "react";

import "./VerticalDivider.css";

const VerticalDivider = (props) => {
  let classSelector = "verticalDivider";
  if (props.secondary) {
    classSelector += " secondary";
  }
  return <div className={classSelector}></div>;
};

export default VerticalDivider;
