import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeFormPosition } from "../../../store/ui-slice";

import ArrowLeft from "../../Icons/ArrowLeft";
import GivenLuggageIcon from "../../Icons/GivenLuggageIcon";
import FlightDetail from "./FlightDetail";

import classes from "./ResultModule.module.css";

const FlightDetails = () => {

	const dispatch = useDispatch();

	const flightData = useSelector((state) => state.form.flightDetails);

	const departureFlightSegmentData = flightData.AirItinerary.OriginDestinationOptions[0].FlightSegments;

	let returnFlightSegmentData;
	if (flightData.AirItinerary.OriginDestinationOptions.length > 1) {
		returnFlightSegmentData = flightData.AirItinerary.OriginDestinationOptions[1].FlightSegments;
	}	

	const [airportNames, setAirportNames] = useState({});

	/* airport shortname service */
	const getAirportNames = useCallback(async () => {
		const departureAirportsStrings = flightData.AirItinerary.OriginDestinationOptions[0].FlightSegments.map(el => el.DepartureAirport + "," + el.ArrivalAirport).slice(0, -1);
		let returnAirportsStrings = "";
		if (flightData.AirItinerary.OriginDestinationOptions.length > 1) {
			returnAirportsStrings = flightData.AirItinerary.OriginDestinationOptions[1].FlightSegments.map(el => el.DepartureAirport + "," + el.ArrivalAirport);
		}
		const singleString = departureAirportsStrings.concat(returnAirportsStrings).join(',').split(',');
		const singleStringArr = [...new Set(singleString)];
		const airportURL = "https://"+process.env.REACT_APP_API_URL+"/api/getAirportsInfo?airports=" + singleStringArr.join(',');
		const airportNiceNamesResponse = await fetch(airportURL);
		const airportNiceNames = await airportNiceNamesResponse.json();
		setAirportNames(airportNiceNames);
	}, [flightData.AirItinerary.OriginDestinationOptions]);

	useEffect(() => {
		getAirportNames();
	}, [getAirportNames]);


	let classType;
	let cabinLuggage = 1;
	let givenBaggage;


	if (departureFlightSegmentData[0].Cabin === "Economy") {
		classType = "Ekonomska klasa"
	}
	if (departureFlightSegmentData[0].Cabin === "Business") {
		classType = "Biznis klasa"
		cabinLuggage = 2;
	}
	if (departureFlightSegmentData[0].Cabin === "Premium") {
		classType = "Premium klasa"
	}
	if (departureFlightSegmentData[0].Cabin === "First") {
		classType = "Prva klasa"
	}

	if (flightData.AirItineraryPricingInfo.PTC_FareBreakdowns[0].Baggages.length) {
		const type = flightData.AirItineraryPricingInfo.PTC_FareBreakdowns[0].Baggages[0].BagAllowanceType;
		// const unit = props.baggageInfo[0].BagAllowanceUnit;
		const quant = flightData.AirItineraryPricingInfo.PTC_FareBreakdowns[0].Baggages[0].FreeQuantity;
		if (parseInt(quant)) {
			let bagOutput;
			if (type === "Piece") {
				bagOutput = quant + "x23kg";
			} else {
				bagOutput = quant + "kg";
			}
			givenBaggage = (<>
				<span className={classes.cabinLuggage + " luggage"}>
					<GivenLuggageIcon />
					<span>Predati prtljag {bagOutput}</span>
				</span>
				<div className={classes.resultBreak}></div>
			</>)
		}
	}

	const handleBackClick = () => {
		dispatch(changeFormPosition(-1))
	}


	// main return
	return (
		<div className={[classes.flightDetails + " flightDetails"]}>
			<div className={[classes.backButton + " backButton"]} onClick={handleBackClick}>
				<ArrowLeft />
			</div>

			<div className={classes.resultCard + " resultCard"}>
				<FlightDetail
					cabinLuggage={cabinLuggage}
					givenBaggage={givenBaggage}
					flightSegmentData={departureFlightSegmentData}
					segmentArray={flightData.AirItinerary.OriginDestinationOptions[0]}
					firstSegment={flightData.AirItinerary.OriginDestinationOptions[0].FlightSegments[0]}
					classType={classType}
					airportNames={airportNames}
					headerLabel="Odlazni let"
				/>
				{
					flightData.AirItinerary.OriginDestinationOptions.length>1 
					? 
					<FlightDetail
						cabinLuggage={cabinLuggage}
						givenBaggage={givenBaggage}
						flightSegmentData={returnFlightSegmentData}
						segmentArray={flightData.AirItinerary.OriginDestinationOptions[1]}
						firstSegment={flightData.AirItinerary.OriginDestinationOptions[1].FlightSegments[0]}
						classType={classType}
						airportNames={airportNames}
						airlineBaggageInfo={<div className={[classes.detailsBlock + " detailsBlock"]}>Informacija o prtljagu<br/>na sajtu aviokompanije</div>}
						seatMap={<div className={[classes.detailsBlock + " detailsBlock"]}>Mapa sedišta</div>}
						headerLabel="Povratni let"
					/>
					
					: null
				}
			</div>
			<div className={classes.priceButton + " resultFooterButtonRight black"}
				onClick={()=>{}}
				>Rezerviši<br/>{flightData.AirItineraryPricingInfo.TotalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} {flightData.AirItineraryPricingInfo.CurrencyCode}
			</div>
			<br/>
		</div>
	);
};

export default FlightDetails;
