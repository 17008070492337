const SandClockFilled = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.4" height="24" viewBox="0 0 14.4 24">
      <g transform="translate(-6 -2)">
        <path d="M6,2V9.2h.012L6,9.212,10.8,14,6,18.8l.012.012H6V26H20.4V18.812h-.012L20.4,18.8,15.6,14l4.8-4.788L20.388,9.2H20.4V2Z"
          className="sand-clock"
          />
      </g>
    </svg>
  );
};

export default SandClockFilled;