import React from "react";
import { useDispatch } from "react-redux";
import { toggleSideDrawer } from "../../store/ui-slice";

import MenuIcon from "../Icons/MenuIcon";
import MainLogo from "../Icons/MainLogo";
import UserIcon from "../Icons/UserIcon";
import UserIconFilled from "../Icons/UserIconFilled";

import "./Header.css";

const Header = (props) => {
  const dispatch = useDispatch();

  const sideBarToggleHandler = () => {
    dispatch(toggleSideDrawer());
  };

  let headerClass = "header";
  if (props.formPosition <= -1 ) {
    headerClass += " hide";
  }

  return (
    <div className={headerClass}>
      <div className="hamburgerHolder" onClick={sideBarToggleHandler}>
        <MenuIcon />
      </div>
      <div className="mainLogoHolder">
        <MainLogo />
      </div>
      <div className={"userIcon"} onClick={props.triggerAuthWindow}>
        <div className={"userInfoHolder"}>
        {!props.isAuthenticated && <UserIcon />}
        {props.isAuthenticated && <UserIconFilled />}
        {props.userName && <span className={"userName"}>{props.userName}</span>}
        </div>
      </div>
    </div>
  );
};

export default Header;
