import React from "react";
import HorizontalDivider from "./HorizontalDivider";

import "./InputField.css";

const InputField = (props) => {
  const { label, type, name, handleChange, errorMessage, isValid, value } =
    props;

  return (
    <div className={"inputContainer"}>
      <label>{label}</label>
      <input type={type} name={name} value={value} onChange={handleChange} onBlur={props.blur}/>
      {errorMessage && !isValid && (
        <span className={"error"}>{errorMessage}</span>
      )}
      <HorizontalDivider secondary />
    </div>
  );
};

export default InputField;
