import React from "react";

import "./FormHolder.css";

const FormHolder = (props) => {
  
  let formClassName = 'mainForm';

  if (props.formPosition === 1) {
    formClassName+=' right'
  }
  if (props.formPosition === 2) {
    formClassName+=' rightx2'
  }
  if (props.formPosition === 3) {
    formClassName+=' rightx3'
  }
  if (props.formPosition === -1) {
    formClassName+=' left'
  }
  if (props.formPosition === -2) {
    formClassName+=' leftx2'
  }
  if (props.formPosition === -3) {
    formClassName+=' leftx3'
  }
  if (props.secondaryForm) {
    formClassName+=' secondary';
  }
  if (props.ternaryForm) {
    formClassName+=' ternary';
  }
  if (props.quaternaryForm) {
    formClassName+=' quaternary';
  }

  return <div className={formClassName}>{props.children}</div>;
};

export default FormHolder;
