const AirportTakeoff = () => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="32.28"
      height="29.43"
      viewBox="0 0 32.28 29.43"
    >
      <path
        className="airport-takeoff"
        data-name="Path 32"
        d="M2.891,29.623H33.133V33H2.891Zm31.15-15.8a2.371,2.371,0,0,0-2.929-1.79l-8.452,2.4L11.677,3.57,8.6,4.431l6.59,12.106L7.284,18.783l-3.136-2.6-2.308.659,4.123,7.581,26.375-7.48a2.59,2.59,0,0,0,1.7-3.124Z"
        transform="translate(-1.84 -3.57)"
      />
    </svg>
  );
};

export default AirportTakeoff;