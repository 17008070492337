import React from "react";

import PlaneDeparture from "../../Icons/PlaneDeparture";
import PlaneArrival from "../../Icons/PlaneArrival";
import StopwatchIcon from "../../Icons/StopwatchIcon";
import CabinLuggageIcon from "../../Icons/CabinLuggageIcon";
import GivenLuggageIcon from "../../Icons/GivenLuggageIcon";

import classes from "./ResultModule.module.css";

const ResultCardFlightDirection = (props) => {
  let givenBaggage;
  if (props.baggageInfo) {
      console.log(props.baggageInfo);
      if (props.baggageInfo.length) {

      
      const type = props.baggageInfo[0].BagAllowanceType;
      // const unit = props.baggageInfo[0].BagAllowanceUnit;
      const quant = props.baggageInfo[0].FreeQuantity;
      if (parseInt(quant)) {
        let bagOutput;
        if (type === "Piece") {
          bagOutput = quant + "x23kg";
        } else {
          bagOutput = quant + "kg";
        }
        givenBaggage = (
          <>
            <span className={classes.cabinLuggage + " luggage"}>
              <GivenLuggageIcon />
              <span>Predati prtljag {bagOutput}</span>
            </span>
            <div className={classes.resultBreak}></div>
          </>
        );
      }
    }
  }

  const totalDuration = (src) => {
    return src
      .split(":")
      .map((el, ind) => {
        return parseInt(el) > 0 ? el + (ind > 0 ? "m" : "h ") : "";
      })
      .join("")
      .replace(/(\d{1})\./g, "$1d ");
  };

  return (
    <div className={classes.resultCardDeparture}>
      {props.povratak ? <PlaneArrival /> : <PlaneDeparture />}
      {props.povratak ? <p>Povratak</p> : <p>Odlazak</p>}
      <div className={classes.resultBreak}></div>
      <span className={classes.resultTimes}>
        <img
          className={classes.airlineLogo}
          src={"https://be.aviokarta.rs/" + (props.povratak ? props.airlineLogo2 : props.airlineLogo)}
          alt=""
        />
        <span className={classes.timesHolder}>
          <span className={classes.horizontalTimes}>
            <span>
              {new Date(
                props.povratak ? props.departureDate2 : props.departureDate
              )
                .toLocaleTimeString("sr-Latn-RS", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
                .replace(/:/g, ".")}
            </span>
            <span>
              {new Date(
                props.povratak ? props.departureDate2 : props.departureDate
              )
                .toLocaleDateString("sr-Latn-RS", {
                  month: "short",
                  day: "2-digit",
                  weekday: "short",
                })
                .replace(/\./g, ",")}
            </span>
            <span>
              {new Date(props.povratak ? props.arrivalDate2 : props.arrivalDate)
                .toLocaleTimeString("sr-Latn-RS", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
                .replace(/:/g, ".")}
              {new Date(
                props.povratak ? props.arrivalDate2 : props.arrivalDate
              ).getDay() !==
              new Date(
                props.povratak ? props.departureDate2 : props.departureDate
              ).getDay()
                ? "+1"
                : ""}
            </span>
          </span>
          <span className={classes.horizontalTimes}>
            <span className={classes.airport + " oswald"}>
              {props.povratak ? props.depAirport2 : props.depAirport}
            </span>
            <span className={classes.midAirport + " oswald midAirport"}>
              <span>
                {props.povratak ? props.midAirport2 : props.midAirport}
              </span>
            </span>
            <span className={classes.airport + " oswald"}>
              {props.povratak ? props.arrAirport2 : props.arrAirport}
            </span>
          </span>
          <span
            className={[classes.horizontalTimes, classes.journeyDuration].join(
              " "
            )}
          >
            <StopwatchIcon />
            <span>
              {props.povratak
                ? totalDuration(props.arriveJourneyTotalDuration)
                : totalDuration(props.departJourneyTotalDuration)}
            </span>
          </span>
        </span>
      </span>
      <div className={classes.resultBreak}></div>
      <span className={classes.cabinLuggage + " luggage"}>
        <CabinLuggageIcon />
        <span>Ručni prtljag {props.cabinLuggage}x8kg</span>
      </span>
      {givenBaggage}
      <span style={{ color: "red", textAlign: "center", width: "100%" }}>
        {props.provider}
      </span>
      <div className={classes.resultBreak}></div>
    </div>
  );
};

export default ResultCardFlightDirection;
