import React from "react";
import AirportSelector from "./Selectors/AirportSelector";
import HorizontalDivider from "../../UI/HorizontalDivider";
import VerticalDivider from "../../UI/VerticalDivider";

import { useSelector, useDispatch } from "react-redux";
import {
  setSecondaryFormContent,
  changeFormPosition,
} from "../../../store/ui-slice";

import classes from "./AirportsModule.module.css";

const AirportsModule = () => {
  const dispatch = useDispatch();

  const startAirportSelected = useSelector(
    (state) => state.form.startAirportSelected
  );
  const destAirportSelected = useSelector(
    (state) => state.form.destAirportSelected
  );

  const clickAirportHandler = (direction) => {
    if (direction === "start") {
      dispatch(setSecondaryFormContent("POLAZNI_AERODROM"));
    }
    if (direction === "dest") {
      dispatch(setSecondaryFormContent("DOLAZNI_AERODROM"));
    }
    dispatch(changeFormPosition(-1));
  };

  return (
    <div className={classes.airportsModule}>
      <AirportSelector
        headline={"Polazni aerodrom"}
        airportData={startAirportSelected}
        clickAirport={clickAirportHandler.bind(this, "start")}
      />
      <VerticalDivider />
      <AirportSelector
        headline={"Dolazni aerodrom"}
        airportData={destAirportSelected}
        clickAirport={clickAirportHandler.bind(this, "dest")}
      />
      <HorizontalDivider />
    </div>
  );
};

export default AirportsModule;
