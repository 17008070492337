import React from "react";
import HorizontalDivider from "../../UI/HorizontalDivider";

import classes from "./PassengerModule.module.css";
import PassengerSelector from "./Selectors/PassengerSelector";

import { useSelector, useDispatch } from "react-redux";
import {
  setSecondaryFormContent,
  changeFormPosition,
} from "../../../store/ui-slice";


const PassengerModule = (props) => {
  const dispatch = useDispatch();

  const adults = useSelector((state) => state.form.adults);
  const children = useSelector((state) => state.form.children);
  const babies = useSelector((state) => state.form.babies);

  const clickPassangerHandler = () => {
    dispatch(setSecondaryFormContent("PUTNICI"));
    dispatch(changeFormPosition(-1));
  };

  return (
    <div className={classes.passengerModule}>
      <div className={classes.title}>Izaberite broj putnika</div>
      <div className={classes.moduleHolder} onClick={clickPassangerHandler}>
        <PassengerSelector title={"Odrasli"} data={adults}/>
        <PassengerSelector title={"Deca"} data={children} />
        <PassengerSelector title={"Bebe"} data={babies} />
      </div>

      <HorizontalDivider />
    </div>
  );
};

export default PassengerModule;
