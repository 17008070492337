import React from "react";
import useForm from "../../Utils/useForm";
import { forgotPasswordForm } from "../../Utils/formConfig";

import Button from "../../UI/Button";

export default function ForgotPasswordModule(props) {
  const { renderFormInputs, isFormValid } = useForm(forgotPasswordForm);

  return (
    <div className="authModuleHolder">
      <h2>Zaboravljena lozinka</h2>
      <form autoComplete="off" onSubmit={props.handleFormSubmit}>
        {renderFormInputs()}
        <Button
          type="submit"
          filled
          className={!isFormValid() ? " disabled" : ""}
          onClick={() => {}}
        >
          Pošaljite
        </Button>
      </form>
    </div>
  );
}
