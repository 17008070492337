import React from "react";
import ReactDOM from "react-dom";
import LoadingAirfantast from "../Icons/LoadingAirfantast";

import classes from "./LoadingModal.module.css";

const portalElement = document.getElementById("loadingOverlay");

const LoadingModal = () => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <div className={classes.loadingHolder}>
          <LoadingAirfantast />
        </div>,
        portalElement
      )}
    </React.Fragment>
  );
};

export default LoadingModal;
