const UserIconFilled = (props) => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <path
        id="user-icon-filled"
        d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20C40,9,31,0,20,0z M32.7,29.7C29.9,26.2,22.9,25,20,25
   s-9.9,1.2-12.7,4.7c-5.4-7-4-17.1,3-22.4s17.1-4,22.4,3C37.1,16,37.1,23.9,32.7,29.7z M20,8c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7
   C27,11.1,23.9,8,20,8C20,8,20,8,20,8z"
      />
    </svg>
  );
};

export default UserIconFilled;
