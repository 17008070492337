import React from "react";

import "./PassengerSelector.css";

const PassengerSelector = (props) => {
  
  let passengerNumber = "--";
  let passengerNumberClass = "passengerSelector_number bold oswald";

  if (props.data!==null) {
    passengerNumber = props.data < 10 ? "0"+props.data : props.data;
  }


  if (passengerNumber !== "--") {
    passengerNumberClass += " text-highlighted";
  }


  return (
    <div className="passengerSelector">
      <div className="passengerSelector_title bold">{props.title}</div>
      <div className={passengerNumberClass}>{passengerNumber}</div>
    </div>
  );
};

export default PassengerSelector;
