import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  token: null,
  userId: null,
  tokenExpirationTime: null,
  profile: {},
};

initialState.token = localStorage.getItem('token');
initialState.userId = localStorage.getItem('userId');

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authSuccess: (state, action) => {
      state.token = action.payload.token;
      state.userId = action.payload.userId;

      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("userId", action.payload.userId);
    },
    authLogout: (state) => {
      state.token = null;
      state.userId = null;

      localStorage.removeItem("token");
      localStorage.removeItem("userId");
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setTokenExpiration: (state, action) => {
      //console.log('expiration time: ', expirationTime)
      /*
		return dispatch => {
			setTimeout(() => {
				dispatch(startLogout());
			}, expirationTime * 1000);
			
			if (expirationTime > 300) {
				setTimeout(()=> {
					dispatch(logoutWarning(true));
					// 5 minutes
				}, (expirationTime-300)*1000);
			} else {
				dispatch(logoutWarning(true));
			}
		};	
		*/
    },
  },
});

export const { authSuccess, authLogout, setTokenExpiration, setProfile } =
  authSlice.actions;

export default authSlice.reducer;
