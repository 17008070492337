import React, { useState, useEffect, useCallback } from "react";

import { useSelector } from "react-redux";
import HorizontalDivider from "../../UI/HorizontalDivider";
import ResultCard from "./ResultCard";

import classes from "./ResultModule.module.css";
import ResultModuleFilters from "./ResultModuleFilters";
import ResultModuleHeader from "./ResultModuleHeader";

import InfiniteScroll from "react-infinite-scroll-component";

const ResultModule = (props) => {

  const cardsBase = useSelector(
    (state) => state.form.thomalexObject
  );

  //console.log(cardsBase);

  const token = useSelector((state) => state.auth.token);
  const storeSearch = useSelector((state)=> state.form.storeSearch);
  const thomalexObject = useSelector((state)=> state.form.thomalexObject);

  const loggerReq = useCallback(async () => {
    // store step log
    let storeStepLogURL = "https://"+process.env.REACT_APP_API_URL+"/api/storeStepLog";
    const storeStepLogReq = await fetch(storeStepLogURL, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      body: JSON.stringify({
        SearchQueryId: storeSearch.id,
        StepId: 1,
        PortalId: 1,
        IbeCode: 'e4Lr08M1CDI',
        Session: thomalexObject.SessionId
      })
    })
    const storeStepLog = await storeStepLogReq.json();
    console.log("storeStepLog", "1", storeStepLog.data);

	},[storeSearch, thomalexObject.SessionId, token])

  useEffect(()=> {
    loggerReq()
  }, [loggerReq])


  const [cardLimit, setCardLimit] = useState(2);
  
  const sessionID = cardsBase.SessionId;

  const doLufthansaCheck = (depart, arrive) => {
    //console.log(thomalexObject);
    const departCode = depart.join('');
    let arriveCode = '';
    if (arrive) {
      arriveCode = arrive.join('');
    }
    let filteredFlights = thomalexObject.PricedItineraries.filter((el) => {
      let innerDeparts = "";
      let innerArrivals = "";
      innerDeparts = el.AirItinerary.OriginDestinationOptions[0].FlightSegments.map((el) => {
        return el.RouteNumber
      }).join('');
      if (arrive) {
        innerArrivals = el.AirItinerary.OriginDestinationOptions[1].FlightSegments.map((el) => {
          return el.RouteNumber
        }).join('');
      }

      if (innerDeparts===departCode && innerArrivals===arriveCode) {
        console.log(innerDeparts, departCode, innerArrivals, arriveCode);
      }
      return innerDeparts===departCode && innerArrivals===arriveCode
    })
    //filteredFlights.shift();
    let tempPricesArr = [];
    let tempFareFamilyArr = [];
    const pricesFilteredFlights = filteredFlights.map((el) => {
      if (el.Provider==="LufthansaGroup" || el.Provider==="Swiss" || el.Provider==="AustrianAirlines") {
        if (tempPricesArr.indexOf(el.AirItineraryPricingInfo.TotalPrice)<0) {
          tempPricesArr.push(el.AirItineraryPricingInfo.TotalPrice);
          tempFareFamilyArr.push(el.AirItineraryPricingInfo.FareFamily);
          return el;
        }
        //return [el.AirItineraryPricingInfo.TotalPrice, el.Id, el.Provider, el.AirItineraryPricingInfo.FareFamily];
      }
      return undefined;
    }).filter((el)=>el!==undefined);


    // filter fare families
    const filteredFareFamilies = thomalexObject.AirlineFareFamilies.map((el) => {
      if (tempFareFamilyArr.indexOf(el.Code)>=0) {
        return el;
      }
      return undefined;
    }).filter((el)=>el!==undefined);

    return({
      PricedItineraries: pricesFilteredFlights,
      AirlineFareFamilies: filteredFareFamilies
    });
  }

  const renderedCards = [...cardsBase.PricedItineraries].splice(0, cardLimit).map((el, ind) => {
    const depart = el.AirItinerary.OriginDestinationOptions[0];
    const departSegments = depart.FlightSegments.length;
    let baggageInfo;
    if (el.AirItineraryPricingInfo.PTC_FareBreakdowns[0].Baggages.length) {
      baggageInfo = el.AirItineraryPricingInfo.PTC_FareBreakdowns[0].Baggages;
    }

    let arrival, arrivalSegments;
    let ow = false;
    if (el.AirItinerary.OriginDestinationOptions.length>1) { // if not ow
      arrival = el.AirItinerary.OriginDestinationOptions[1];
      arrivalSegments = arrival.FlightSegments.length;
    } else {
      ow=true;
    }

    const totalPrice = el.AirItineraryPricingInfo.TotalPrice;
    const currency = el.AirItineraryPricingInfo.CurrencyCode;

    const ifAmadeus = el.Provider==="Amadeus";
    const ifLufthansa = el.Provider==="LufthansaGroup" || el.Provider==="Swiss" || el.Provider==="AustrianAirlines";
    const ifTravelFusion = el.Provider==="TravelFusion";

    if (!ow) {

      return (
        <ResultCard
          key={ind}
          ind={ind}
          data={el}
          ow={ow}
          klasa={depart.Cabin}
          airlineLogo={depart.FlightSegments[0].AirlineLogo}
          departureDate={depart.FlightSegments[0].DepartureDate}
          arrivalDate={depart.FlightSegments[departSegments - 1].ArrivalDate}
          depAirport={depart.FlightSegments[0].DepartureAirport}
          arrAirport={depart.FlightSegments[departSegments - 1].ArrivalAirport}
          midAirport={depart.FlightSegments.map(
            (el, ind) => ind + 1 + "." + el.ArrivalAirport + " "
          ).slice(0, -1)}
          departSegments={departSegments}
          departJourneyTotalDuration={depart.JourneyTotalDuration}
          cabinLuggage={depart.Cabin === "Business" ? 2 : 1}
          baggageInfo={baggageInfo}
          
          // return

          airlineLogo2={arrival.FlightSegments[0].AirlineLogo}
          departureDate2={arrival.FlightSegments[0].DepartureDate}
          arrivalDate2={arrival.FlightSegments[arrivalSegments - 1].ArrivalDate}
          depAirport2={arrival.FlightSegments[0].DepartureAirport}
          arrAirport2={arrival.FlightSegments[arrivalSegments - 1].ArrivalAirport}
          midAirport2={arrival.FlightSegments.map(
            (el, ind) => ind + 1 + "." + el.ArrivalAirport + " "
          ).slice(0, -1)}
          arrivalSegments={arrivalSegments}
          arriveJourneyTotalDuration={arrival.JourneyTotalDuration}
          currency={currency}
          totalPrice={totalPrice}

          sessionID={sessionID}
          flightID={el.Id}

          ifAmadeus={ifAmadeus}
          ifLufthansa={ifLufthansa}
          ifTravelFusion={ifTravelFusion}
          lufthansaCheck={doLufthansaCheck}
          lufthansaDepartSegments={depart.FlightSegments}
          lufthansaArriveSegments={arrival.FlightSegments}
          provider={el.Provider}
        />
      );
    } else {
      return (
        <ResultCard
          key={ind}
          ind={ind}
          data={el}
          ow={ow}
          klasa={depart.Cabin}
          airlineLogo={depart.FlightSegments[0].AirlineLogo}
          departureDate={depart.FlightSegments[0].DepartureDate}
          arrivalDate={depart.FlightSegments[departSegments - 1].ArrivalDate}
          depAirport={depart.FlightSegments[0].DepartureAirport}
          arrAirport={depart.FlightSegments[departSegments - 1].ArrivalAirport}
          midAirport={depart.FlightSegments.map(
            (el, ind) => ind + 1 + "." + el.ArrivalAirport + " "
          ).slice(0, -1)}
          departSegments={departSegments}
          departJourneyTotalDuration={depart.JourneyTotalDuration}
          cabinLuggage={depart.Cabin === "Business" ? 2 : 1}
          baggageInfo={baggageInfo}
          
          currency={currency}
          totalPrice={totalPrice}

          sessionID={sessionID}
          flightID={el.Id}

          ifAmadeus={ifAmadeus}
          ifLufthansa={ifLufthansa}
          ifTravelFusion={ifTravelFusion}
          lufthansaCheck={doLufthansaCheck}
          lufthansaDepartSegments={depart.FlightSegments}

        />
      );
    }
  });

  return (
    <div className={classes.resultModule}>
      <ResultModuleHeader />
      <HorizontalDivider relative />
      <ResultModuleFilters />
      <InfiniteScroll
          dataLength={cardLimit}
          next={() => {
            setTimeout( () => {
              setCardLimit(prevLimit => prevLimit+2)
            },250)
          }}
          hasMore={true}
          loader={<h3><center>Loading...</center></h3>}
        >
      <div className={classes.cardHolder}>
        {renderedCards}
      </div>
      </InfiniteScroll>
    </div>
  );
};

export default ResultModule;
