const PlaneArrival = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
    >
      <path
        className="resultIcon"
        d="M33,9.9v3.3L18.975,21.45v9.075a2.475,2.475,0,1,1-4.95,0V21.45L0,13.2V9.9l14.025,4.125V4.95L9.9,2.475V0l6.6,1.65L23.1,0V2.475L18.975,4.95v9.075Z"
        transform="translate(33) rotate(90)"
      />
    </svg>
  );
};

export default PlaneArrival;
