import React from "react";
import HorizontalDivider from "../../../UI/HorizontalDivider";

import "./AirportChooserObject.css";

const AirportChooserObject = (props) => {
  return (
    <div className="airportChooser_object" onClick={props.onAirportClick.bind(this,props.data)}>
      <div className="airportChooser_object_icon">{props.icon}</div>
      <div className="airportChooser_object_infoHolder">
        <div className="airportChooser_object_infoHolder_title bold">
          <div className="airportChooser_object_infoHolder_title_city text-highlighted">
            {props.data.city},&nbsp;
          </div>
          <div className="airportChooser_object_infoHolder_title_country">
            {props.data.country}
          </div>
        </div>
        <div className="airportChooser_object_infoHolder_title_name">
          {props.data.name}
        </div>
      </div>
      <div className="airportChooser_object_shortCode oswald bold text-highlighted">
        {props.data.shortcode}
      </div>
      <HorizontalDivider />
    </div>
  );
};

export default AirportChooserObject;
