const CancelRounded = () => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="-2 0 30.667 28.667"
    >
      <path
        className="cancel-rounded"
        d="M16.333,2A14.333,14.333,0,1,0,30.667,16.333,14.32,14.32,0,0,0,16.333,2Zm0,25.8A11.467,11.467,0,1,1,27.8,16.333,11.482,11.482,0,0,1,16.333,27.8ZM21.479,9.167l-5.146,5.146L11.188,9.167,9.167,11.188l5.146,5.146L9.167,21.479,11.188,23.5l5.146-5.146L21.479,23.5,23.5,21.479l-5.146-5.146L23.5,11.188Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
};

export default CancelRounded;