import React, { useState, useCallback} from 'react';

import TriangleDown from '../../Icons/TriangleDown';
import CheckMark from '../../Icons/CheckMark';
import Coins from '../../Icons/Coins';
import Xicon from '../../Icons/Xicon';

import classes from "./ResultModule.module.css";

import { useDispatch, useSelector} from 'react-redux';
import {
	toggleLoadingStatus,
	changeFormPosition,
	setQuaternaryFormContent,
	setPriceOverviewCameFrom
} from '../../../store/ui-slice';

import {
	setCartDetails,
	setSessionID,
	setFlightID
} from "../../../store/form-slice"

const ResultFlightOption = (props) => {

	const dispatch = useDispatch();
	const [propsOpened, setpropsOpened] = useState(false);
	
	const storeSearch = useSelector((state) => state.form.storeSearch);

	const toggleProps = () => {
		setpropsOpened(oldProp => !oldProp)
	}

	let optionsRendered = (
		<div className={classes.optionsHolder + " optionsHolder"}>
			<div className={propsOpened ? classes.options : classes.optionsClosed}>
				{props.info.AirlineFareFamilyInfos.map((el, ind) => {
					let optionIcon;
					if (el.Type === "Included") {
						optionIcon = <CheckMark />
					} else if (el.Type === "AtCharge") {
						optionIcon = <Coins />
					} else if (el.Type === "NotOffered") {
						optionIcon = <Xicon />
					}
					return <div key={ind} className={classes.airlineOption}>{optionIcon} <b>{el.Code}</b> <span>{el.Name}</span></div>
				})}
			</div>
			<div onClick={() => { toggleProps() }} className={classes.toggler}>
				<div className={!propsOpened ? classes.triangleIconRotated : classes.triangleIcon}>
					<TriangleDown />
				</div>
				<span className="light">{!propsOpened ? "Klikni za više informacija o tarifi" : "Zatvori informacije o tarifi"}</span>
			</div>
		</div>
	)


	const bookRequest = useCallback(async () => {
		dispatch(toggleLoadingStatus());

		// thomalex fetch token
		//const rawToken = await fetch("https://"+process.env.REACT_APP_API_URL+"/api/flight_token?client_id=e4Lr08M1CDI");
		//const rawToken = await fetch("https://office.airfantast.com/api/flight_token?client_id=E10GH26U41A");
		const rawToken = await fetch("https://office.airfantast.com/api/flight_token?client_id="+process.env.REACT_APP_TOKEN_PORTAL_CODE);
		const response = await rawToken.json();
		const thomalexToken = response.value;

		const cartAddURL = "https://rest.resvoyage.com/api/v1/cart/air/add";
		// const cartAddURL = './3-response.json';
		const cartAddRes = await fetch(cartAddURL, {
			// /* 
			method: "POST",
			headers: {
				Authorization: "Bearer " + thomalexToken,
				"Content-Type": "application/json",
			},
			withCredentials: true,
			body: JSON.stringify({
				SessionId: props.sessionID,
				ItemId: props.data.Id
			}),
			//*/
		});

		// log storage
		fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId='+storeSearch.id+'&step=3&type=request', {
			method: "POST",
			body: JSON.stringify(
				{
					SessionId: props.sessionID,
					ItemId: props.data.Id
				}
			)
		})

		if (!cartAddRes.ok) {
			const errMessage = "An error occured: " + cartAddRes.status
			throw new Error(errMessage)
		}
		const cartAdd = await cartAddRes.json();
		//console.log(cartAdd);

		// log storage
		fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId='+storeSearch.id+'&step=3&type=response', {
			method: "POST",
			body: JSON.stringify(
				cartAdd
			)
		})

		dispatch(changeFormPosition(-3));
		dispatch(setQuaternaryFormContent("PREGLED_CENE"))
		dispatch(setPriceOverviewCameFrom(-1));
		dispatch(toggleLoadingStatus());
		dispatch(setSessionID(props.sessionID));
		dispatch(setFlightID(props.flightID));
		dispatch(setCartDetails(cartAdd))
	}, [props.sessionID, props.data.Id, dispatch, storeSearch.id, props.flightID])
	// },[dispatch])

	return (
		<React.Fragment>
			<div className={classes.dashedDivider}></div>
			<div className={classes.flightOption}>
				<div className={classes.smallDivider + " detailsBreak"}></div>
				<div className={classes.flightOptionHeadline + " optionsHeadline bold"}>{props.info.Name}</div>
				<div className={classes.flightOptionHeadline + " optionsSubHeadline text-highlighted"}>{props.data.AirItinerary.OriginDestinationOptions[0].Cabin}</div>
				<div className={classes.dottedDivider}></div>
				{/* <div className={classes.nonRefundable + " nonRefundable light"}>NON-REFUNDABLE ...</div> */}
				{optionsRendered}
				<div className={classes.flightOptionPriceHolder + " thickBorderBottom"}>
					<div className={classes.flightOptionPrice + " flightOptionReserveButton black"}>{props.data.AirItineraryPricingInfo.TotalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")},00 {props.data.AirItineraryPricingInfo.CurrencyCode}</div>
					<div className={classes.flightOptionReserveButton + " resultFooterButtonRight flightOptionReserveButton black"} data-flightid={props.data.Id}
						onClick={() => {
							bookRequest();
						}}
					>Rezerviši</div>
				</div>
			</div>
		</React.Fragment>
	)
};

export default ResultFlightOption;