const CalendarIcon = () => {
  return (
    <svg
      id="date_range_black_24dp"
      xmlns="https://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <path id="Path_27" data-name="Path 27" d="M0,0H36V36H0Z" fill="none" />
      <path
        className="calendar-icon"
        d="M9.667,16.4H13v3.2H9.667ZM33,8.4V30.8A3.278,3.278,0,0,1,29.667,34H6.333A3.268,3.268,0,0,1,3,30.8L3.017,8.4A3.253,3.253,0,0,1,6.333,5.2H8V2h3.333V5.2H24.667V2H28V5.2h1.667A3.278,3.278,0,0,1,33,8.4ZM6.333,11.6H29.667V8.4H6.333ZM29.667,30.8v-16H6.333v16ZM23,19.6h3.333V16.4H23Zm-6.667,0h3.333V16.4H16.333Z"
      />
    </svg>
  );
};

export default CalendarIcon;