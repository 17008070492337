import HorizontalDivider from "./HorizontalDivider";
import classes from "./SecondaryTitle.module.css";

const SecondaryTitle = (props) => {
  return (
    <div className={classes.title}>
      <div className={classes.icon}>
        {props.icon}
      </div>
      <div className={classes.text}>
        {props.text}
      </div>
      <HorizontalDivider />
    </div>
  );
};

export default SecondaryTitle;
