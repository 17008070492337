import React, { useState, useEffect } from "react";
// import ArrowLeft from "../../../Icons/ArrowLeft";
// import ArrowRight from "../../../Icons/ArrowRight";
import HorizontalDivider from "../../../UI/HorizontalDivider";
// import Button from "../../../UI/Button";

import "./CalendarDayChooserObject.css";
import CancelRounded from "../../../Icons/CancelRounded";

const textLabels = {
  polazak: {
    longTitle: "Odabrani datum polaska",
    shortTitle: "Odabrani polazak",
    buttonText: "Potvrdite datum polaska",
  },
  povratak: {
    longTitle: "Odabrani datum povratka",
    shortTitle: "Odabrani povratak",
    buttonText: "Potvrdite datum povratka",
  },
};

/*
const flexLevelLabels = [
  "Tačan datum",
  "+ / -  1 Dan",
  "+ / -  2 Dana",
  "+ / -  3 Dana",
];
*/

const CalendarDayChooserObject = (props) => {

  useEffect(() => {
    if (props.checkSelectedDate) {
      setConfirmed(true);
      props.handleConfirmDate(true);
    }
  }, [props]);

  const [confirmed, setConfirmed] = useState(
    props.isConfirmed ? props.isConfirmed : false
  );
  // const [flexLevel, setFlexLevel] = useState(props.flex ? props.flex : 0);


  /*
  const changeFlexLevelHandler = (dir) => {
    props.onChangeFlexDate(flexLevel + dir);
    setFlexLevel(flexLevel + dir);
  };
  */

  const cancelConfirmHandler = () => {
    if (!confirmed) return;
    setConfirmed(false);
    props.onChangeFlexDate(null);
    props.handleConfirmDate(null);
  };

  const handleOnReset = () => {
    props.onReset();
  };

  return (
    <div
      className="calendarChooser_dayChooserObject_holder"
      onClick={cancelConfirmHandler}
    >
      <div
        className={`calendarChooser_dayChooserObject_holder_dayInfo ${
          confirmed ? "confirmed" : ""
        }`}
      >
        <div className="calendarChooser_dayChooserObject_holder_dayInfo_text">
          {confirmed && textLabels[props.type].shortTitle}
          {!confirmed && textLabels[props.type].longTitle}
        </div>
        <div className="calendarChooser_dayChooserObject_holder_dayInfo_date text-highlighted">
          <span className="dayName">{props.dayName}</span>
          <span className="fullDate oswald bold">{props.fullDate}</span>
        </div>
        {confirmed && (
          <div className="cancelSelection" onClick={handleOnReset}>
            <CancelRounded />
          </div>
        )}

        <HorizontalDivider />
      </div>
      {/*
      !confirmed && (
        <div className="calendarChooser_dayChooserObject_holder_flexibleSetup">
          <div className="calendarChooser_dayChooserObject_holder_flexibleSetup_title">
            Fleksibilni datum povratka + / - 3 dana
          </div>
          <div className="calendarChooser_dayChooserObject_holder_flexibleSetup_controls">
            <div
              className={`calendarChooser_dayChooserObject_holder_flexibleSetup_controls_arrow arrowLeft ${
                flexLevel === 0 ? "disabled" : ""
              }`}
              onClick={changeFlexLevelHandler.bind(this, -1)}
            >
              <ArrowLeft />
            </div>
            <div className="calendarChooser_dayChooserObject_holder_flexibleSetup_controls_controlText text-highlighted bold">
              {flexLevelLabels[flexLevel]}
            </div>
            <div
              className={`calendarChooser_dayChooserObject_holder_flexibleSetup_controls_arrow arrowRight ${
                flexLevel >= 3 ? "disabled" : ""
              }`}
              onClick={changeFlexLevelHandler.bind(this, 1)}
            >
              <ArrowRight />
            </div>
          </div>
          <HorizontalDivider />
        </div>
      )*/
    }
      {/*!confirmed && (
        <Button filled onClick={confirmDateHandler.bind(this)}>
          {textLabels[props.type].buttonText}
        </Button>
      )*/}
    </div>
  );
};

export default CalendarDayChooserObject;
