import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { setChoiceOption, setChoiceWindow } from "../../store/ui-slice";

import Button from "./Button";

import classes from "./InfoModal.module.css";

const portalElement = document.getElementById("infoOverlay");

const Backdrop = (props) => {
  return <div className={classes.Backdrop} onClick={props.closeChoiceModal} />;
};

const ChoiceModal = (props) => {
  const choiceWindowObject = useSelector((state) => state.ui.choiceWindow);
  
  const dispatch = useDispatch();

  const closeChoiceModalHandler = () => {
    dispatch(setChoiceWindow(false));
	/*
    if (infoWindowRefresh) {
      //window.location.reload(true);
      document.location.href="/";
      return false;
    }
	*/
  };

  const OkChoiceHandler = () => {
	dispatch(setChoiceOption("YES"));
	dispatch(setChoiceWindow(false));
  }
  const NoChoiceHandler = () => {
	dispatch(setChoiceOption("NO"));
	dispatch(setChoiceWindow(false));
  }

  useEffect( () => {
    document.body.classList.add('noscroll');
    document.getElementById('infoOverlay').classList.add('active');
    
    return function cleanup () {
      document.body.classList.remove('noscroll');
      document.getElementById('infoOverlay').classList.remove('active');
    }
  }, [])

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <React.Fragment>
          <Backdrop closeChoiceModal={closeChoiceModalHandler} />
          <div className={classes.infoHolder}>
            <div id="infoWindow" className={classes.infoWindow}>
              <div id="infoWindowMessage" className={classes.infoWindowMessage}>{choiceWindowObject.message}</div>
			  <div className={classes.buttonHolder}>
              <Button onClick={OkChoiceHandler} filled className={classes.yesButton}>
                {choiceWindowObject.okText}
              </Button>
              <Button onClick={NoChoiceHandler} filled className={classes.noButton}>
                {choiceWindowObject.noText}
              </Button>
			  </div>
            </div>
          </div>
        </React.Fragment>,
        portalElement
      )}
    </React.Fragment>
  );
};

export default ChoiceModal;
