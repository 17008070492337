const SandClock = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.4" height="24" viewBox="0 0 14.4 24">
      <g transform="translate(-6 -2)">
        <path d="M6,2l.012,7.2L10.8,14,6.012,18.812,6,26H20.4V18.8L15.6,14l4.8-4.788V2ZM18,19.4v4.2H8.4V19.4l4.8-4.8Z"
          className="sand-clock"/>
      </g>
    </svg>
  );
};

export default SandClock;