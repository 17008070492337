const ManIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.715"
      height="28"
      viewBox="0 0 12.715 28"
      className={props.className}
    >
      <path
        id="Union_1"
        data-name="Union 1"
        d="M3.678,26.917a1.381,1.381,0,0,1-.925-1.37q0-4.435,0-8.869v-.4l-.138.032a2.176,2.176,0,0,1-.234-.662c-.019-2.021-.011-4.043-.011-6.064V9.1l-.163-.046a1.514,1.514,0,0,0-.165.469c0,.989.046,1.977.05,2.966.006,1.015.013,2.032-.036,3.045a.87.87,0,0,1-.87.931A1,1,0,0,1,.121,15.6a2.021,2.021,0,0,1-.041-.4Q.038,12.161,0,9.123A3.709,3.709,0,0,1,.54,7.062,3.4,3.4,0,0,1,3.2,5.551a17.143,17.143,0,0,1,5.919.115,3.029,3.029,0,0,1,2.579,2.9c.053,2.292-.006,4.587-.047,6.88a1.012,1.012,0,0,1-1.063,1.027c-.571-.021-.915-.4-.914-1.084,0-1.9.028-3.807.031-5.712a2.664,2.664,0,0,0-.14-.6L9.4,9.1v7.027c-.427-.029-.4.225-.4.491q.007,4.479,0,8.958a1.346,1.346,0,0,1-1.174,1.356,1.377,1.377,0,0,1-1.554-.929,2.158,2.158,0,0,1-.066-.621q0-4.322,0-8.643v-.557H5.552v.478q0,4.435,0,8.868A1.409,1.409,0,0,1,4.162,27,1.476,1.476,0,0,1,3.678,26.917ZM3.443,2.378a2.425,2.425,0,0,1,4.849-.021A2.371,2.371,0,0,1,5.883,4.705H5.872A2.393,2.393,0,0,1,3.443,2.378Z"
        transform="translate(0.5 0.5)"
        fill="#fff"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  );
};

export default ManIcon;
