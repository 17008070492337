const GivenLuggageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.417"
      height="25.708"
      viewBox="0 0 30.417 25.708"
    >
      <g
        transform="translate(-63.292 -332.153)"
      >
        <path
          id="Path_167"
          data-name="Path 167"
          d="M27.5,7.833H20.583V5.458A1.463,1.463,0,0,0,19.125,4H11.292A1.463,1.463,0,0,0,9.833,5.458V7.833H2.917A2.925,2.925,0,0,0,0,10.75V26.792a2.925,2.925,0,0,0,2.917,2.917H27.5a2.925,2.925,0,0,0,2.917-2.917V10.75A2.925,2.925,0,0,0,27.5,7.833ZM12.021,6.188H18.4V7.833H12.021ZM27.5,26.792H2.917V10.75H27.5Z"
          transform="translate(63.292 328.153)"
          className="resultIcon"
        />
        <line
          y2="20"
          transform="translate(70.5 337.5)"
          fill="none"
          className="resultIconStroke"
        />
        <path
          id="Path_181"
          data-name="Path 181"
          d="M0,0V18"
          transform="translate(86.5 338.5)"
          fill="none"
          className="resultIconStroke"
          />
        <rect
          width="7"
          height="4"
          rx="1"
          transform="matrix(0.966, -0.259, 0.259, 0.966, 75.602, 348.974)"
          className="resultIcon"
        />
      </g>
    </svg>
  );
};

export default GivenLuggageIcon;
