import React from "react";
import Input from "../UI/InputField";

import {
  requiredRule,
  emailRule,
  minLengthRule,
  maxLengthRule,
  passwordMatchRule,
} from "./inputValidationRules";

/**
 * creates and returns object representation of form field
 *
 * @param {string} label - label to show with the form input
 * @param {string} name - input name
 * @param {string} type - input type
 * @param {string} defaultValue - default value for the input
 */
function createFormFieldConfig(label, name, type, defaultValue = "") {
  return {
    renderInput: (handleChange, value, isValid, error, key) => {
      return (
        <Input
          key={key}
          name={name}
          type={type}
          label={label}
          isValid={isValid}
          value={value}
          handleChange={handleChange}
          errorMessage={error}
        />
      );
    },
    label,
    value: defaultValue,
    valid: false,
    errorMessage: "",
    touched: false,
  };
}

// object representation of signup form
export const signupForm = {
  email: {
    ...createFormFieldConfig("Email", "email", "email"),
    validationRules: [emailRule("email"), requiredRule("email")],
  },
  password: {
    ...createFormFieldConfig("Lozinka", "password", "password"),
    validationRules: [
      requiredRule("password"),
      minLengthRule("password", 8, "Lozinka"),
    ],
  },
  confirmPassword: {
    ...createFormFieldConfig("Potvrda lozinke", "confirmPassword", "password"),
    validationRules: [passwordMatchRule()],
  },
};

export const loginForm = {
  email: {
    ...createFormFieldConfig("Email", "email", "email"),
    validationRules: [emailRule("email"), requiredRule("email")],
  },
  password: {
    ...createFormFieldConfig("Lozinka", "password", "password"),
    validationRules: [
      requiredRule("password"),
      minLengthRule("password", 8, "Lozinka"),
      maxLengthRule("password", 20, "Lozinka"),
    ],
  },
};

export const forgotPasswordForm = {
  email: {
    ...createFormFieldConfig("Email", "email", "email"),
    validationRules: [emailRule("email"), requiredRule("email")],
  },
};
