const ArrowLeft = () => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="14.82"
      height="24"
      viewBox="0 0 14.82 24"
    >
      <path
        className="arrow arrow_left"
        d="M22.82,8.82,20,6,8,18,20,30l2.82-2.82L13.66,18Z"
        transform="translate(-8 -6)"
      />
    </svg>
  );
};
export default ArrowLeft;