const CabinLuggageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.605"
      height="21.229"
      viewBox="0 0 21.605 21.229"
    >
      <g
        transform="translate(-3.5 -3.996)"
      >
        <g transform="translate(3.5 7.643)">
          <path
            d="M21.323,31.2H7.282A3.786,3.786,0,0,1,3.5,27.419V17.4a3.786,3.786,0,0,1,3.782-3.782H21.323A3.786,3.786,0,0,1,25.105,17.4V27.419A3.786,3.786,0,0,1,21.323,31.2ZM7.282,15.821A1.582,1.582,0,0,0,5.7,17.4V27.419A1.582,1.582,0,0,0,7.282,29H21.323a1.582,1.582,0,0,0,1.58-1.58V17.4a1.582,1.582,0,0,0-1.58-1.58Z"
            transform="translate(-3.5 -13.619)"
            className="resultIcon"
          />
        </g>
        <g id="Group_50" data-name="Group 50" transform="translate(3.5 13.365)">
          <path

            d="M23.93,30.917H4.6a1.1,1.1,0,1,1,0-2.2H23.93a1.1,1.1,0,1,1,0,2.2Z"
            transform="translate(-3.5 -28.715)"
            className="resultIcon"
          />
        </g>
        <g
          transform="translate(10.143 3.996)"
        >
          <path
            d="M28.11,9.846a1.1,1.1,0,0,1-1.1-1.1V6.2h-3.78V8.745a1.1,1.1,0,0,1-2.2,0V6.186A2.193,2.193,0,0,1,23.217,4h3.8a2.193,2.193,0,0,1,2.19,2.19V8.745A1.1,1.1,0,0,1,28.11,9.846Z"
            transform="translate(-21.027 -3.996)"
            className="resultIcon"
          />
        </g>
        <g
          transform="translate(17.521 13.365)"
        >
          <path
            d="M41.591,32.885a1.1,1.1,0,0,1-1.1-1.1V29.816a1.1,1.1,0,1,1,2.2,0v1.968A1.1,1.1,0,0,1,41.591,32.885Z"
            transform="translate(-40.49 -28.715)"
            className="resultIcon"
          />
        </g>
        <g
          transform="translate(8.913 13.365)"
        >
          <path
            d="M18.883,32.885a1.1,1.1,0,0,1-1.1-1.1V29.816a1.1,1.1,0,0,1,2.2,0v1.968A1.1,1.1,0,0,1,18.883,32.885Z"
            transform="translate(-17.782 -28.715)"
            className="resultIcon"
          />
        </g>
      </g>
    </svg>
  );
};

export default CabinLuggageIcon;