import { createSlice } from "@reduxjs/toolkit";

const AVAILABLE_THEMES = ["dark", "light"];

const AVAILABLE_SECONDARY_FORM = [
  "POLAZNI_AERODROM",
  "DOLAZNI_AERODROM",
  "KALENDAR",
  "PUTNICI",
  "KALENDAR",
  "REZULTATI"
];

const AVAILABLE_TERNARY_FORM = [
  "DETALJI_LETA"
]

const AVAILABLE_QUATERNARY_FORM = [
  "PREGLED_CENE",
  "POTVRDA_REZERVACIJE"
]

const initialState = {
  activeTheme: "dark",
  sideMenuShown: false,
  loadingStatus: false,
  formPosition: 0,
  secondaryFormContent: "",
  ternaryFormContent: "",
  quarternaryFormContent: "",
  priceOverviewCameFrom: null,
  authWindowShown: false,
  infoWindow: false,
  infoWindowRefresh: false,
  iframePayment: false,
  choiceWindow: false,
  choiceOption: false
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    changeTheme: (state, action) => {
      if (AVAILABLE_THEMES.includess(action.payload)) {
        state.theme = action.payload;
      } else {
        state.theme = "dark";
      }
    },
    toggleSideDrawer: (state) => {
      state.sideMenuShown = !state.sideMenuShown;
    },
    changeFormPosition: (state, action) => {
      state.formPosition = action.payload;
    },
    setSecondaryFormContent: (state, action) => {
      if (AVAILABLE_SECONDARY_FORM.includes(action.payload)) {
        state.secondaryFormContent = action.payload;
      } else {
        state.secondaryFormContent = "";
      }
    },
    setTernaryFormContent: (state, action) => {
      if (AVAILABLE_TERNARY_FORM.includes(action.payload)) {
        state.ternaryFormContent = action.payload;
      } else {
        state.ternaryFormContent = "";
      }
    },
    setQuaternaryFormContent: (state, action) => {
      if (AVAILABLE_QUATERNARY_FORM.includes(action.payload)) {
        state.quarternaryFormContent = action.payload;
      } else {
        state.quarternaryFormContent = "";
      }
    },
    toggleAuthWindow: (state) => {
      state.authWindowShown = !state.authWindowShown;
    },
    toggleLoadingStatus: (state) => {
      state.loadingStatus = !state.loadingStatus
    },
    setInfoWindow: (state, action) => {
      state.infoWindow = action.payload;
    },
    setInfoWindowCloseRefresh: (state, action) => {
      state.infoWindowRefresh = action.payload;
    },
    setPriceOverviewCameFrom: (state,action) => {
      state.priceOverviewCameFrom = action.payload;
    },
    setIframePayment: (state, action) => {
      state.iframePayment = action.payload;
    },
    setChoiceWindow: (state, action) => {
      state.choiceWindow = action.payload
    },
    setChoiceOption: (state, action) => {
      state.choiceOption = action.payload
    }
  },
});

export const {
  changeTheme,
  toggleSideDrawer,
  changeFormPosition,
  setSecondaryFormContent,
  setTernaryFormContent,
  setQuaternaryFormContent,
  toggleAuthWindow,
  toggleLoadingStatus,
  setInfoWindow,
  setInfoWindowCloseRefresh,
  setPriceOverviewCameFrom,
  setIframePayment,
  setChoiceWindow,
  setChoiceOption,
} = uiSlice.actions;

export default uiSlice.reducer;
