import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setIframePayment } from "../../store/ui-slice";

import CancelRounded from "../Icons/CancelRounded";

import classes from "./PaymentIframe.module.css";

const portalElement = document.getElementById("iframeContent");

const PaymentIframe = () => {

	const dispatch = useDispatch();
	const formRef = useRef(null);
	const cartDetails = useSelector((state) => state.form.cartDetails)
	const amount = cartDetails.ShoppingCart.Air.AirItineraryPricingInfo.TotalPrice.toString() + ",00";
	const curCode = cartDetails.ShoppingCart.Air.AirItineraryPricingInfo.CurrencyCode;

	const [data, setData] = useState(null);
	const [shopid, setShopid] = useState("");
	const [shopCartid, setShopCartid] = useState("");
	const [signature, setSignature] = useState("");

	useEffect(() => {
		let isSubscribed = true;

		const fetchData = async () => {
			const data = await fetch(`https://${process.env.REACT_APP_API_URL}/api/get-signature-ws?Amount=${amount}&Currency=${curCode}`, {
				method: "POST"
			})
			const json = await data.json();
			if (isSubscribed) {
				isSubscribed=false;
				console.log(json.data)
				setData(json.data);
			}
		}

		try {
			fetchData()
		} catch (e) {
			console.log(e)
		}

	}, [amount, curCode])

	useEffect(() => {
		if (data) {
			console.log("data set", data)
			setShopid(data.ShopID);
			setShopCartid(data.ShoppingCartID);
			setSignature(data.Signature);
		}
	}, [data])

	useEffect(()=> {
		console.log(signature.length, shopid.length, shopCartid);
		if (signature.length && shopid.length && shopCartid) {
			formRef.current.submit();
		}
	}, [signature, shopid, shopCartid])

	const deactivateIframe = () => {
		dispatch(setIframePayment(false));
	}

	const apiUrl = process.env.REACT_APP_API_URL;

	return (
		<React.Fragment>
			{ReactDOM.createPortal(
				<div className={classes.paymentIframeHolder}>
					<div className={classes.closeIcon + " closeIcon"} onClick={deactivateIframe}>
						<CancelRounded />
					</div>
					<form ref={formRef} name="pay" action="https://form.wspay.biz/authorization.aspx" method="POST" target="wspayiframe" id="wspayform">
						<input type="hidden" name="ShopID" value={shopid} />
						<input type="hidden" name="ShoppingCartID" value={shopCartid} />
						<input type="hidden" name="Version" value="2.0" />
						<input type="hidden" name="TotalAmount" value={amount} />
						<input type="hidden" name="Signature" value={signature} />
						<input type="hidden" name="ReturnURL" value={`https://${apiUrl}/wspayrep/return.php`} />
						<input type="hidden" name="CancelURL" value={`https://${apiUrl}/wspayrep/cancel.php`} />
						<input type="hidden" name="ReturnErrorURL" value={`https://${apiUrl}/wspayrep/error.php`} />
						<input type="hidden" name="Iframe" value="True" />
						<input type="hidden" name="IframeResponseTarget" value="SELF" />
					</form>


					<iframe className={classes.paymentIframe} name="wspayiframe" id="wspayiframe" title="wspayiframe"></iframe>
				</div>,
				portalElement
			)}
		</React.Fragment>
	);
};

export default PaymentIframe;
