import "./Button.css";

const Button = (props) => {
  const active = props.active;
  const filled = props.filled;
  const outline = props.outline;

  const activeClass =
    "Button" +
    (active ? " active" : "") +
    (filled ? " filledButton" : "") +
    (outline ? " outlineButton" : "") +
    (props.className ? " " + props.className : "");

  return (
    <button className={activeClass} onClick={props.onClick.bind(this, props)}>
      {props.children}
    </button>
  );
};

export default Button;
