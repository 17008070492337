import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  oneWay: false,
  startAirportSelected: false,
  destAirportSelected: false,
  startDate: null,
  startFlex: null,
  endDate: null,
  endFlex: null,
  adults: 1,
  children: null,
  babies: null,
  travelClass: 1,

  countryList: null,
  helperList: null,
  exchangeList: null,

  storeSearch: null,

  sessionID: null,
  flightID: null,

  thomalexObject: null,

  flightDetails: null,

  cartDetails: null,

  storeReservation: null,

  pnrResponse: null,

  buyerInfo: null,

  ancillaryData: null
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    changeDirection: (state) => {
      state.oneWay = !state.oneWay;
    },
    changeAirport: (state, action) => {
      if (action.payload.type === "Polazni aerodrom") {
        state.startAirportSelected = {
          ...action.payload.airport,
        };
      }
      if (action.payload.type === "Dolazni aerodrom") {
        state.destAirportSelected = {
          ...action.payload.airport,
        };
      }
    },
    changeDate: (state, action) => {
      if (action.payload.type === "startDate") {
        state.startDate = action.payload.day;
        state.startFlex = action.payload.flex;
      }
      if (action.payload.type === "endDate") {
        state.endDate = action.payload.day;
        state.endFlex = action.payload.flex;
      }
    },
    changePassangers: (state, action) => {
      state.adults = action.payload.adults;
      state.children = action.payload.children;
      state.babies = action.payload.babies;
    },
    changeClass: (state, action) => {
      state.travelClass = action.payload;
    },
    setCountryList: (state, action) => {
      state.countryList = action.payload;
    },
    setHelperList: (state, action) => {
      state.helperList = action.payload;
    },
    setExchangeList: (state, action) => {
      state.exchangeList = action.payload;
    },
    setThomalexObject: (state, action) => {
      state.thomalexObject = action.payload
    },
    setFlightDetails: (state, action) => {
      state.flightDetails = action.payload
    },
    setCartDetails: (state, action) => {
      state.cartDetails = action.payload
    },
    setStoreSearch: (state, action) => {
      state.storeSearch = action.payload
    },
    setSessionID: (state, action) => {
      state.sessionID = action.payload
    },
    setFlightID: (state, action) => {
      state.flightID = action.payload
    },
    setBuyerInfo: (state, action) => {
      state.buyerInfo = action.payload;
    },
    setStoreReservation: (state, action) => {
      state.storeReservation = action.payload;
    },
    setPnrResponse: (state, action) => {
      state.pnrResponse = action.payload;
    },
    setAncillaryData: (state, action) => {
      state.ancillaryData = action.payload;
    }
  },
});

export const {
  changeDirection,
  changeAirport,
  changeDate,
  changePassangers,
  changeClass,
  setCountryList,
  setHelperList,
  setStoreSearch,
  setSessionID,
  setFlightID,
  setThomalexObject,
  setFlightDetails,
  setCartDetails,
  setExchangeList,
  setBuyerInfo,
  setStoreReservation,
  setPnrResponse,
  setAncillaryData,
} = formSlice.actions;

export default formSlice.reducer;
