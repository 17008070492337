import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

import Background from "./components/UI/Background";
import LoadingModal from "./components/UI/LoadingModal";
import PaymentIframe from "./components/UI/PaymentIframe";
import InfoModal from "./components/UI/InfoModal";
import ChoiceModal from "./components/UI/ChoiceModal";
import Header from "./components/Layout/Header";
import SideDrawer from "./components/Layout/SideDrawer";
import Button from "./components/UI/Button";

import FormHolder from "./components/Layout/FormHolder";
import AirportChooser from "./components/Layout/SecondaryFormModules/AirportChooser";
import CalendarChooser from "./components/Layout/SecondaryFormModules/CalendarChooser";
import PassangerChooser from "./components/Layout/SecondaryFormModules/PassangerChooser";

import DirectionModule from "./components/Layout/MainFormModules/DirectionModule";
import AirportsModule from "./components/Layout/MainFormModules/AirportsModule";
import CalendarModule from "./components/Layout/MainFormModules/CalendarModule";
import PassengerModule from "./components/Layout/MainFormModules/PassengerModule";
import ClassModule from "./components/Layout/MainFormModules/ClassModule";
import ResultModule from "./components/Layout/ResultModules/ResultModule";
import FlightDetails from "./components/Layout/ResultModules/FlightDetails";
import CheckoutForm from "./components/Layout/ResultModules/CheckoutForm";
/* auth */
import AuthHolder from "./components/Layout/LoginModules/AuthHolder";
import UserProfile from "./components/Layout/LoginModules/UserProfile";

import {
  toggleSideDrawer,
  toggleLoadingStatus,
  setSecondaryFormContent,
  changeFormPosition,
  setQuaternaryFormContent,
} from "./store/ui-slice";

import {
  setCountryList,
  setHelperList,
  setExchangeList,
  setStoreSearch,
  setThomalexObject,
} from "./store/form-slice";

import "./App.css";
import "./App-darkTheme.css";
import Confirmation from "./components/Layout/ResultModules/Confirmation";

function App() {

  //console.log(process.env.REACT_APP_TOKEN_PORTAL_CODE);
  //console.log(process.env.REACT_APP_API_URL);
  //console.log(process.env.NODE_ENV);

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const dispatch = useDispatch();
  const activeTheme = useSelector((state) => state.ui.activeTheme);
  const sideMenuShown = useSelector((state) => state.ui.sideMenuShown);
  const loadingStatus = useSelector((state) => state.ui.loadingStatus);
  const infoWindow = useSelector((state) => state.ui.infoWindow);
  const choiceWindow = useSelector((state) => state.ui.choiceWindow);
  const iframePayment = useSelector((state) => state.ui.iframePayment);

  const appClass = "App " + activeTheme;
  document.getElementById("infoOverlay").classList.add(activeTheme);

  const history = useHistory();

  const closeSidebarHandler = () => {
    dispatch(toggleSideDrawer());
  };

  const activateAuthWindow = () => {
    if (!isAuthenticated) {
      history.push("/auth");
    } else {
      history.push("/profile");
    }
  };

  //authenticated
  const token = useSelector((state) => state.auth.token);
  const isAuthenticated = useSelector((state) => !!state.auth.token);
  const userId = useSelector((state) => state.auth.userId);

  // form position
  const formPosition = useSelector((state) => state.ui.formPosition);

  const secondaryFormContent = useSelector(
    (state) => state.ui.secondaryFormContent
  );

  let secondaryFormComponent;

  if (secondaryFormContent === "POLAZNI_AERODROM") {
    secondaryFormComponent = <AirportChooser title={"Polazni aerodrom"} />;
  }
  if (secondaryFormContent === "DOLAZNI_AERODROM") {
    secondaryFormComponent = <AirportChooser title={"Dolazni aerodrom"} />;
  }
  if (secondaryFormContent === "PUTNICI") {
    secondaryFormComponent = <PassangerChooser />;
  }
  if (secondaryFormContent === "KALENDAR") {
    secondaryFormComponent = <CalendarChooser />;
  }
  if (secondaryFormContent === "REZULTATI") {
    secondaryFormComponent = <ResultModule />;
  }

  const ternaryFormContent = useSelector(
    (state) => state.ui.ternaryFormContent
  );

  let ternaryFormComponent;

  if (ternaryFormContent === "DETALJI_LETA") {
    ternaryFormComponent = <FlightDetails />;
  }

  const quarternaryFormContent = useSelector(
    (state) => state.ui.quarternaryFormContent
  );

  let quarternaryFormComponent;
  if (quarternaryFormContent === "PREGLED_CENE") {
    quarternaryFormComponent = <CheckoutForm />;
  }
  if (quarternaryFormContent === "POTVRDA_REZERVACIJE") {
    quarternaryFormComponent = <Confirmation />;
  }

  /* form elements state */
  const oneWay = useSelector((state) => state.form.oneWay);
  const startAirportSelected = useSelector(
    (state) => state.form.startAirportSelected
  );
  const destAirportSelected = useSelector(
    (state) => state.form.destAirportSelected
  );
  const startDate = useSelector((state) => state.form.startDate);
  const startFlex = useSelector((state) => state.form.startFlex);
  const endDate = useSelector((state) => state.form.endDate);
  const endFlex = useSelector((state) => state.form.endFlex);
  const adults = useSelector((state) => state.form.adults);
  const children = useSelector((state) => state.form.children);
  const babies = useSelector((state) => state.form.babies);
  const travelClass = useSelector((state) => state.form.travelClass);

  useEffect(() => {
    if (oneWay) {
      if (
        startAirportSelected !== null &&
        startDate !== null &&
        startFlex !== null &&
        adults !== null
      ) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    } else {
      if (
        startAirportSelected !== null &&
        destAirportSelected !== null &&
        startDate !== null &&
        startFlex !== null &&
        endDate !== null &&
        endFlex !== null &&
        adults !== null
      ) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    }
  }, [
    oneWay,
    startAirportSelected,
    destAirportSelected,
    startDate,
    endDate,
    startFlex,
    endFlex,
    adults,
    children,
    babies,
  ]);

  const formSubmitHandler = async () => {

    /* temp for result page */
    /*
    dispatch(setQuaternaryFormContent("POTVRDA_REZERVACIJE"));
    dispatch(changeFormPosition(-3));
    return;
    */
  
    let dataObject = {
      type_id: oneWay ? 1 : 2,
      from: startAirportSelected.shortcode,
      to: destAirportSelected.shortcode,
      dep_date: new Date(startDate).toLocaleDateString("en-CA"),
      ret_date: endDate ? new Date(endDate).toLocaleDateString("en-CA") : null,
      flex_dates: startFlex !== 0 || endFlex !== 0 ? 1 : 0,
      flex_dep: startFlex,
      flex_ret: endFlex ? endFlex : 0,
      adt: adults ? adults : 0,
      chd: children ? children : 0,
      inf: babies ? babies : 0,
      travel_class: travelClass,
      portal: 14,
    };

    if (!oneWay) {
      dataObject.flex_ret = endFlex;
      dataObject.ret_date = new Date(endDate).toLocaleDateString("en-CA");
    }

    console.log('search send', oneWay, dataObject);

    setLoading();

    // airfantast save
    ///*
    const storeSearch = await fetch(
      "https://"+process.env.REACT_APP_API_URL+"/api/storeSearch",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        withCredentials: true,
        body: JSON.stringify(dataObject),
      }
    )
      .then((res) => {
        //console.log(res);
        return res.json();
      })
      .then((data) => {
        //console.log(data);
        if (data.success) {
          return data;
          //console.log(data.message);
          //dispatch(setInfoWindow(data.message));
        }
      })
      .catch((e) => {
        throw e.message;
      });
    //*/
    dispatch(setStoreSearch(storeSearch.data));

    // thomalex fetch token

    //const rawToken = await fetch("https://"+process.env.REACT_APP_API_URL+"/api/flight_token?client_id=e4Lr/08M1CDI");
    //const rawToken = await fetch("https://office.airfantast.com/api/flight_token?client_id=E10GH26U41A");
    const rawToken = await fetch(
      "https://office.airfantast.com/api/flight_token?client_id="+process.env.REACT_APP_TOKEN_PORTAL_CODE
    );
    const response = await rawToken.json();
    const thomalexToken = response.value;
    console.log(thomalexToken);
    //const thomalexTokenTS = response.reqtime;

    //console.log(thomalexToken);

    //https://rest.resvoyage.com/api/v1/air/search?from1=MIA&to1=PAR&DepartureDate1=2021-11-17&Adults=1&from2=PAR&to2=MIA&DepartureDate2=2021-12-02

    let thomalexQuery = {
      from1: startAirportSelected.shortcode,
      to1: destAirportSelected.shortcode,
      DepartureDate1: new Date(startDate).toLocaleDateString("en-CA"),
      Adults: adults,
    };

    if (!oneWay) {
      thomalexQuery["from2"] = destAirportSelected.shortcode;
      thomalexQuery["to2"] = startAirportSelected.shortcode;
      thomalexQuery["DepartureDate2"] = new Date(endDate).toLocaleDateString(
        "en-CA"
      );
    }

    let thomalexSearchURL = "https://rest.resvoyage.com/api/v1/air/search";
    thomalexSearchURL += "?from1=" + startAirportSelected.shortcode;
    thomalexSearchURL += "&to1=" + destAirportSelected.shortcode;
    thomalexSearchURL +=
      "&DepartureDate1=" + new Date(startDate).toLocaleDateString("en-CA");
    thomalexSearchURL += "&Adults=" + adults;

    if (children) {
      thomalexSearchURL += "&Children=" + children;
    }

    if (babies) {
      thomalexSearchURL += "&Infants=" + babies;
    }

    if (!oneWay) {
      thomalexSearchURL += "&from2=" + destAirportSelected.shortcode;
      thomalexSearchURL += "&to2=" + startAirportSelected.shortcode;
      thomalexSearchURL +=
        "&DepartureDate2=" + new Date(endDate).toLocaleDateString("en-CA");
    }

    // thomalexSearchURL = './flight-01.json';
    // thomalexSearchURL = './thomaResponse.json';
    // thomalexSearchURL = './BEG-GPS-2022-04-15-2022-04-22-response.json';
    // thomalexSearchURL = './BEG-GPS-2022-04-15-2022-04-22-PAX-5-response.json';
    // thomalexSearchURL = './BEG-ESB-2022-04-15-2022-04-22-PAX-1-response.json';
    // thomalexSearchURL = './BEG-GPS-2022-04-15-2022-04-22-PAX-5-business-response.json';
    // thomalexSearchURL = './BEG-ESB-2022-04-15-2022-04-22-PAX-5-BUSINESS-response.json';
    // thomalexSearchURL = './BEG-GPS-OW-2022-04-15-2022-04-22-PAX-5-business-response.json';
    // thomalexSearchURL = './BEG-GPS-2022-04-15-2022-04-22-response.json';
    // thomalexSearchURL = './BEG-ZRH-Sample-response.json';

    try {
      const thomalexReq = await fetch(thomalexSearchURL, {
        method: "GET",

        headers: {
          Authorization: "Bearer " + thomalexToken,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      // log storage
      fetch(
        "https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=" +
          storeSearch.data.id +
          "&step=1&type=request",
        {
          method: "POST",
          body: JSON.stringify(
            Object.fromEntries(
              new URLSearchParams(thomalexSearchURL.split("?")[1])
            )
          ),
        }
      );

      if (!thomalexReq.ok) {
        console.log(thomalexReq);
        throw new Error(thomalexReq);
      }
      const thomalexResponse = await thomalexReq.json();
      //const thomaCopy = {...thomalexResponse, PricedItineraries: thomalexResponse.PricedItineraries.splice(0,15)};
      //console.log('thomalexresponse', thomalexResponse);

      // log storage
      fetch(
        "https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=" +
          storeSearch.data.id +
          "&step=1&type=response",
        {
          method: "POST",
          body: JSON.stringify(thomalexResponse),
        }
      );

      setLoading();
      dispatch(setThomalexObject(thomalexResponse));
      dispatch(setSecondaryFormContent("REZULTATI"));
      dispatch(changeFormPosition(-1));
    } catch (e) {
      console.log(e);
      /*
      // error log request
      let storeErrorLogURL = "https://"+process.env.REACT_APP_API_URL+"/api/storeErrorLog";
      const storeErrorLogReq = await fetch(storeErrorLogURL, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        withCredentials: true,
        body: JSON.stringify({
          SearchQueryId: storeSearch.id,
          StepId: 1,
          PortalId: 1,
          IbeCode: 'e4Lr08M1CDI',
          Session: 'trt'//thomalexObject.SessionId
          ,
          ErrorId:"null",
          ErrorCode:e.status,
          ErrorMessage:e.statusText,
          Request:JSON.stringify(thomalexSearchURL),
          Response:JSON.stringify(e.body)
        })
      })
      const storeErrLog = await storeErrorLogReq.json();
      console.log("storeStepLog", "2", storeErrLog.data);
      */
    }
  };

  const setLoading = useCallback(() => {
    dispatch(toggleLoadingStatus());
  }, [dispatch]);

  /* helper & country service */
  const getHelperServices = useCallback(async () => {
    setLoading();
    const dt = new Date();
    const y = dt.getFullYear();
    const m = dt.getMonth() + 1;
    const d = dt.getDate();
    const exchangeRateURL =
      "https://office.airfantast.com/api/exrates?bank_id=1&currency=EUR&date=" +
      y +
      "-" +
      (m < 10 ? "0" + m : m) +
      "-" +
      (d < 10 ? "0" + d : d);
    const exchangeResponse = await fetch(exchangeRateURL);
    const exchangeData = await exchangeResponse.json();
    dispatch(setExchangeList(exchangeData));
    const countryURL = "https://"+process.env.REACT_APP_API_URL+"/api/countries";
    const countryResponse = await fetch(countryURL);
    const countryData = await countryResponse.json();
    dispatch(setCountryList(countryData));
    const helpURL = "https://"+process.env.REACT_APP_API_URL+"/api/help_service";
    const helpResponse = await fetch(helpURL);
    const helpData = await helpResponse.json();
    dispatch(setHelperList(helpData));
    // console.log('country values')
    setLoading();
  }, [setLoading, dispatch]);

  useEffect(() => {
    getHelperServices();
  }, [getHelperServices]);

  useEffect(() => {
    document.body.classList.toggle("noscroll", loadingStatus);
  }, [loadingStatus]);

  return (
    <main className={appClass}>
      <Background />
      {iframePayment && <PaymentIframe />}
      {loadingStatus && <LoadingModal />}
      {infoWindow && <InfoModal />}
      {choiceWindow && <ChoiceModal />}
      <Switch>
        <Route path="/" exact>
          <Header
            formPosition={formPosition}
            triggerAuthWindow={activateAuthWindow.bind(this)}
            isAuthenticated={isAuthenticated}
            userName={userId}
          />
          <SideDrawer
            activeTheme={activeTheme}
            sideMenuShown={sideMenuShown}
            closeSidebarHandler={closeSidebarHandler}
          />
          <FormHolder formPosition={formPosition}>
            <DirectionModule />
            <AirportsModule />
            <CalendarModule />
            <PassengerModule />
            <ClassModule />
            <div className={"mainButtonHolder"}>
              <Button
                //className={buttonDisabled ? "disabled" : ""}
                filled={true}
                onClick={formSubmitHandler}
              >
                Pronađi let
              </Button>
            </div>
          </FormHolder>
          <FormHolder formPosition={formPosition + 1} secondaryForm={true}>
            {secondaryFormComponent}
          </FormHolder>
          <FormHolder formPosition={formPosition + 2} ternaryForm={true}>
            {ternaryFormComponent}
          </FormHolder>
          <FormHolder formPosition={formPosition + 3} quaternaryForm={true}>
            {quarternaryFormComponent}
          </FormHolder>
        </Route>
        {!isAuthenticated && (
          <Route path="/auth">
            <AuthHolder />
          </Route>
        )}
        <Route path="/profile">
          {isAuthenticated && <UserProfile />}
          {!isAuthenticated && <Redirect to="/auth" />}
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </main>
  );
}

export default App;
