import React, { useState, useEffect, useCallback } from "react";

import AirportTakeoff from "../../Icons/AirportTakeoff";
import AirportTouchdown from "../../Icons/AirportTouchdown";
import CancelRounded from "../../Icons/CancelRounded";
import HorizontalDivider from "../../UI/HorizontalDivider";
import AirportChooserObject from "./ModuleObjects/AirportChooserObject";

import "./AirportChooser.css";
import CloseSecondary from "../../UI/CloseSecondary";
import SecondaryTitle from "../../UI/SecondaryTitle";

import { useDispatch } from "react-redux";
import { changeAirport } from "../../../store/form-slice";
import {
  setSecondaryFormContent,
  changeFormPosition,
} from "../../../store/ui-slice";

const AirportChooser = (props) => {
  const [airportInputValue, changeAirportInputValue] = useState("");
  const [airportInputFilled, changeAirportInputFilled] = useState(false);

  const [airportsLoaded, setAirportsLoaded] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { title } = props;

  const dispatch = useDispatch();

  const airportInputHandler = (e) => {
    e.preventDefault();
    changeAirportInputValue(e.target.value);
  };

  const clearInputHandler = () => {
    changeAirportInputValue("");
  };

  let planeIcon = <AirportTakeoff />;
  if (title === "Dolazni aerodrom") {
    planeIcon = <AirportTouchdown />;
  }

  const handleAirportClick = (data) => {
    //console.log(data, props.title);
    dispatch(changeAirport({ type: title, airport: data }));
    dispatch(setSecondaryFormContent(""));
    dispatch(changeFormPosition(0));
  };

  const searchAirportsHandler = async (str) => {
    setIsLoading(true);
    let airportSearchUrl = "https://"+process.env.REACT_APP_API_URL+"/api/geta?data=" + str;
    const response = await fetch(airportSearchUrl);
    const data = await response.json();
    if (data.length) {
      const parsedAirports = data.map((el) => {
        const splitted = el.split(" - ");
        return {
          id: splitted[0],
          name: splitted[1],
          city: splitted[2],
          country: splitted[3],
          shortcode: splitted[4],
        };
      });
      setAirportsLoaded(parsedAirports);
    } else {
      setAirportsLoaded([]);
    }
    setIsLoading(false);
  };

  const fetchAirportsHandler = useCallback(async () => {
    setIsLoading(true);
    let airportUrl = "https://"+process.env.REACT_APP_API_URL+"/api/defaultDeparture";
    if (title === "Dolazni aerodrom") {
      airportUrl = "https://"+process.env.REACT_APP_API_URL+"/api/defaultArrival";
    }
    const response = await fetch(airportUrl);
    const data = await response.json();
    const parsedAirports = data.map((el) => {
      const splitted = el.split(" - ");
      return {
        id: splitted[0],
        name: splitted[1],
        city: splitted[2],
        country: splitted[3],
        shortcode: splitted[4],
      };
    });
    setAirportsLoaded(parsedAirports);
    setIsLoading(false);
  }, [title]);

  useEffect(() => {
    fetchAirportsHandler();
  }, [fetchAirportsHandler]);

  useEffect(() => {
    if (airportInputValue.length > 0) {
      changeAirportInputFilled(true);
    } else {
      changeAirportInputFilled(false);
      fetchAirportsHandler();
    }
    let timeoutIdentifier;
    if (airportInputValue.length >= 3) {
      timeoutIdentifier = setTimeout(() => {
        searchAirportsHandler(airportInputValue);
      }, 500);
    }
    return () => {
      clearTimeout(timeoutIdentifier);
    };
  }, [airportInputValue, fetchAirportsHandler]);

  return (
    <div className="airportChooser">
      <CloseSecondary />
      <SecondaryTitle icon={planeIcon} text={props.title} />
      <div className={"airportChooser_form"}>
        <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            name="airportCode"
            id="airportCodeInput"
            placeholder="Unesite grad, aerodrom ili mesto"
            value={airportInputValue}
            onChange={airportInputHandler}
          />
        </form>
        {airportInputFilled && (
          <div
            className={"airportChooser_form_cancel"}
            onClick={clearInputHandler}
          >
            <CancelRounded />
          </div>
        )}
        <HorizontalDivider secondary={true} />
      </div>
      <div className={"airportChooser_results"}>
        {!isLoading && airportsLoaded.length === 0 && (
          <div className="airportChooser_loading">Aerodrom nije pronađen</div>
        )}
        {!isLoading &&
          airportsLoaded.length > 0 &&
          airportsLoaded.map((airport, cnt) => {
            return (
              <AirportChooserObject
                key={cnt}
                icon={planeIcon}
                data={airport}
                onAirportClick={handleAirportClick}
              />
            );
          })}
        {isLoading && <div className="airportChooser_loading">Učitavanje...</div>}
      </div>
    </div>
  );
};

export default AirportChooser;
