import React, { useRef, useState, useCallback } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  setTernaryFormContent,
  changeFormPosition,
  toggleLoadingStatus,
  setQuaternaryFormContent,
  setPriceOverviewCameFrom,
} from "../../../store/ui-slice";

import {
  setFlightDetails,
  setCartDetails,
  setSessionID,
  setFlightID,
  setAncillaryData
} from "../../../store/form-slice";

import classes from "./ResultModule.module.css";
import ResultFlightOption from "./ResultFlightOption";
import ResultCardFlightDirection from "./ResultCardFlightDirection";

const ResultCard = (props) => {
  const dispatch = useDispatch();
  // form position
  // const formPosition = useSelector((state) => state.ui.formPosition);

  const scrollToRef = useRef();

  // logger
  const token = useSelector((state) => state.auth.token);
  const storeSearch = useSelector((state) => state.form.storeSearch);
  const thomalexObject = useSelector((state) => state.form.thomalexObject);

  const loggerReq = useCallback(async () => {
    // store step log
    let storeStepLogURL = "https://"+process.env.REACT_APP_API_URL+"/api/storeStepLog";
    const storeStepLogReq = await fetch(storeStepLogURL, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      body: JSON.stringify({
        SearchQueryId: storeSearch.id,
        StepId: 2,
        PortalId: 1,
        IbeCode: process.env.REACT_APP_TOKEN_PORTAL_CODE,
        Session: thomalexObject.SessionId,
      }),
    });
    const storeStepLog = await storeStepLogReq.json();
    console.log("storeStepLog", "2", storeStepLog.data);
  }, [storeSearch, thomalexObject.SessionId, token]);

   const moreInfoHandler = (flightData, sessionID, flightID) => {
    dispatch(setTernaryFormContent("DETALJI_LETA"));
    dispatch(changeFormPosition(-2));
    dispatch(setFlightDetails(flightData));
    dispatch(setSessionID(sessionID));
    dispatch(setFlightID(flightID));
  };

  const [priceOptionSent, setPriceOptionSent] = useState(false);
  const [priceOptions, setPriceOptions] = useState();

  const priceOptionReq = useCallback(async () => {
    dispatch(toggleLoadingStatus());
    setPriceOptionSent(true);
    // thomalex fetch token

    //const rawToken = await fetch("https://"+process.env.REACT_APP_API_URL+"/api/flight_token?client_id="+process.env.REACT_APP_TOKEN_PORTAL_CODE);
    //const rawToken = await fetch("https://office.airfantast.com/api/flight_token?client_id=E10GH26U41A");
    const rawToken = await fetch(
      "https://office.airfantast.com/api/flight_token?client_id="+process.env.REACT_APP_TOKEN_PORTAL_CODE
    );
    const response = await rawToken.json();
    const thomalexToken = response.value;

    // const priceOptionsURL = './thomalex-3-response.json';
    const priceOptionsURL =
      "https://rest.resvoyage.com/api/v1/air/upsell?sessionId=" +
      props.sessionID +
      "&itineraryId=" +
      props.flightID;
    const priceOptionsRes = await fetch(priceOptionsURL, {
      method: "GET",

      headers: {
        Authorization: "Bearer " + thomalexToken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    // log storage
    fetch(
      "https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=" +
        storeSearch.id +
        "&step=2&type=request",
      {
        method: "POST",
        body: JSON.stringify({
          sessionId: props.sessionID,
          itineraryId: props.flightID,
        }),
      }
    );

    if (!priceOptionsRes.ok) {
      const errMessage = "An error occured: " + priceOptionsRes.status;
      throw new Error(errMessage);
    }
    const priceOptions = await priceOptionsRes.json();

    // log storage
    fetch(
      "https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=" +
        storeSearch.id +
        "&step=2&type=response",
      {
        method: "POST",
        body: JSON.stringify(priceOptions),
      }
    );

    if (priceOptions.ErrorMessage === "upsell_not_available") {
      dispatch(toggleLoadingStatus());
      return alert("Upsell not available!");
    }
    setPriceOptions({
      offers: priceOptions.PricedItineraries,
      infos: priceOptions.AirlineFareFamilies.reduce(
        (obj, item) => ({ ...obj, [item["Code"]]: item }),
        {}
      ),
    });
    loggerReq();
    scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    dispatch(toggleLoadingStatus());
  }, [props.sessionID, props.flightID, dispatch, loggerReq, storeSearch.id]);
  // }, [dispatch])

  const priceOptionLufthansa = () => {
    dispatch(toggleLoadingStatus());
    setPriceOptionSent(true);
    let departRN, arrivalRN;
    departRN = props.lufthansaDepartSegments.map((el) => {
      return (el.RouteNumber);
    })
    if (props.lufthansaArriveSegments) {
      arrivalRN = props.lufthansaArriveSegments.map((el) => {
        return (el.RouteNumber);
      })
    }
    const priceOptionsLufthansa = props.lufthansaCheck(departRN,arrivalRN);
    console.log(priceOptionsLufthansa);

    console.log(
      priceOptionsLufthansa.AirlineFareFamilies.reduce(
        (obj, item) => ({ ...obj, [item["Code"]]: item }),
        {}
      )
    )


    setPriceOptions({
      offers: priceOptionsLufthansa.PricedItineraries,
      infos: priceOptionsLufthansa.AirlineFareFamilies.reduce(
        (obj, item) => ({ ...obj, [item["Code"]]: item }),
        {}
      ),
    });


    scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    dispatch(toggleLoadingStatus());
  }



  const priceOptionTravelFusion = useCallback(async () => {
      dispatch(toggleLoadingStatus());
      setPriceOptionSent(true);
      // thomalex fetch token
      //const rawToken = await fetch("https://"+process.env.REACT_APP_API_URL+"/api/flight_token?client_id="+process.env.REACT_APP_TOKEN_PORTAL_CODE);
      //const rawToken = await fetch("https://office.airfantast.com/api/flight_token?client_id=E10GH26U41A");
      const rawToken = await fetch(
        "https://office.airfantast.com/api/flight_token?client_id="+process.env.REACT_APP_TOKEN_PORTAL_CODE
      );
      const response = await rawToken.json();
      const thomalexToken = response.value;
  
      const cartAddURL = "https://rest.resvoyage.com/api/v1/cart/air/add";
      // const cartAddURL = './3-response.json';
      const cartAddRes = await fetch(cartAddURL, {
        // /*
        method: "POST",
        headers: {
          Authorization: "Bearer " + thomalexToken,
          "Content-Type": "application/json",
        },
        withCredentials: true,
        body: JSON.stringify({
          SessionId: props.sessionID,
          ItemId: props.flightID,
        }),
        //*/
      });
  
      // log storage
      fetch(
        "https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=" +
          storeSearch.id +
          "&step=3.1&type=request",
        {
          method: "POST",
          body: JSON.stringify({
            SessionId: props.sessionID,
            ItemId: props.flightID,
          }),
        }
      );
  
      if (!cartAddRes.ok) {
        const errMessage = "An error occured: " + cartAddRes.status;
        throw new Error(errMessage);
      }
      const cartAdd = await cartAddRes.json();
      console.log("cartAdd response", cartAdd);
  
      // log storage
      fetch(
        "https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=" +
          storeSearch.id +
          "&step=3.1&type=response",
        {
          method: "POST",
          body: JSON.stringify(cartAdd),
        }
      );

      //console.log("result card add", props.sessionID, props.flightID);

      
      //fetch ancillary
      const ancillarySessionID = cartAdd.SessionId;
      const ancillaryAirID = cartAdd.ShoppingCart.Air.Id;
      const ancillaryURL = "https://rest.resvoyage.com/api/v1/cart/ancillary?sessionId="+ancillarySessionID+"&itineraryId="+ancillaryAirID;
      //const ancillaryURL = '/BEG-BVA-ANCIL-2WAY.json';
   
      // log storage
      fetch(
        "https://storage.airfantast.com/thomalex-logs/store_request.php?sessionId=" +
          storeSearch.id +
          "&step=3.2&type=request",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId: ancillarySessionID,
            itineraryId: ancillaryAirID,
          }),
        }
      );

      const ancillaryRes = await fetch(ancillaryURL,{
        headers: {
          Authorization: "Bearer " + thomalexToken,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      const ancillaryData = await ancillaryRes.json();
      console.log("ancillary result", ancillaryData);

      // log storage
      fetch(
        "https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=" +
          storeSearch.id +
          "&step=3.2&type=response",
        {
          method: "POST",
          body: JSON.stringify(ancillaryData),
        }
      );

      dispatch(setAncillaryData(ancillaryData));

      // flight rules
      const flightRulesSessionID = cartAdd.SessionId;
      const flightRulesAirID = cartAdd.ShoppingCart.Air.Id;
      const flightRulesURL = "https://rest.resvoyage.com/api/v1/air/references/flight/rules?sessionId="+flightRulesSessionID+"&itineraryId="+flightRulesAirID;

      // log storage
      fetch(
        "https://storage.airfantast.com/thomalex-logs/store_request.php?sessionId=" +
          storeSearch.id +
          "&step=3.3&type=request",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId: flightRulesSessionID,
            itineraryId: flightRulesAirID,
          }),
        }
      );

      const flightRulesRes = await fetch(flightRulesURL,{
        headers: {
          Authorization: "Bearer " + thomalexToken,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      const flightRulesData = await flightRulesRes.json();
      console.log("flight rules result", flightRulesData);

      // log storage
      fetch(
        "https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=" +
          storeSearch.id +
          "&step=3.3&type=response",
        {
          method: "POST",
          body: JSON.stringify(flightRulesData),
        }
      );


  
      dispatch(changeFormPosition(-3));
      dispatch(setQuaternaryFormContent("PREGLED_CENE"));
      dispatch(setPriceOverviewCameFrom(-1));
      dispatch(toggleLoadingStatus());
      dispatch(setSessionID(props.sessionID));
      dispatch(setFlightID(props.flightID));
      dispatch(setCartDetails(cartAdd));
      setPriceOptionSent(false);
    }, [props.sessionID, props.flightID, dispatch, storeSearch.id]);



  const bookRequest = useCallback(async () => {
    dispatch(toggleLoadingStatus());
    setPriceOptionSent(true);
    // thomalex fetch token
    //const rawToken = await fetch("https://"+process.env.REACT_APP_API_URL+"/api/flight_token?client_id="+process.env.REACT_APP_TOKEN_PORTAL_CODE);
    //const rawToken = await fetch("https://office.airfantast.com/api/flight_token?client_id=E10GH26U41A");
    const rawToken = await fetch(
      "https://office.airfantast.com/api/flight_token?client_id="+process.env.REACT_APP_TOKEN_PORTAL_CODE
    );
    const response = await rawToken.json();
    const thomalexToken = response.value;

    const cartAddURL = "https://rest.resvoyage.com/api/v1/cart/air/add";
    // const cartAddURL = './3-response.json';
    const cartAddRes = await fetch(cartAddURL, {
      // /*
      method: "POST",
      headers: {
        Authorization: "Bearer " + thomalexToken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      body: JSON.stringify({
        SessionId: props.sessionID,
        ItemId: props.flightID,
      }),
      //*/
    });

    // log storage
    fetch(
      "https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=" +
        storeSearch.id +
        "&step=3&type=request",
      {
        method: "POST",
        body: JSON.stringify({
          SessionId: props.sessionID,
          ItemId: props.flightID,
        }),
      }
    );

    if (!cartAddRes.ok) {
      const errMessage = "An error occured: " + cartAddRes.status;
      throw new Error(errMessage);
    }
    const cartAdd = await cartAddRes.json();
    console.log("cartAdd response", cartAdd);

    // log storage
    fetch(
      "https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=" +
        storeSearch.id +
        "&step=3&type=response",
      {
        method: "POST",
        body: JSON.stringify(cartAdd),
      }
    );

    console.log("result card add", props.sessionID, props.flightID);

    dispatch(changeFormPosition(-3));
    dispatch(setQuaternaryFormContent("PREGLED_CENE"));
    dispatch(setPriceOverviewCameFrom(-1));
    dispatch(toggleLoadingStatus());
    dispatch(setSessionID(props.sessionID));
    dispatch(setFlightID(props.flightID));
    dispatch(setCartDetails(cartAdd));
    setPriceOptionSent(false);
  }, [props.sessionID, props.flightID, dispatch, storeSearch.id]);
  // },[dispatch])

  let footerPart = (
    <div className={classes.footerButtons}>
      <div
        className={classes.footerButton + " resultFooterButtonLeft black"}
        onClick={() => {
          moreInfoHandler(props.data, props.flightID, props.sessionID);
        }}
      >
        Više
        <br />
        informacija
      </div>
      <div
        className={classes.footerButton + " resultFooterButtonRight black"}
        disabled={priceOptionSent}
        onClick={() => {
          if (props.ifAmadeus) {
            priceOptionReq();
          } else if (props.ifLufthansa) {
            priceOptionLufthansa();
          } else if (props.ifTravelFusion) {
            priceOptionTravelFusion();
          } else {
            bookRequest();
          }
        }}
      >
        Rezerviši {props.ifAmadeus ? " ***" : null}
        <br />
        {props.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
        {props.currency}
      </div>
    </div>
  );

  let resultCardClass = " resultCard";
  let priceOptionsRender;
  if (priceOptions) {
    resultCardClass = " resultCard allWhite";
    priceOptionsRender = priceOptions.offers.map((el, ind) => {
      //console.log(priceOptions.infos[el.AirItineraryPricingInfo.AdditionalFareInfos[0].FareFamily]);
      return (
        <ResultFlightOption
          key={ind}
          info={
            priceOptions.infos[
              el.AirItineraryPricingInfo.AdditionalFareInfos ?
              el.AirItineraryPricingInfo.AdditionalFareInfos[0].FareFamily :
              el.AirItineraryPricingInfo.FareFamily
            ]
          }
          data={el}
          sessionID={props.sessionID}
          flightID={props.flightID}
        />
      );
    });
    footerPart = priceOptionsRender;
  }

  return (
    <div className={classes.resultCard + resultCardClass}>
      <div className={classes.resultCardHeader + " resultCardHeader"}>
        {props.klasa}
      </div>
      <ResultCardFlightDirection {...props} />
      {props.ow !== true ? (
        <>
          <div className={classes.dashedDivider}></div>
          <ResultCardFlightDirection povratak={true} {...props} />
        </>
      ) : (
        <div style={{ height: "0.5rem" }}></div>
      )}
      <div ref={scrollToRef}></div>
      {footerPart}
    </div>
  );
};

export default ResultCard;
