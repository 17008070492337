const AirportTouchdown = () => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <path d="M0,0H36V36H0Z" fill="none" />
      <path
        className="airport-touchdown"
        d="M2.5,29.737h31V33H2.5ZM29.976,24.6a2.45,2.45,0,0,0,1.273-4.732l-8.664-2.317-4.5-14.717L14.933,2V15.509l-8.109-2.17L5.306,9.554,2.941,8.918v8.435Z"
        transform="translate(0)"
      />
    </svg>
  );
};

export default AirportTouchdown;
