import React from "react";
import classes from "./CalendarModule.module.css";

import CalendarSelector from "./Selectors/CalendarSelector";
import VerticalDivider from "../../UI/VerticalDivider";
import HorizontalDivider from "../../UI/HorizontalDivider";

import { useSelector, useDispatch } from "react-redux";

import {
  setSecondaryFormContent,
  changeFormPosition,
} from "../../../store/ui-slice";

const CalendarModule = (props) => {
  const oneWay = useSelector((state) => state.form.oneWay);

  const startDate = useSelector((state) => state.form.startDate);
  const endDate = useSelector((state) => state.form.endDate);
  
  const startFlex = useSelector((state) => state.form.startFlex);
  const endFlex = useSelector((state) => state.form.endFlex);

  const dispatch = useDispatch();

  const activateCalendarHandler = () => {
    dispatch(setSecondaryFormContent("KALENDAR"));
    dispatch(changeFormPosition(-1));
  };

  //console.log('calendar chooser front page', startDate, endDate);

  return (
    <div className={classes.calendarModule}>
      <CalendarSelector
        title={"Datum polaska"}
        calendarData={null}
        flexibleText={"Odaberite polazak"}
        onClick={activateCalendarHandler}
        data={{date: startDate, flex: startFlex}}
        startD={startDate}
      />
      {!oneWay && <VerticalDivider />}
      {!oneWay && (
        <CalendarSelector
          title={"Datum povratka"}
          calendarData={null}
          flexibleText={"Odaberite povratak"}
          onClick={activateCalendarHandler}
          data={{date: endDate, flex: endFlex}}
          endD={endDate}
        />
      )}
      <HorizontalDivider />
    </div>
  );
};

export default CalendarModule;
