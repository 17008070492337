const SelectTouchIcon = () => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="24.156"
      height="32"
      viewBox="0 0 24.156 32"
    >
      <g id="touch_app_black_24dp" transform="translate(5 -1)">
        <g id="Group_21" data-name="Group 21" transform="translate(-5 1)">
          <g id="Group_20" data-name="Group 20">
            <path
              className="select-touch-icon"
              data-name="Path 47"
              d="M25.623,18.432l-4.937-2.469a8.3,8.3,0,0,0,3.23-6.583,8.381,8.381,0,1,0-12.19,7.451V21.8c-3.276-.7-3.078-.67-3.444-.67a3.022,3.022,0,0,0-2.149.9L4,24.192l7.756,7.756A3.527,3.527,0,0,0,14.27,33h9.6a3.03,3.03,0,0,0,3-2.545l1.219-7.177A4.56,4.56,0,0,0,25.623,18.432Zm-.533,4.343L23.87,29.952h-9.6A.562.562,0,0,1,13.9,29.8L8.3,24.192l6.476,1.356V9.381a.762.762,0,0,1,1.524,0v9.143h2.682l5.272,2.636A1.534,1.534,0,0,1,25.09,22.775ZM10.2,9.381a5.333,5.333,0,0,1,10.667,0A5.279,5.279,0,0,1,19.345,13.1V9.381a3.81,3.81,0,0,0-7.619,0V13.1A5.279,5.279,0,0,1,10.2,9.381Z"
              transform="translate(-4 -1)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SelectTouchIcon;