import React from "react";
import useForm from "../../Utils/useForm";
import { signupForm } from "../../Utils/formConfig";

import Button from "../../UI/Button";


export default function SignupModule(props) {
  const { renderFormInputs, isFormValid } = useForm(signupForm);

  return (
    <div className="authModuleHolder">
      <h2>Registracija</h2>
      <form
        autoComplete="off"
        onSubmit={props.handleFormSubmit}
      >
        {renderFormInputs()}
        <Button
          type="submit"
          filled
          className={!isFormValid() ? " disabled" : ""}
          onClick={()=>{}}
        >
          Registrujte se
        </Button>
      </form>
    </div>
  );
}
