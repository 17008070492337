const StopwatchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20.99"
      viewBox="0 0 18 20.99"
    >
      <path
        d="M15.07,1.01h-6v2h6Zm-4,13h2v-6h-2ZM19.1,7.39l1.42-1.42a11.048,11.048,0,0,0-1.41-1.41L17.69,5.98A9,9,0,1,0,19.1,7.39ZM12.07,20.01a7,7,0,1,1,7-7A6.995,6.995,0,0,1,12.07,20.01Z"
        transform="translate(-3.07 -1.01)"
      />
    </svg>
  );
};

export default StopwatchIcon;
