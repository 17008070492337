import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBuyerInfo, setPnrResponse, setStoreReservation } from '../../../store/form-slice';
import { setChoiceWindow, setChoiceOption, setInfoWindow, changeFormPosition, toggleLoadingStatus, /*setIframePayment,*/ setInfoWindowCloseRefresh, setQuaternaryFormContent } from '../../../store/ui-slice';
import ArrowLeft from '../../Icons/ArrowLeft';
import ArrowRight from '../../Icons/ArrowRight';
import BankIcon from '../../Icons/BankIcon';
import CreditCardIcon from '../../Icons/CreditCardIcon';
import DateInput from '../../UI/DateInput';
import HorizontalDivider from '../../UI/HorizontalDivider';
import Select from "react-select";

import classes from './ResultModule.module.css';
import suggestions from "./country_codes.json";

const CheckoutForm = () => {

	const dispatch = useDispatch();

	const scrollToRef = useRef();

	const priceOverviewCameFrom = useSelector((state) => state.ui.priceOverviewCameFrom)
	const cartDetails = useSelector((state) => state.form.cartDetails);

	const session_id = useSelector((state) => state.form.sessionID);
	const flight_id = useSelector((state) => state.form.flightID);

	const ancillaryData = useSelector((state) => state.form.ancillaryData);

	const [ancillarySelectBasic, setAncillarySelectBasic] = useState([]);
	const [ancillarySelectBagOutgoing, setAncillarySelectBagOutgoing] = useState([]);
	const [ancillarySelectBagReturn, setAncillarySelectBagReturn] = useState([]);
	const [ancillarySelectAdvanced, setAncillarySelectAdvanced] = useState([]);

	const cDetails = cartDetails ? cartDetails.ShoppingCart.Air.AirItineraryPricingInfo.PTC_FareBreakdowns : null;
	//const segmentSource = cartDetails ? cartDetails.ShoppingCart.Air.AirItinerary.OriginDestinationOptions : null;

	const choiceOption = useSelector((state) => state.ui.choiceOption);

	//console.log('cartdetails', cartDetails);

	const isItTravelFusion = cartDetails ? (cartDetails.ShoppingCart.Air.OfficeId === "LZYBYR80UC9JXBPJ" ? true : false) : false

	const phoneCodeOptions = suggestions.map((el, ind) => {
		return {
			value: "+" + el.PhoneCode,
			label: "+" + el.PhoneCode + " " + el.Country,
		};
	});

	const [overViewState, setOverViewState] = useState(1);

	const handleFormReset = async () => {
		//dispatch(changeFormPosition(priceOverviewCameFrom))
		dispatch(toggleLoadingStatus());

		const rawToken = await fetch("https://office.airfantast.com/api/flight_token?client_id=" + process.env.REACT_APP_TOKEN_PORTAL_CODE);
		const response = await rawToken.json();
		const thomalexToken = response.value;

		const resetCartURL = "https://rest.resvoyage.com/api/v1/cart/item/remove?sessionId=" + session_id + "&type=air&itemKey=" + flight_id;

		//console.log(session_id, flight_id);

		const resetCartReq = await fetch(resetCartURL,
			{
				method: "POST",
				headers: {
					Authorization: "Bearer " + thomalexToken,
					"Content-Type": "application/json",
				},
				withCredentials: true,
				//body: resetCartData
			}
		);

		const resetCartResp = await resetCartReq.json();
		console.log(resetCartResp);

		dispatch(toggleLoadingStatus());
		dispatch(changeFormPosition(priceOverviewCameFrom))

	}

	const cardsBase = useSelector(
		(state) => state.form.thomalexObject
	);

	const sessionID = cardsBase.SessionId;

	const passengerTypes = useMemo(
		() => {
			return {
				ADT: ["Odrasli", "Odrasli", 1],
				CHD: ["Dete", "Deca", 2],
				INF: ["Beba", "Bebe", 3],
			}
		}
		, []
	)


	// logger
	const token = useSelector((state) => state.auth.token);
	//const userId = useSelector((state) => state.auth.userId)
	const storeSearch = useSelector((state) => state.form.storeSearch);
	const thomalexObject = useSelector((state) => state.form.thomalexObject);
	const oneWay = useSelector((state) => state.form.oneWay)


	const loggerReq = useCallback(async (stepID = 3) => {
		// store step log
		let storeStepLogURL = "https://"+process.env.REACT_APP_API_URL+"/api/storeStepLog";
		const storeStepLogReq = await fetch(storeStepLogURL, {
			method: "POST",
			headers: {
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			},
			withCredentials: true,
			body: JSON.stringify({
				SearchQueryId: storeSearch.id,
				StepId: stepID,
				PortalId: 1,
				IbeCode: 'e4Lr08M1CDI',
				Session: thomalexObject.SessionId
			})
		})
		const storeStepLog = await storeStepLogReq.json();
		//console.log("storeStepLog", stepID, storeStepLog.data);

	}, [storeSearch, thomalexObject.SessionId, token])


	useEffect(() => {
		loggerReq()
	}, [loggerReq])
	// logger


	//const exchangeData = useSelector((state) => state.form.exchangeData);

	//const userProfile = useSelector((state) => state.auth.profile);
	// console.log(exchangeData, userProfile);

	let detailsRender, totalPriceRender, totalPriceRaw;

	const [values, setValues] = useState([]);

	let ticketPriceTotal = 0;

	if (cartDetails !== null) {
		detailsRender = cartDetails.ShoppingCart.Air.AirItineraryPricingInfo.PTC_FareBreakdowns.map((el, ind) => {
			ticketPriceTotal += el.TotalPrice;
			return (
				<div className={classes.priceHolder} key={ind}>
					<div className={classes.priceHeadline + " optionsHeadline bold"}>
						Broj karata: {passengerTypes[el.PassengerType][1]} x {el.PassengerCount}
					</div>
					<div className={classes.priceSummary + " darkText light"}>
						<span>Cena karte</span><span>
							{el.BasePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")},00 {cartDetails.CurrencyCode}
						</span>
					</div>
					<div className={classes.priceSummary + " darkText light"}>
						<span>Takse</span><span>
							{el.Tax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")},00 {cartDetails.CurrencyCode}
						</span>
					</div>
					<div className={classes.priceSummary + " darkText light"}>
						<span>Ukupno</span><span>
							{el.TotalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")},00 {cartDetails.CurrencyCode}
						</span>
					</div>
				</div>
			)
		});
		totalPriceRaw = cartDetails.ShoppingCart.Air.AirItineraryPricingInfo.TotalPrice;
		totalPriceRender = totalPriceRaw.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ",00 " + cartDetails.ShoppingCart.Air.AirItineraryPricingInfo.CurrencyCode;
	}


	useEffect(() => {
		//console.log("setValues called");
		if (cartDetails !== null) {
			setValues(cartDetails.ShoppingCart.Travellers.map((el, ind) => {
				return {
					ind: ind,
					id: el.Id,
					passType: el.TypeCode,
					gender: null,
					name: null,
					lastname: null,
					day: null,
					month: null,
					year: null,
					valid: false,
					ancillary: []
				}
			}))
		}
	}, [cartDetails])

	const setValue = (index, whichvalue, newvalue) => {
		//console.log('index: ' + index);
		//console.log('property name: '+ whichvalue);
		//console.log('new value: '+ newvalue);
		let newArr = JSON.parse(JSON.stringify(values));
		newArr[index][whichvalue] = newvalue;
		setValues(newArr);
	}

	const [tempAncillaryValues, setTempAncillaryValues] = useState([])

	const setTempAncillary = (el, el0, ind) => {
		console.log('setTempAncillary', el, el0, ind);
		const newArr = JSON.parse(JSON.stringify(tempAncillaryValues));

		if (typeof newArr[ind] === 'undefined') {
			newArr[ind] = [];
		}

		const arr = newArr[ind];
		const objIndex = arr.findIndex(obj => obj.id === el.id);

		if (objIndex !== -1) {
			arr.splice(objIndex, 1); // Remove object
		} else {
			arr.push({
				id: el.id,
				name: el.description,
				price: el.amount.toLocaleString("sr-RS", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
				priceNr: el.amount,
				currency: el.currency,
				airlineCode: el0.airlineCode,
				flightNumber: el0.flightNumber,
				departureAirportCode: el0.departureAirportCode,
				arrivalAirportCode: el0.arrivalAirportCode
			}); // Insert object
		}
		setTempAncillaryValues(newArr);
	}

	const setAncillaryValues = (ind, id, is_per_pax = false) => {
		let newArr = JSON.parse(JSON.stringify(values));
		//console.log(ind,id,is_per_pax);
		if (is_per_pax) {
			ind = 0;
		}
		if (typeof newArr[ind] === 'undefined') {
			newArr[ind] = {};
		}
		if (typeof newArr[ind].ancillary === 'undefined') {
			newArr[ind].ancillary = [];
		}

		console.log(newArr[ind], newArr[ind].ancillary, id)

		let isInArray = newArr[ind].ancillary.indexOf(id);
		if (isInArray > -1) {
			newArr[ind].ancillary.splice(isInArray, 1);
		} else {
			newArr[ind].ancillary.push(id);
		}
		setValues(newArr);
	}

	const [ancillaryOpened, setAncillaryOpened] = useState([]);


	const toggleAncillaryControl = (ind) => {
		//console.log('toggle', ind, ancillaryOpened)
		let newArr = [...ancillaryOpened];
		const isInArray = newArr.indexOf(ind);
		if (isInArray > -1) {
			newArr.splice(isInArray, 1);
		} else {
			newArr.push(ind)
		}
		setAncillaryOpened(newArr);
	}



	let detailsAncillaryHeader;
	let parsedAncillaryVisual = [];
	let detailsAncillaryRender = [];
	let ancillaryPriceTotal = 0;

	// if (tempAncillaryValues.some(arr => arr.length > 0)) {
	if (ancillarySelectBasic.length>0 ||
		ancillarySelectBagOutgoing.length>0 ||
		ancillarySelectBagReturn.length>0 ||
		ancillarySelectAdvanced.length>0) 
	{
		detailsAncillaryHeader = (
			<>
				<div style={{ margin: "0.5rem 0" }}>
					<HorizontalDivider gray relative />
				</div>
				<div className={classes.priceHolder}>
					<div className={classes.priceHeadline + " optionsHeadline bold"}>
						Dodatne usluge:
					</div>
				</div>
			</>
		)


		ancillarySelectBasic.forEach(el => {
			let parsedObj = JSON.parse(el.value);
			ancillaryPriceTotal+= parseFloat(parsedObj.Amount)
		})
		ancillarySelectBagOutgoing.forEach(el => {
			let parsedObj = JSON.parse(el.value);
			ancillaryPriceTotal+= parseFloat(parsedObj.Amount)
		})
		ancillarySelectBagReturn.forEach(el => {
			let parsedObj = JSON.parse(el.value);
			ancillaryPriceTotal+= parseFloat(parsedObj.Amount)
		})
		ancillarySelectAdvanced.forEach(el => {
			let parsedObj = JSON.parse(el.value);
			ancillaryPriceTotal+= parseFloat(parsedObj.Amount)
		})

		parsedAncillaryVisual = ancillarySelectBasic.map((el) => {
			let parsedObj = JSON.parse(el.value);
			return ({name: parsedObj.Description, price: parsedObj.Amount});
		});

		parsedAncillaryVisual.push(
		...ancillarySelectBagOutgoing.map((el) => {
			let parsedObj = JSON.parse(el.value);
			return ({name: parsedObj.Description, price: parsedObj.Amount});
		})
		);

		parsedAncillaryVisual.push(
		...ancillarySelectBagReturn.map((el) => {
			let parsedObj = JSON.parse(el.value);
			return ({name: parsedObj.Description, price: parsedObj.Amount});
		})
		)
		parsedAncillaryVisual.push(
		...ancillarySelectAdvanced.map((el) => {
			let parsedObj = JSON.parse(el.value);
			return ({name: parsedObj.Description, price: parsedObj.Amount});
		})
		)

		console.log('parsedAncillaryVisual', parsedAncillaryVisual);

		
			const result = [];
			const map = new Map();
			
			parsedAncillaryVisual.forEach(item => {
			  if (!map.has(item.name)) {
				map.set(item.name, {name: item.name, price: item.price, count: 1});
			  } else {
				let currentItem = map.get(item.name);
				currentItem.price += item.price;
				currentItem.count++;
				map.set(item.name, currentItem);
			  }
			});
			
			for (let [key, value] of map.entries()) {
			  let name = value.name;
			  let count = value.count;
			  let price = value.price;
			  name += count > 1 ? ` x${count}` : '';
			  result.push({name, price});
			}

		detailsAncillaryRender = result.map((el,ind) => {
			return (
			<div className={classes.priceSummary + " darkText light"} key={ind}>
				<span style={{wordWrap:"break-word",maxWidth:"75%"}}>{el.name}</span>
				<span style={{alignSelf:"flex-end"}}>{
					el.price.toLocaleString("sr-RS", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
				}</span>
			</div>
			)
		});

		detailsAncillaryRender.push(
			<div style={{margin:"0.5rem 0 0"}} key={Math.random()}>
				<HorizontalDivider gray relative />
			</div>
		)
	}


	const [detailFareOpened, setDetailedFareOpened] = useState(false);

	const toggledetailFareControl = (oldToggle) => {
		setDetailedFareOpened(oldToggle => !oldToggle);
	}

	const [nextStep, setNextStep] = useState(false);

	useEffect(() => {
		let newArr = JSON.parse(JSON.stringify(values));
		let allValid = []
		newArr.forEach((el, ind) => {
			if (typeof (values[ind]) !== 'undefined') {
				if (
					el.gender !== null &&
					el.name !== null &&
					el.lastname !== null &&
					el.day !== null && parseInt(el.day) > 0 && parseInt(el.day) < 32 &&
					el.month !== null && parseInt(el.month) > 0 && parseInt(el.month) < 13 &&
					el.year !== null && parseInt(el.year) > 1900
				) {
					allValid[ind] = true;
				} else {
					allValid[ind] = false;
				}
			}
		})
		setNextStep(allValid.every(Boolean));
	}, [values])


	const dobInputChangeHandler = (ind, nm, val) => {

		let val2 = parseInt(val) < 10 ? "0" + parseInt(val) : val;
		switch (nm) {
			case "day":
				if (val2.length > 2) {
					val2 = parseInt(val2).toString().substr(0, 2);
				}
				if (parseInt(val2) > 31) {
					val2 = "31";
				}
				// setValue(ind, nm, val2);
				break;
			case "month":
				if (val2.length > 2) {
					val2 = parseInt(val2).toString().substr(0, 2);
				}
				if (parseInt(val2) > 12) {
					val2 = "12";
				}
				// setValue(ind, nm, val2);
				break;
			case "year":
				val2 = parseInt(val) < 1000 ? "0" + parseInt(val) : val;
				val2 = parseInt(val2) < 100 ? "00" + parseInt(val2) : val2;
				val2 = parseInt(val2) < 10 ? "000" + parseInt(val2) : val2;
				if (val2.length > 4) {
					val2 = parseInt(val2).toString().substr(0, 4);
				}
				if (parseInt(val2) > new Date().getFullYear()) {
					val2 = new Date().getFullYear()
				}
				// setValue(ind, nm, val2);
				break;
			default:
		}
		//console.log(ind, nm, val2);
		setValue(ind, nm, val2);
	};


	let passengerInputs;
	let ancillary_per_flight = [];
	let ancillary_per_type = [];
	if (cartDetails !== null) {

		if (ancillaryData !== null) {

			const result = {
				SpeedyBoarding: [],
				Baggage: [],
				AdvancedAncillary: []
			  };
			
			  const grouped = ancillaryData.reduce((acc, item) => {
				(acc[item.AncillaryType] = acc[item.AncillaryType] || []).push(item);
				return acc;
			  }, {});
			
			  for (let [type, items] of Object.entries(grouped)) {
				if (type === 'Baggage') {
				  const baggageGrouped = items.reduce((acc, item) => {
					item.FlightSegments.forEach(segment => {
					  acc[segment.FlightNumber] = acc[segment.FlightNumber] || [];
					  const clonedItem = {...item};  // shallow copy of item
					  clonedItem.FlightSegments = [segment]; // overwrite FlightSegments
					  acc[segment.FlightNumber].push(clonedItem);
					});
					return acc;
				  }, {});
			
				  // Assign the baggageGrouped values to result.Baggage with index-based keys
				  let index = 0;
				  for (let value of Object.values(baggageGrouped)) {
					result.Baggage[index] = value;
					index++;
				  }
				} else {
				  result[type].push(...items);
				}
			  }
			  console.log(result);
			  result.AdvancedAncillary.unshift({Description:"Izaberite uslugu", Id: ""})
			  result.SpeedyBoarding.unshift({Description:"Izaberite uslugu", Id: ""})
			  result.Baggage[0].unshift({Description:"Izaberite uslugu", Id: ""})
			  if (result.Baggage.length>1) {
				result.Baggage[1].unshift({Description:"Izaberite uslugu", Id: ""})
			  }

			  ancillary_per_type = result;
		}


		passengerInputs = cartDetails.ShoppingCart.Travellers.map((el, ind) => {
			return (
				<div className={classes.passengerInputHolder + " " + (values[ind] ? values[ind]["valid"] ? " passangerInputHolderValid" : "" : "")} key={ind} data-passid={el.Id}>
					<div className={classes.passengerTitle + " bold optionsHeadline"}>
						{passengerTypes[el.TypeCode][0]}
					</div>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.passengerInputLabel}>
							Pol
						</div>
						<div className={classes.passengerInput}>
							<div className={[classes.genderButton, values[ind] ? (values[ind]["gender"] === "woman" ? classes.genderButtonActive + " genderActive" : null) : null].join(" ")} onClick={() => { setValue(ind, "gender", "woman") }}>Ženski</div>
							<div className={[classes.genderButton, values[ind] ? (values[ind]["gender"] === "man" ? classes.genderButtonActive + " genderActive" : null) : null].join(" ")} onClick={() => { setValue(ind, "gender", "man") }}>Muški</div>
						</div>
					</div>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.passengerInputLabel}>
							Ime
						</div>
						<div className={classes.passengerInput}>
							<input className={"passengerInput"} type="text" autoComplete='false' value={values[ind] ? values[ind]["name"] ? values[ind]["name"] : "" : ""} onChange={(e) => {
								setValue(ind, "name", e.currentTarget.value);
							}} />
						</div>
					</div>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.passengerInputLabel}>
							Prezime
						</div>
						<div className={classes.passengerInput}>
							<input className={"passengerInput"} type="text" autoComplete='false' value={values[ind] ? values[ind]["lastname"] ? values[ind]["lastname"] : "" : ""} onChange={(e) => {
								setValue(ind, "lastname", e.currentTarget.value);
							}} />
						</div>
					</div>
					<div className={classes.dobInputElement + " light darkText"}>
						<div className={classes.passengerInputLabel}>
							Datum rođenja
						</div>
						<div className={classes.passengerInputDob}>
							<DateInput
								addClass="darkText"
								inputChangeHandler={(e) => {
									// console.log(ind, e.target.name, e.target.value);
									dobInputChangeHandler(ind, e.target.name, e.target.value)
								}}
								dayValue={values[ind] ? values[ind]["day"] ? values[ind]["day"] : "" : ""}
								monthValue={values[ind] ? values[ind]["month"] ? values[ind]["month"] : "" : ""}
								yearValue={values[ind] ? values[ind]["year"] ? values[ind]["year"] : "" : ""}
							/>
						</div>
					</div>
					<div className={classes.ancillaryHolder + " light darkText"}>
						<div className={classes.ancillaryTitleControll} onClick={() => toggleAncillaryControl(ind)}>
							<div className={classes.ancillaryTitle}>
								Dodatne usluge
							</div>
							<div className={[classes.ancillaryDropDownArrow, ancillaryOpened.indexOf(ind) > -1 ? classes.ancillaryArrowRotated : null, "ancillaryDropDownArrow"].join(" ")}>
								<ArrowRight />
							</div>
						</div>

						<div className={[classes.ancillaryInputHolder, ancillaryOpened.indexOf(ind) > -1 ? classes.ancillaryInputHolderVisible : null].join(" ")}>
							{/*
							ancillary_per_flight.map((el, ind2) => {
								return (
									<div key={ind2}>
										<div className={classes.ancillaryFlightInfo}>
											Let: {el.airlineCode} - {el.flightNumber} | {el.departureAirportCode} - {el.arrivalAirportCode}
										</div>
										<div>
											{el.ancillarySpecifics.map((el2, ind3) => {
												let isChecked = false;
												if (values.length > 0) {
													if (values[ind].ancillary.indexOf(el2.id) > -1) {
														isChecked = true;
													}
												}
												return (
													<div key={ind3} className={classes.ancillaryCheckboxHolder}
														onClick={() => { setAncillaryValues(ind, el2.id); setTempAncillary(el2, el, ind) }}
													>
														<div className={[classes.ancillaryCheckbox, isChecked ? classes.ancillaryCheckboxChecked : null].join(" ")}></div>
														<span>
															<em>{el2.description}</em><br /><strong>{el2.amount.toLocaleString("sr-RS", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {el2.currency}</strong>
														</span>
													</div>
												)
											})}
										</div>
									</div>
								)
							})
							*/
							}
							{
								<>
								{console.log('basic', ancillarySelectBasic)}
								{console.log('out', ancillarySelectBagOutgoing)}
								{console.log('return', ancillarySelectBagReturn)}
								{console.log('advanced', ancillarySelectAdvanced)}
								<span>Brzo ukrcavanje:</span>
								<Select
									defaultValue={
										ancillarySelectBasic[ind] ?
										ancillarySelectBasic[ind].label :
										null
									}
								  	placeholder={"Izaberite uslugu"}
									classNamePrefix={"ancillarySelect"}
									options={
										ancillary_per_type.SpeedyBoarding.map((el)=> {
											var labelValue = el.Description;
											if (el.Amount) {
												labelValue = el.Description + ": " + el.Amount.toLocaleString("sr-RS", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + el.CurrencyCode
											}
											return {
												value:JSON.stringify(el), 
												label: labelValue
											}

										})
									}
									onChange={(value) => {
										setAncillarySelectBasic(oldArray => {
											const newArray = [...oldArray];
											newArray[ind] = value;
											return newArray;
										});
									}}
									maxMenuHeight={150}
									styles={{
										menu: (baseStyles, state) => ({
										  ...baseStyles,
										  position: 'revert',
										  marginTop: '0'
										}),
									}}									
								/>
								<span>Usluge prtljaga u odlaznom letu:</span>
								<Select
									defaultValue={
										ancillarySelectBagOutgoing ?
										ancillarySelectBagOutgoing.label :
										null
									}
								  	placeholder={"Izaberite uslugu"}
									  classNamePrefix={"ancillarySelect"}
									options={
										ancillary_per_type.Baggage[0].map((el)=> {
											var labelValue = el.Description;
											if (el.Amount) {
												labelValue = el.Description + ": " + el.Amount.toLocaleString("sr-RS", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + el.CurrencyCode
											}
											return {
												value:JSON.stringify(el), 
												label: labelValue
											}

										})
									}
									onChange={(value) => {setAncillarySelectBagOutgoing(
										oldArray => {
											const newArray = [...oldArray];
											newArray[ind] = value;
											return newArray;
										}
									)}}
									maxMenuHeight={150}
									styles={{
										menu: (baseStyles, state) => ({
										  ...baseStyles,
										  position: 'revert',
										  marginTop: '0'
										}),
									}}											
								/>
								{ancillary_per_type.Baggage.length>1
								? (
									<>
										<span>Usluge prtljaga u povratnom letu:</span>
										<Select
											defaultValue={
												ancillarySelectBagReturn ?
												ancillarySelectBagReturn.label :
												null
											}
											placeholder={"Izaberite uslugu"}
											classNamePrefix={"ancillarySelect"}
											options={
												ancillary_per_type.Baggage[0].map((el)=> {
													var labelValue = el.Description;
													if (el.Amount) {
														labelValue = el.Description + ": " + el.Amount.toLocaleString("sr-RS", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + el.CurrencyCode
													}
													return {
														value:JSON.stringify(el), 
														label: labelValue
													}

												})
											}
											onChange={(value) => {setAncillarySelectBagReturn(
												oldArray => {
													const newArray = [...oldArray];
													newArray[ind] = value;
													return newArray;
												}
											)}}
											maxMenuHeight={150}
											styles={{
												menu: (baseStyles, state) => ({
												  ...baseStyles,
												  position: 'revert',
												  marginTop: '0'
												}),
											}}													
										/>
									</>
								)
								:null}


								{ancillary_per_type.AdvancedAncillary
								? (
									<>
										<span>Ostale usluge:</span>
										<Select
											defaultValue={
												ancillarySelectAdvanced ?
												ancillarySelectAdvanced.label :
												null
											}
											placeholder={"Izaberite uslugu"}
											classNamePrefix={"ancillarySelect"}
											options={
												ancillary_per_type.AdvancedAncillary.map((el)=> {
													var labelValue = el.Description;
													if (el.Amount) {
														labelValue = el.Description + ": " + el.Amount.toLocaleString("sr-RS", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + el.CurrencyCode
													}
													return {
														value:JSON.stringify(el), 
														label: labelValue
													}
													
												})
											}
											onChange={(value) => {setAncillarySelectAdvanced(
												oldArray => {
													const newArray = [...oldArray];
													newArray[ind] = value;
													return newArray;
												}
											)}}
											maxMenuHeight={150}
											styles={{
												menu: (baseStyles, state) => ({
												  ...baseStyles,
												  position: 'revert',
												  marginTop: '0'
												}),
											}}													
										/>
									</>
								)
								:null}
								</>
							}
						</div>
					</div>
				</div>
			)
		})
	}

	let ancilleryOutputPerPaxFalse;
	if (typeof ancillary_per_flight["perPaxFalse"] !== 'undefined') {
		ancilleryOutputPerPaxFalse = ancillary_per_flight["perPaxFalse"].map((el, ind) => {
			let isChecked = false;
			if (values.length > 0) {
				if (values[0].ancillary.indexOf(el.ancillarySpecifics[0].id) > -1) {
					isChecked = true;
				}
			}
			return (
				<div key={ind}>
					<div className={classes.ancillaryFlightInfo}>
						Let: {el.airlineCode} - {el.flightNumber} | {el.departureAirportCode} - {el.arrivalAirportCode}
					</div>
					<div className={classes.ancillaryCheckboxHolder}
						onClick={() => { setAncillaryValues(ind, el.ancillarySpecifics[0].id, true); setTempAncillary(el.ancillarySpecifics[0], el, 0) }}
					>
						<div className={[classes.ancillaryCheckbox, isChecked ? classes.ancillaryCheckboxChecked : null].join(" ")}></div>
						<span>
							<em>{el.ancillarySpecifics[0].description}</em><br /><strong>{el.ancillarySpecifics[0].amount.toLocaleString("sr-RS", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {el.ancillarySpecifics[0].currency}</strong>
						</span>
					</div>
				</div>
			)
		})
	}

	let numberOfAdults = 0;
	let numberOfChildren = 0;
	let numberOfBabies = 0;

	if (cartDetails) {
		numberOfAdults = cartDetails.ShoppingCart.Travellers.filter(el => el.TypeCode === "ADT").length;
		numberOfChildren = cartDetails.ShoppingCart.Travellers.filter(el => el.TypeCode === "CHD").length;
		numberOfBabies = cartDetails.ShoppingCart.Travellers.filter(el => el.TypeCode === "INF").length;
	}



	const cartProvider = cartDetails ? cartDetails.ShoppingCart.Air.Provider : null;

	useEffect(() => {
		if (cartProvider === "TravelFusion") {
			setPaymentType(2);
		}
	}, [cartProvider]);

	//payment type
	const [paymentType, setPaymentType] = useState(1);
	const [legalEntity, setLegalEntityType] = useState(1);

	const [checkoutForm, setCheckoutForm] = useState({
		companyName: "",
		pib: "",
		gender: "",
		name: "",
		lastname: "",
		address1: "",
		address2: "",
		zip: "",
		city: "",
		country: "",
		mobile: "",
		mobileCode: "",
		phone: "",
		phoneCode: "",
		email: "",
		note: "",
		ccname: "",
		ccnr: "",
		ccmonth: "",
		ccyear: "",
		ccv: ""
	});

	const [checkoutFormValid, setcheckoutFormValid] = useState(false);

	const copyFormOwnerData = () => {
		setCheckoutForm((prevState) => ({ ...prevState, gender: values[0].gender, name: values[0].name, lastname: values[0].lastname }))
	}

	const handleCheckoutState = (e, name, value) => {
		if (e) {
			//console.log(e.target.name, e.target.value);
			setCheckoutForm(prevState => ({
				...prevState,
				[e.target.name]: e.target.value
			}));
		} else {
			setCheckoutForm(prevState => ({
				...prevState,
				[name]: value
			}));
		}
	};

	useEffect(() => {
		if (paymentType === 1) {
			if (legalEntity === 1) {
				//console.log(Object.values(checkoutForm));
				setcheckoutFormValid(Object.values(checkoutForm).every((val, ind) => {
					//console.log(ind, val, val !== "", ind===3);
					if (ind === 3 || ind === 4 || ind === 5 || ind === 7 || ind === 8 || ind === 9 || ind === 10 || ind === 11 || ind === 14) {
						if (val !== "") {
							return true;
						} else {
							return false;
						}
					} else {
						return true;
					}
				}))
			} else {
				//setcheckoutFormValid(Object.values(checkoutForm).every(value => {console.log(value); return !!value}));
				setcheckoutFormValid(Object.values(checkoutForm).every((val, ind) => {
					//console.log(ind,val);
					if (ind === 0 || ind === 1 || ind === 3 || ind === 4 || ind === 5 || ind === 7 || ind === 8 || ind === 9 || ind === 10 || ind === 11 || ind === 14) {
						if (val !== "") {
							return true;
						} else {
							return false;
						}
					} else {
						return true;
					}
				}))
			}
		} else if (paymentType === 2) {
			setcheckoutFormValid(true);
			/*
			// added validation for cc fields if it is on
			if (legalEntity === 1) {
				//console.log(Object.values(checkoutForm));
				setcheckoutFormValid(Object.values(checkoutForm).every((val, ind) => {
					console.log(ind, val);
					if (ind === 3 || ind === 4 || ind === 5 || ind === 7 || ind === 8 || ind === 9 || ind === 10 || ind === 11 || ind === 14
						|| ind === 16 
						|| ind === 17
						|| ind === 18
						|| ind === 19
						|| ind === 20
						) {
						if (val!=="") {
							return true;
						} else {
							return false;
						}
					} else {
						return true;
					}
				}))
			} else {
				//setcheckoutFormValid(Object.values(checkoutForm).every(value => {console.log(value); return !!value}));
				setcheckoutFormValid(Object.values(checkoutForm).every((val, ind) => {
					console.log(ind,val);
					if (ind === 0 || ind === 1 || ind === 3 || ind === 4 || ind === 5 || ind === 7 || ind === 8 || ind === 9 || ind === 10 || ind === 11 || ind === 14
						|| ind === 16 
						|| ind === 17
						|| ind === 18
						|| ind === 19
						|| ind === 20
						) {
						if (val!=="") {
							return true;
						} else {
							return false;
						}
					} else {
						return true;
					}
				}))
			}
			*/

		}

	}, [checkoutFormValid, legalEntity, checkoutForm, paymentType])

	// fizicko lice
	let finalCheckoutForm = (
		<>
			<div className={classes.passengerInputElement + " light darkText"}>
				<div className={classes.billingInputLabel}>
					Pol
				</div>
				<div className={classes.passengerInput}>
					<div className={[classes.genderButton, (checkoutForm["gender"] === "woman" ? classes.genderButtonActive + " genderActive" : null)].join(" ")}
						onClick={() => { handleCheckoutState(null, "gender", "woman") }}>Ženski</div>
					<div className={[classes.genderButton, (checkoutForm["gender"] === "man" ? classes.genderButtonActive + " genderActive" : null)].join(" ")}
						onClick={() => { handleCheckoutState(null, "gender", "man") }}>Muški</div>
				</div>
			</div>
			<div className={classes.passengerInputElement + " light darkText"}>
				<div className={classes.billingInputLabel}>
					Ime
				</div>
				<div className={classes.passengerInput}>
					<input className={"passengerInput"} type="text" name="name" value={checkoutForm["name"]} onChange={handleCheckoutState} />
				</div>
			</div>
			<div className={classes.passengerInputElement + " light darkText"}>
				<div className={classes.billingInputLabel}>
					Prezime
				</div>
				<div className={classes.passengerInput}>
					<input className={"passengerInput"} type="text" name="lastname" value={checkoutForm["lastname"]} onChange={handleCheckoutState} />
				</div>
			</div>
			<div className={classes.passengerInputElement + " light darkText"}>
				<div className={classes.billingInputLabel} style={{ paddingTop: "0.5rem" }}>
					Adresa<br />Kupca
				</div>
				<div className={classes.passengerInput}>
					<input className={"passengerInput"} type="text" name="address1" value={checkoutForm["address1"]} onChange={handleCheckoutState} />
					<br />
					<input className={"passengerInput"} type="text" name="address2" value={checkoutForm["address2"]} onChange={handleCheckoutState} />
				</div>
			</div>
			<div className={classes.passengerInputElement + " light darkText"}>
				<div className={classes.billingInputLabel}>
					Poštanski<br />kod
				</div>
				<div className={classes.passengerInput}>
					<input className={"passengerInput"} type="text" name="zip" value={checkoutForm["zip"]} onChange={handleCheckoutState} />
				</div>
			</div>
			<div className={classes.passengerInputElement + " light darkText"}>
				<div className={classes.billingInputLabel}>
					Grad
				</div>
				<div className={classes.passengerInput}>
					<input className={"passengerInput"} type="text" name="city" value={checkoutForm["city"]} onChange={handleCheckoutState} />
				</div>
			</div>
			<div className={classes.passengerInputElement + " light darkText"}>
				<div className={classes.billingInputLabel}>
					Zemlja
				</div>
				<div className={classes.passengerInput}>
					<input className={"passengerInput"} type="text" name="country" value={checkoutForm["country"]} onChange={handleCheckoutState} />
				</div>
			</div>
			<div className={classes.passengerInputElement + " light darkText"}>
				<div className={classes.billingInputLabel}>
					Mobilni<br />telefon
				</div>
				<div className={classes.phoneField}>
					<div className={classes.passengerInput}><span>+</span>
						<input className={"passengerInput"} type="text" name="mobileCode" value={checkoutForm["mobileCode"]} onChange={handleCheckoutState} />
					</div>
					<div className={classes.passengerInput}>/
						<input className={"passengerInput"} type="text" name="mobile" value={checkoutForm["mobile"]} onChange={handleCheckoutState} />
					</div>
				</div>
			</div>
			<div className={classes.passengerInputElement + " light darkText"}>
				<div className={classes.billingInputLabel}>
					Telefon
				</div>
				<div className={classes.phoneField}>
					<div className={classes.passengerInput}>
						<div style={{ display: "flex", borderBottom: "1px solid #098181" }}>
							<Select
								options={phoneCodeOptions}
								placeholder="+000"
								components={{
									DropdownIndicator: () => null,
									IndicatorSeparator: () => null,
								}}
								classNamePrefix="countryCode"
								styles={{
									control: (baseStyles, state) => ({
										//...baseStyles,
										width: "70px",
									}),
									menu: (baseStyles) => ({
										...baseStyles,
										width: "200px",
									}),
									menuPortal: (baseStyles) => ({
										...baseStyles,
										width: "200px",
									}),
									menuList: (baseStyles) => ({
										...baseStyles,
										width: "200px",
									}),
								}}
								onChange={(value) => handleCheckoutState(null, "phoneCode", value)}
							/>
						</div>
						<input className={"passengerInput"} type="hidden" name="phoneCode" value={checkoutForm["phoneCode"]} onChange={handleCheckoutState} />
					</div>
					<div className={classes.passengerInput}>/
						<input className={"passengerInput"} type="text" name="phone" value={checkoutForm["phone"]} onChange={handleCheckoutState} />
					</div>
				</div>
			</div>
			<div className={classes.passengerInputElement + " light darkText"}>
				<div className={classes.billingInputLabel}>
					E-mail
				</div>
				<div className={classes.passengerInput}>
					<input className={"passengerInput"} type="text" name="email" value={checkoutForm["email"]} onChange={handleCheckoutState} />
				</div>
			</div>
			<div className={classes.passengerInputElement + " light darkText"}>
				<div className={classes.billingInputLabel}>
					Napomena
				</div>
				<div className={classes.passengerInput}>
					<input className={"passengerInput"} type="text" name="note" value={checkoutForm["note"]} onChange={handleCheckoutState} />
				</div>
			</div>
			<br />
		</>
	)
	// ako je pravno lice
	if (legalEntity === 2) {
		finalCheckoutForm =
			(
				<>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.billingInputLabel}>
							Naziv
						</div>
						<div className={classes.passengerInput}>
							<input className={"passengerInput"} type="text" name="companyName" value={checkoutForm["companyName"]} placeholder="Tačan naziv firme" onChange={handleCheckoutState} />
						</div>
					</div>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.billingInputLabel}>
							PIB
						</div>
						<div className={classes.passengerInput}>
							<input className={"passengerInput"} type="text" name="pib" value={checkoutForm["pib"]} onChange={handleCheckoutState} placeholder="PIB" />
						</div>
					</div>


					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.billingInputLabel}>
							Pol
						</div>
						<div className={classes.passengerInput}>
							<div className={[classes.genderButton, (checkoutForm["gender"] === "woman" ? classes.genderButtonActive + " genderActive" : null)].join(" ")}
								onClick={() => { handleCheckoutState(null, "gender", "woman") }}>Ženski</div>
							<div className={[classes.genderButton, (checkoutForm["gender"] === "man" ? classes.genderButtonActive + " genderActive" : null)].join(" ")}
								onClick={() => { handleCheckoutState(null, "gender", "man") }}>Muški</div>
						</div>
					</div>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.billingInputLabel}>
							Ime
						</div>
						<div className={classes.passengerInput}>
							<input className={"passengerInput"} type="text" name="name" value={checkoutForm["name"]} onChange={handleCheckoutState} />
						</div>
					</div>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.billingInputLabel}>
							Prezime
						</div>
						<div className={classes.passengerInput}>
							<input className={"passengerInput"} type="text" name="lastname" value={checkoutForm["lastname"]} onChange={handleCheckoutState} />
						</div>
					</div>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.billingInputLabel} style={{ paddingTop: "0.5rem" }}>
							Adresa<br />Kupca
						</div>
						<div className={classes.passengerInput}>
							<input className={"passengerInput"} type="text" name="address1" value={checkoutForm["address1"]} onChange={handleCheckoutState} />
							<br />
							<input className={"passengerInput"} type="text" name="address2" value={checkoutForm["address2"]} onChange={handleCheckoutState} />
						</div>
					</div>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.billingInputLabel}>
							Poštanski<br />kod
						</div>
						<div className={classes.passengerInput}>
							<input className={"passengerInput"} type="text" name="zip" value={checkoutForm["zip"]} onChange={handleCheckoutState} />
						</div>
					</div>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.billingInputLabel}>
							Grad
						</div>
						<div className={classes.passengerInput}>
							<input className={"passengerInput"} type="text" name="city" value={checkoutForm["city"]} onChange={handleCheckoutState} />
						</div>
					</div>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.billingInputLabel}>
							Zemlja
						</div>
						<div className={classes.passengerInput}>
							<input className={"passengerInput"} type="text" name="country" value={checkoutForm["country"]} onChange={handleCheckoutState} />
						</div>
					</div>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.billingInputLabel}>
							Mobilni<br />telefon
						</div>
						<div className={classes.phoneField}>
							<div className={classes.passengerInput}><span>+</span>
								<input className={"passengerInput"} type="text" name="mobileCode" value={checkoutForm["mobileCode"]} onChange={handleCheckoutState} />
							</div>
							<div className={classes.passengerInput}>/
								<input className={"passengerInput"} type="text" name="mobile" value={checkoutForm["mobile"]} onChange={handleCheckoutState} />
							</div>
						</div>
					</div>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.billingInputLabel}>
							Telefon
						</div>
						<div className={classes.phoneField}>
							<div className={classes.passengerInput}><span>+</span>
								<input className={"passengerInput"} type="text" name="phoneCode" value={checkoutForm["phoneCode"]} onChange={handleCheckoutState} />
							</div>
							<div className={classes.passengerInput}>/
								<input className={"passengerInput"} type="text" name="phone" value={checkoutForm["phone"]} onChange={handleCheckoutState} />
							</div>
						</div>
					</div>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.billingInputLabel}>
							E-mail
						</div>
						<div className={classes.passengerInput}>
							<input className={"passengerInput"} type="text" name="email" value={checkoutForm["email"]} onChange={handleCheckoutState} />
						</div>
					</div>
					<div className={classes.passengerInputElement + " light darkText"}>
						<div className={classes.billingInputLabel}>
							Napomena
						</div>
						<div className={classes.passengerInput}>
							<input className={"passengerInput"} type="text" name="note" value={checkoutForm["note"]} onChange={handleCheckoutState} />
						</div>
					</div>
					<br />
				</>
			)
	}

	// console.log(values[0].name);

	const bookRequestHandler = useCallback(async () => {
		dispatch(toggleLoadingStatus());
		const bookRequestURL = "https://rest.resvoyage.com/api/v1/cart/book";
		//const bookRequestURL = '/book-read.json';

		const { address1, address2, city, country, email, mobile, mobileCode, zip } = checkoutForm;

		let cardInfo;

		let bookDataObject = {
			"SessionId": sessionID,
			"NotesForAgent": "",
			"PurchaseInsurance": false,
			"CubaTravelReasonCode": null,
			"Travellers": [],
			"PaymentDetails": [{
				"TravelType": "AIR",
				"CorporateCreditCard": false,
				"PaymentOption": paymentType===1 ? "CallMe" : "CreditCard",
				"Address": {
					"CountryCode": "RS",
					"CountryName": country,
					"State": country,
					"RegionName": country,
					"ZIP": zip,
					"CityName": city,
					"StreetAddress": address1 + (address2.length ? " " + address2 : "")
				},
				"CardInfo": cardInfo
			}]
		};

		// cartDetails.ShoppingCart.Travellers
		//console.log(values);

		bookDataObject.Travellers = values.map((el, ind) => {
			// prepare ancillary ids
			let ancillaryIds = [
				JSON.parse(ancillarySelectBasic[el].value).Id,
				JSON.parse(ancillarySelectBagOutgoing[el].value).Id,
				JSON.parse(ancillarySelectAdvanced[el].value).Id,
			]

			if (ancillarySelectBagReturn.length > 0) {
				ancillaryIds.push(JSON.parse(ancillarySelectBagReturn[el].value).Id);
			}

			//console.log("el", el);
			return {
				"IndexInSequence": ind,
				"Title": el.gender === "woman" ? "Mrs" : "Mr",
				"TypeCode": el.passType,
				"FirstName": el.name,
				"MiddleName": "",
				"LastName": el.lastname,
				"Email": ind === 0 ? email : "",
				"CountryCode": null,
				"Phone": ind === 0 ? mobile : "", // ili phone
				"PhoneCode": ind === 0 ? mobileCode : "",
				"SkypeID": null,
				"MealsNSeatReq": null,
				"Gender": el.gender === "woman" ? false : true,
				"DateOfBirthString": el.year + "-" + el.month + "-" + el.day, //"1979-04-30",
				"PaymentInfoObject": null,
				"BaggagePiecesQty": 0,
				"LoyalityProgram": null,
				"PassportExpDate": null,
				"PassportIssuingDate": null,
				"PassportNo": null,
				"PassportIssuingCountry": null,
				"CountryOfNationality": null,
				"AncillaryServiceIds": ancillaryIds
			}
		})

		//const rawToken = await fetch("https://"+process.env.REACT_APP_API_URL+"/api/flight_token?client_id=e4Lr08M1CDI");
		//const rawToken = await fetch("https://office.airfantast.com/api/flight_token?client_id=E10GH26U41A");
		const rawToken = await fetch("https://office.airfantast.com/api/flight_token?client_id=" + process.env.REACT_APP_TOKEN_PORTAL_CODE);
		const response = await rawToken.json();
		const thomalexToken = response.value;

		//console.log(bookDataObject);

		try {
			const bookReq = await fetch(bookRequestURL,
				{
					method: "POST",
					headers: {
						Authorization: "Bearer " + thomalexToken,
						"Content-Type": "application/json",
					},
					withCredentials: true,
					body: JSON.stringify(bookDataObject),
				});

			//const bookReq = await fetch(bookRequestURL);

			// log storage
			fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=7&type=request', {
				method: "POST",
				body: JSON.stringify(
					bookDataObject
				)
			})

			if (!bookReq.ok) {

				const bookErrParsed = await bookReq.json();
				console.log(bookErrParsed);


				// log storage
				fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=7&type=response', {
					method: "POST",
					body: JSON.stringify(
						bookErrParsed
					)
				})

				// error log request
				let storeErrorLogURL = "https://"+process.env.REACT_APP_API_URL+"/api/storeErrorLog";
				const storeErrorLogReq = await fetch(storeErrorLogURL, {
					method: "POST",
					headers: {
						Authorization: "Bearer " + token,
						"Content-Type": "application/json",
					},
					withCredentials: true,
					body: JSON.stringify({
						SearchQueryId: storeSearch.id,
						StepId: 7,
						PortalId: 1,
						IbeCode: 'e4Lr08M1CDI',
						Session: thomalexObject.SessionId,

						ErrorId: bookErrParsed.ErrorId === null ? "" : bookErrParsed.ErrorId,
						ErrorCode: bookErrParsed.ErrorCode,
						ErrorMessage: bookErrParsed.ErrorMessage,
						Request: JSON.stringify(bookDataObject),
						Response: JSON.stringify(bookErrParsed)
					})
				})
				const storeErrLog = await storeErrorLogReq.json();
				console.log("storeErrorLog", "7", storeErrLog.data);
				alert('Err msg: ' + storeErrLog.data.ErrorMessage + " - Err id: " + storeErrLog.data.id)

				return
				//throw new Error(bookReq);
			}

			const bookResp = await bookReq.json()
			//console.log(bookResp);

			// log storage
			fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=7&type=response', {
				method: "POST",
				body: JSON.stringify(
					bookResp
				)
			})

			if (bookResp.ErrorMessage) {
				alert(bookResp.ErrorMessage);
			}


			if (bookResp.IsSuccessful === true) {
				if (bookResp.ReferenceNumber) {
					//alert(bookResp.ReferenceNumber)
					loggerReq(7);


					const pnrURL = 'https://rest.resvoyage.com/api/v1/pnr/' + bookResp.ReferenceNumber + '/read';
					const pnrReq = await fetch(pnrURL,
						{
							method: "POST",
							headers: {
								Authorization: "Bearer " + thomalexToken,
								"Content-Type": "application/json",
							},
							withCredentials: true,
						}
					);

					//const pnrURL = '/pnr-read.json';
					//const pnrReq = await fetch(pnrURL);

					const pnrResponse = await pnrReq.json();
					//console.log(pnrResponse);
					loggerReq(8);

					// log storage
					fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=8&type=response', {
						method: "POST",
						body: JSON.stringify(
							pnrResponse
						)
					})

					dispatch(setPnrResponse(pnrResponse));

					//dispatch(setInfoWindow("Booking Number: " + pnrResponse.BookingReferenceNumber + " UniqueIdentifier: " + pnrResponse.UniqueTripIdentifier));


					const providersArray = ["Amadeus", "LufthansaGroup", "TravelFusion", "Swiss", "AustrianAirlines"];


					// let segmentsSource = pnrResponse.Air.AirItinerary.OriginDestinationOptions;

					//let segmentsSource = cartDetails.ShoppingCart.Air.AirItinerary.OriginDestinationOptions;

					//console.log(segmentSource)
					const segmentSource = cartDetails ? cartDetails.ShoppingCart.Air.AirItinerary.OriginDestinationOptions : null;
					const segmentsPrep = segmentSource.map(
						(el1, ind1) => {
							console.log("segment1 ind", el1, el1.SectorSequence, ind1 + 1)
							let returnEl = [...el1.FlightSegments];
							returnEl.forEach((el, index, arr) => { arr[index] = { ...el, type_id: ind1 + 1 } });
							console.log(returnEl);
							return returnEl;
						}
					).flat()
						.map((el, ind) => {
							console.log("segment2 - type_id", el);
							return {
								segment_number: ind + 1,
								type_id: el.type_id,
								from_iata: el.DepartureAirport,
								from_name: el.DepartureAirportName,

								to_iata: el.ArrivalAirport, //"IST", // ArrivalAirport last element in OriginDestinationOptions [0] FlightSegments
								to_name: el.ArrivalAirportName, //"Istanbul Int. Aeroport", // Get airport Name

								dep_time: el.DepartureDate, //"2022-08-12T09:15:00", // DepartureDate
								arr_time: el.ArrivalDate, //"2022-08-12T15:00:00", // ArrivalDate
								booking_class: el.BookingClass, //"Y", // BookingClass
								cabin_class: el.Cabin,  //"Economy", // Cabin
								flight_number: el.FlightNumber, //"2154", // FlightNumber
								route_number: el.RouteNumber, //"TK2154", // RouteNumber
								flight_duration: el.Duration, //"01:00:00", // Duration
								aircraft: el.Aircraft, //"AIRBUS INDUSTRIE A321 JET", // Aircraft
								seats_left: el.SeatsLeft, //null, //SeatsLeft
								op_airline_id: el.OperatingAirlineCode ? el.OperatingAirlineCode : el.MarketingAirlineCode, //"TK", // OperatingAirlineCode
								ma_airline_id: el.MarketingAirlineCode, //"TK", //MarketingAirlineCode
							}
						});



					let PTC_Breakdown = pnrResponse.Air.AirItineraryPricingInfo.PTC_FareBreakdowns;

					if (PTC_Breakdown.length === 0) {
						PTC_Breakdown = cDetails;
					}

					// console.log("segments", segmentsPrep);

					const netTotalPrice = PTC_Breakdown.reduce((accumulator, object) => {
						return accumulator + object.TotalPrice;
					}, 0);
					//   console.log('netTotalPrice', netTotalPrice);

					const reservationTotalPrice = pnrResponse.Air.AirItineraryPricingInfo.TotalPrice;
					//   console.log('reservationTotalPrice', reservationTotalPrice);

					const totalNumberOfPax = PTC_Breakdown.reduce((accumulator, object) => {
						return accumulator + object.PassengerCount;
					}, 0);
					// console.log('totalNumberOfPax', totalNumberOfPax);

					const passengerCurrency = pnrResponse.Air.AirItineraryPricingInfo.CurrencyCode;
					//   console.log('passengerCurrency', passengerCurrency);

					const travelersPrep = pnrResponse.Travellers.map((el, ind) => {

						const fareBreakdownForType = PTC_Breakdown.filter(el2 => el2.PassengerType === el.TypeCode)[0];
						//console.log('fareBreakdownForType', fareBreakdownForType);
						//console.log('gender', el, el.Gender);

						return {
							traveler_no: ind + 1, // set counter 1.2.3....
							type_id: passengerTypes[el.TypeCode][2], // 1 - ADT(odrasli), 2 - CHD(dete), 3 - INF(beba) // data from the form
							gender_id: el.Gender ? "1" : "2", // if form the form 1 - muski, 2 - zenski, 3 - ne zelim da se izjansnim // data from the form
							title_id: el.Gender ? "1" : "2", // 1 - gospodin, 2 - gospodja // data from the form
							fname: el.FirstName, //"Marko", // data from the form
							lname: el.LastName, //"Markovic", // data from the form
							phone: null, //"00381641733488", // data from the form (use customer phone for the first pax)
							email: null, // data from the form (use customer email for the first pax)
							dob: el.DateOfBirth.split("T")[0], //"1984-11-25", // data from the pax form
							Baggage: el.AncillaryServiceInfos.map(
								(el2, ind2) => {
									return {
										traveler_no: ind + 1, // check which traveler is in list
										segment: ind2 + 1, //el2.FlightSegments, // number of ancillary items is the same as number of segments
										ancillary_type: el2.AncillaryType, //"Baggage", //AncillaryServiceInfos[0][AncillaryType]
										description: el2.Description, //"30 Kg", //AncillaryServiceInfos[0][Description]
										amount: parseInt(el2.Amount), //AncillaryServiceInfos[0][Amount]
										currency_code: el2.CurrencyCode, //"RSD" //AncillaryServiceInfos[0][CurrencyCode]
										is_per_pax: el2.IsPerPassenger ? 1 : 0
									}
								}
							),
							Price: {
								base_price_display: (fareBreakdownForType.BasePriceFromItinerary / fareBreakdownForType.PassengerCount) + ((reservationTotalPrice - netTotalPrice) / totalNumberOfPax),//"11384", // AirItineraryPricingInfo > TotalPrice (46452)
								base_price_system: fareBreakdownForType.BasePriceFromItinerary / fareBreakdownForType.PassengerCount,
								cur: passengerCurrency,	//"RSD" // AirItineraryPricingInfo > CurrencyCode
								fee: (reservationTotalPrice - netTotalPrice) / totalNumberOfPax, //"1000.00", // AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > BasePrice (46452) - AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > BasePriceFromItinerary (45452)
								pax_type_count: ind + 1, // brojac putnika po redu
								pax_type_id: passengerTypes[el.TypeCode][2], // 1 - ADT(odrasli), 2 - CHD(dete), 3 - INF(beba) // data from the form

								//"10384", //AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > TotalPrice
								tax: fareBreakdownForType.Tax / fareBreakdownForType.PassengerCount, //"35068.00", // AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > Tax
								total: fareBreakdownForType.TotalPrice / fareBreakdownForType.PassengerCount + ((reservationTotalPrice - netTotalPrice) / totalNumberOfPax), //"46452.00", // AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > TotalPrice
							},
							AncillaryServiceIds: el.AncillaryServiceIds,
							AncillaryServiceInfos: el.AncillaryServiceInfos
						}

					})

					let supply_ref = null;
					let pnr_ndc = null

					if (pnrResponse.Air.Provider !== "Amadeus") {
						supply_ref = pnrResponse.ThirdPartyBookingReferenceNumber.split(":")[1];
						pnr_ndc = bookResp.NonGdsReferences[0].Code;
					}

					const storeReservationObject =
					{
						"Reservation": {
							"pnr": bookResp.ReferenceNumber, //"ReferenceNumber": 5. response book, this PNR is uset in step 6 for read pnr
							"pnr_airline": bookResp.AirlineBookingReferences.length ? bookResp.AirlineBookingReferences[0].ConfirmationNumber : bookResp.ReferenceNumber, //  "ConfirmationNumber", 5. response book,
							"pnr_ndc": pnr_ndc, // NDC-4-Confirmation-Response : NonGdsReferences.Code
							"supplier_ref": supply_ref,
							"trip_ref": pnrResponse.UniqueTripIdentifier, // UniqueTripIdentifier, 5. read PNR
							"pcc": pnrResponse.Air.OfficeId, // Air->OfficeId // currenti missing data in response 
							"from": pnrResponse.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments[0].DepartureAirport, // FlightSegments-[FIRST]-DepartureAirport // first element in flight segments / "SectorSequence": 0,
							//"from_country_code": 72,//storeSearch.from_country_code, // get country id for the airport
							"to": pnrResponse.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments[pnrResponse.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments.length - 1].ArrivalAirport, // FlightSegments-[LAST]-ArrivalAirport // last element in flight segments
							//"to_country_code": 185, // get country id for the airport
							"dep_date": pnrResponse.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments[0].DepartureDate, // FlightSegments-[FIRST]-DepartureDate first element in flight segments
							"ret_date": oneWay === true ? null : pnrResponse.Air.AirItinerary.OriginDestinationOptions[pnrResponse.Air.AirItinerary.OriginDestinationOptions.length - 1].FlightSegments[0].DepartureDate, // null if is one way flight, FlightSegments-[LAST]-DepartureDate first element in flight segments, If return flight for data use "SectorSequence": 1, / 0 is for outbound (if one way then only this element exist) and 1 is for inbound (return flight)
							"portal_id": "1", // use one for now, end point will be created.
							"type_id": pnrResponse.Air.AirItinerary.OriginDestinationOptions.length > 1 ? "2" : "1", // rt -2 ow -1 / if "SectorSequence": AirItinerary->DirectionInd: "OneWay" or "Return" // if ther is more then one OriginDestinationOptions element then is return flight
							"payment_id": paymentType, //"1", // 1 - pay by invoice / 2 - pay by credit card - info missing from response ("Travellers"][0]["PaymentInfoObject"][0]["PaymentOption")
							"provider_id": providersArray.findIndex((element) => element === pnrResponse.Air.Provider) + 1, // Air->Provider / 1-Amadeus, 2-LufthansaGroup, 3-TravelFusion, 4-Swiss, 5-AustrianAirlines
							"origin_id": "1", // 1- online reservation / 2 - phone reservation
							"status_id": "1", // 1 - reservation / 2- issued ticket / 3 - void ticket / use only 1
							//"total_org": pnrResponse.Air.AirItineraryPricingInfo.TotalPrice, // AirItineraryPricingInfo->TotalPrice
							//"fee_org": parseInt(pnrResponse.Air.AirItineraryPricingInfo.TotalPrice) - parseInt(PTC_Breakdown[0].TotalPrice), // calcuclate AirItineraryPricingInfo->TotalPrice  - AirItineraryPricingInfo->PTC_FareBreakdowns->[0]->TotalPrice  (10082 - 9582).
							"org_cur": pnrResponse.Air.AirItineraryPricingInfo.CurrencyCode, // // AirItineraryPricingInfo -> CurrencyCode
							"flight_serch_id": storeSearch.id, // get info form store request
							"ff_name": pnrResponse.Air.AirItineraryPricingInfo.FareFamily, // Air->[0]->AirItineraryPricingInfo->FareFamily / data is missing from response
							"user_id": /*userId ? userId : */checkoutForm.email, // if user is registed then add user id from the system
							"note": checkoutForm.note // last field (napomena) in customer data form (payment page), add new text field in form
						},

						"Segments": segmentsPrep,
						"SegmentsMoreInfo": null,
						"Customer": {
							"user_id": /*userId ? userId : */checkoutForm.email, // if user is logged in add ID
							"company_name": checkoutForm.companyName.length ? checkoutForm.companyName : "", // data from the form
							"company_vat": checkoutForm.pib.length ? checkoutForm.pib : "", // data from the form / pib
							"gender_id": checkoutForm.gender === "man" ? "1" : "2", // if form the form 1 - muski, 2 - zenski, 3 - ne zelim da se izjansnim
							"title_id": checkoutForm.gender === "man" ? "1" : "2", // 1 - gospodin, 2 - gospodja
							"fname": checkoutForm.name, // data from the form
							"lname": checkoutForm.lastname, // data from the form
							"address": checkoutForm.address1 + " " + checkoutForm.address2, // data from the form
							"city": checkoutForm.city, // data from the form
							"zip": checkoutForm.zip, // data from the form
							"country_id": "184", //get selected country id from the form
							"country": checkoutForm.country, // country text name
							"email": checkoutForm.email, // data from the form
							"phone": checkoutForm.phone, // data from the form format 00381641733488 (+ convert to 00)
							"phoneCode": checkoutForm.phoneCode, // data from the form format 00381641733488 (+ convert to 00)
							"mob": checkoutForm.mobile, // data from the form format 00381641733488 (+ convert to 00)
							"mobCode": checkoutForm.mobileCode, // data from the form format 00381641733488 (+ convert to 00)
							"dob": values[0]["year"] + "-" + values[0]["month"] + "-" + values[0]["day"]
							//"1979-30-04" // data from the form format 1979-30-04

						},
						"Travelers": travelersPrep,
						// if is invoice return null "PaymentCcInfo": null,
						"PaymentCcInfo": null,
						"ExRates": {
							"ex_rate_to_eur": null, // ovde treba uzeti info sa Exchange Rates - Show rate endpointa // primer http://"+process.env.REACT_APP_API_URL+"/api/exrates?bank_id=1&currency=EUR&date=2021-09-09
							//https://office.airfantast.com/api/exrates?bank_id=1&currency=EUR&date=2022-06-06
							"ex_rate_to_rsd": null  // ovde treba uzeti info sa Exchange Rates - Show rate endpointa // primer http://"+process.env.REACT_APP_API_URL+"/api/exrates?bank_id=1&currency=EUR&date=2021-09-09
						}
					}


					// final page pnrResponse data
					console.log(
						storeReservationObject,
						// JSON.stringify(storeReservationObject)
					);

					// log storage
					fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=9&type=response', {
						method: "POST",
						body: JSON.stringify(
							storeReservationObject
						)
					})

					//const storeURL = 'https://office.airfantast.com/api/storeReservaton';
					const storeURL = 'https://"+process.env.REACT_APP_API_URL+"/api/storeReservaton';
					const storeReq = await fetch(storeURL, {
						method: "POST",
						/*
						headers: {
							Authorization: "Bearer " + token,
							"Content-Type": "application/json",
						},
						withCredentials: true,
						*/
						body: JSON.stringify(
							storeReservationObject
						)
					}
					);

					const storeResponse = await storeReq.json();
					//console.log(storeResponse);
					if (storeResponse.message) {
						if (storeResponse.message === "Booking stored OK!") {
							dispatch(setBuyerInfo(checkoutForm));
							dispatch(setStoreReservation(storeResponse));
							dispatch(setInfoWindowCloseRefresh(true));
							dispatch(setInfoWindow("Email poslat - PNR: " + bookResp.ReferenceNumber));
							dispatch(setQuaternaryFormContent("POTVRDA_REZERVACIJE"));
							dispatch(changeFormPosition(-3));
						}
					}

				}
			}

			dispatch(toggleLoadingStatus());
		} catch (e) {
			console.error(e);
		}

	}, [checkoutForm, values, sessionID, dispatch, loggerReq, storeSearch.id, thomalexObject.SessionId, token, oneWay, passengerTypes, cDetails, cartDetails, paymentType, ancillarySelectAdvanced, ancillarySelectBagOutgoing, ancillarySelectBagReturn, ancillarySelectBasic]);


	const bookRequestHandlerTravelFusionCC = useCallback(async () => {
		dispatch(toggleLoadingStatus());
		const bookRequestURL = "https://rest.resvoyage.com/api/v1/cart/book";
		//const bookRequestURL = '/book-read.json';

		const { address1, address2, city, country, email, mobile, mobileCode, zip } = checkoutForm;

		let cardInfo;


		if (paymentType === 2 && isItTravelFusion) {
			cardInfo = {
				"CardholderName": "Zeljko Ivanisevic",
				"CardType": "VI",
				"CardTypeCode": "VI",
				"CardNumber": "4111111111111111",
				"ExpirationMonth": "12",
				"ExpirationYear": "2023",
				"CVV": "123",
				"BankName": "BankName",
				"BankPhone": "0038164123456"
			}
		}




		let bookDataObject = {
			"SessionId": sessionID,
			"NotesForAgent": "",
			"PurchaseInsurance": false,
			"CubaTravelReasonCode": null,
			"Travellers": [],
			"PaymentDetails": [{
				"TravelType": "AIR",
				"CorporateCreditCard": false,
				"PaymentOption": paymentType===1 ? "CallMe" : "CreditCard",
				"Address": {
					"CountryCode": "RS",
					"CountryName": country,
					"State": country,
					"RegionName": country,
					"ZIP": zip,
					"CityName": city,
					"StreetAddress": address1 + (address2.length ? " " + address2 : "")
				},
				"CardInfo": cardInfo
			}]
		};

		// cartDetails.ShoppingCart.Travellers
		//console.log(values);
		bookDataObject.Travellers = values.map((el, ind) => {
			// console.log("el", el);
						// prepare ancillary ids
			let ancillaryIds = [
				JSON.parse(ancillarySelectBasic[el].value).Id,
				JSON.parse(ancillarySelectBagOutgoing[el].value).Id,
				JSON.parse(ancillarySelectAdvanced[el].value).Id,
			]

			if (ancillarySelectBagReturn.length > 0) {
				ancillaryIds.push(JSON.parse(ancillarySelectBagReturn[el].value).Id);
			}
			return {
				"IndexInSequence": ind,
				"Title": el.gender === "woman" ? "Mrs" : "Mr",
				"TypeCode": el.passType,
				"FirstName": el.name,
				"MiddleName": "",
				"LastName": el.lastname,
				"Email": ind === 0 ? email : "",
				"CountryCode": null,
				"Phone": ind === 0 ? mobile : "", // ili phone
				"PhoneCode": ind === 0 ? mobileCode : "",
				"SkypeID": null,
				"MealsNSeatReq": null,
				"Gender": el.gender === "woman" ? false : true,
				"DateOfBirthString": el.year + "-" + el.month + "-" + el.day, //"1979-04-30",
				"PaymentInfoObject": null,
				"BaggagePiecesQty": 0,
				"LoyalityProgram": null,
				"PassportExpDate": null,
				"PassportIssuingDate": null,
				"PassportNo": null,
				"PassportIssuingCountry": null,
				"CountryOfNationality": null,
				"AncillaryServiceIds": ancillaryIds,
			}
		})

		console.log('ancillary check', bookDataObject)

		//const rawToken = await fetch("https://"+process.env.REACT_APP_API_URL+"/api/flight_token?client_id=e4Lr08M1CDI");
		//const rawToken = await fetch("https://office.airfantast.com/api/flight_token?client_id=E10GH26U41A");
		const rawToken = await fetch("https://office.airfantast.com/api/flight_token?client_id=" + process.env.REACT_APP_TOKEN_PORTAL_CODE);
		const response = await rawToken.json();
		const thomalexToken = response.value;

		//console.log(bookDataObject);

		try {
			const bookReq = await fetch(bookRequestURL,
				{
					method: "POST",
					headers: {
						Authorization: "Bearer " + thomalexToken,
						"Content-Type": "application/json",
					},
					withCredentials: true,
					body: JSON.stringify(bookDataObject),
				});

			//const bookReq = await fetch(bookRequestURL);

			// log storage
			fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=7&type=request', {
				method: "POST",
				body: JSON.stringify(
					bookDataObject
				)
			})

			if (!bookReq.ok) {

				const bookErrParsed = await bookReq.json();
				console.log(bookErrParsed);


				// log storage
				fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=7&type=response', {
					method: "POST",
					body: JSON.stringify(
						bookErrParsed
					)
				})

				// error log request
				let storeErrorLogURL = "https://"+process.env.REACT_APP_API_URL+"/api/storeErrorLog";
				const storeErrorLogReq = await fetch(storeErrorLogURL, {
					method: "POST",
					headers: {
						Authorization: "Bearer " + token,
						"Content-Type": "application/json",
					},
					withCredentials: true,
					body: JSON.stringify({
						SearchQueryId: storeSearch.id,
						StepId: 7,
						PortalId: 1,
						IbeCode: 'e4Lr08M1CDI',
						Session: thomalexObject.SessionId,

						ErrorId: bookErrParsed.ErrorId === null ? "" : bookErrParsed.ErrorId,
						ErrorCode: bookErrParsed.ErrorCode,
						ErrorMessage: bookErrParsed.ErrorMessage,
						Request: JSON.stringify(bookDataObject),
						Response: JSON.stringify(bookErrParsed)
					})
				})
				const storeErrLog = await storeErrorLogReq.json();
				console.log("storeErrorLog", "7", storeErrLog.data);
				alert('Err msg: ' + storeErrLog.data.ErrorMessage + " - Err id: " + storeErrLog.data.id)

				return
				//throw new Error(bookReq);
			}

			const bookResp = await bookReq.json()
			//console.log(bookResp);

			// log storage
			fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=7&type=response', {
				method: "POST",
				body: JSON.stringify(
					bookResp
				)
			})

			if (bookResp.ErrorMessage) {
				alert(bookResp.ErrorMessage);
			}

			if (bookResp.Warning) {
				if (bookResp.Warning.Message === "Pricing Confirmation Required") {
					dispatch(setChoiceWindow(
						{
							message: `Da li prihvatate cenu karte od ` + bookResp.TravelFusionBookingConfirmedPrice + ` ` + bookResp.TravelFusionBookingConfirmedCurrency + `
							?`,
							okText: "Da",
							noText: "Ne",
						}
					));
				}
			}

			//console.log(bookResp);

			/*
			if (bookResp.IsSuccessful === true) {
				if (bookResp.ReferenceNumber) {
					//alert(bookResp.ReferenceNumber)
					loggerReq(7);

					
					const pnrURL = 'https://rest.resvoyage.com/api/v1/pnr/' + bookResp.ReferenceNumber + '/read';
					const pnrReq = await fetch(pnrURL,
						{
							method: "POST",
							headers: {
								Authorization: "Bearer " + thomalexToken,
								"Content-Type": "application/json",
							},
							withCredentials: true,
						}
					);
					
					//const pnrURL = '/pnr-read.json';
					//const pnrReq = await fetch(pnrURL);

					const pnrResponse = await pnrReq.json();
					//console.log(pnrResponse);
					loggerReq(8);

					// log storage
					fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=8&type=response', {
						method: "POST",
						body: JSON.stringify(
							pnrResponse
						)
					})

					dispatch(setPnrResponse(pnrResponse));

					//dispatch(setInfoWindow("Booking Number: " + pnrResponse.BookingReferenceNumber + " UniqueIdentifier: " + pnrResponse.UniqueTripIdentifier));

					
					const providersArray = ["Amadeus","LufthansaGroup","TravelFusion","Swiss","AustrianAirlines"];


					// let segmentsSource = pnrResponse.Air.AirItinerary.OriginDestinationOptions;
					
					//let segmentsSource = cartDetails.ShoppingCart.Air.AirItinerary.OriginDestinationOptions;

					//console.log(segmentSource)
					const segmentSource = cartDetails ? cartDetails.ShoppingCart.Air.AirItinerary.OriginDestinationOptions : null;
					const segmentsPrep = segmentSource.map(
						(el1,ind1) => {
							console.log("segment1 ind", el1, el1.SectorSequence, ind1+1)
							let returnEl = [...el1.FlightSegments];
							returnEl.forEach((el, index, arr) => {arr[index] = {...el, type_id:ind1+1}});
							console.log(returnEl);
							return returnEl;
						}
					).flat()
					.map((el,ind) => {
						console.log("segment2 - type_id", el);
						return {
							segment_number:ind+1,
							type_id: el.type_id,
							from_iata: el.DepartureAirport,
							from_name: el.DepartureAirportName,
							
							to_iata: el.ArrivalAirport, //"IST", // ArrivalAirport last element in OriginDestinationOptions [0] FlightSegments
							to_name: el.ArrivalAirportName, //"Istanbul Int. Aeroport", // Get airport Name
							
							dep_time: el.DepartureDate, //"2022-08-12T09:15:00", // DepartureDate
							arr_time: el.ArrivalDate, //"2022-08-12T15:00:00", // ArrivalDate
							booking_class: el.BookingClass, //"Y", // BookingClass
							cabin_class: el.Cabin,  //"Economy", // Cabin
							flight_number: el.FlightNumber, //"2154", // FlightNumber
							route_number: el.RouteNumber, //"TK2154", // RouteNumber
							flight_duration: el.Duration , //"01:00:00", // Duration
							aircraft: el.Aircraft, //"AIRBUS INDUSTRIE A321 JET", // Aircraft
							seats_left: el.SeatsLeft, //null, //SeatsLeft
							op_airline_id: el.OperatingAirlineCode ? el.OperatingAirlineCode : el.MarketingAirlineCode, //"TK", // OperatingAirlineCode
							ma_airline_id: el.MarketingAirlineCode, //"TK", //MarketingAirlineCode
						}
					});
 


					let PTC_Breakdown = pnrResponse.Air.AirItineraryPricingInfo.PTC_FareBreakdowns;

					if (PTC_Breakdown.length===0) {
						PTC_Breakdown = cDetails;
					}

					// console.log("segments", segmentsPrep);

					const netTotalPrice = PTC_Breakdown.reduce((accumulator, object) => {
						return accumulator + object.TotalPrice;
					  }, 0);
					//   console.log('netTotalPrice', netTotalPrice);

					  const reservationTotalPrice = pnrResponse.Air.AirItineraryPricingInfo.TotalPrice;
					//   console.log('reservationTotalPrice', reservationTotalPrice);

					const totalNumberOfPax = PTC_Breakdown.reduce((accumulator, object) => {
						return accumulator + object.PassengerCount;
					}, 0);
					// console.log('totalNumberOfPax', totalNumberOfPax);

					const passengerCurrency = pnrResponse.Air.AirItineraryPricingInfo.CurrencyCode;
					//   console.log('passengerCurrency', passengerCurrency);

					const travelersPrep = pnrResponse.Travellers.map((el,ind) => {

						const fareBreakdownForType = PTC_Breakdown.filter(el2 => el2.PassengerType === el.TypeCode)[0];
						//console.log('fareBreakdownForType', fareBreakdownForType);
						//console.log('gender', el, el.Gender);
						
						return {
							traveler_no: ind+1, // set counter 1.2.3....
							type_id: passengerTypes[el.TypeCode][2], // 1 - ADT(odrasli), 2 - CHD(dete), 3 - INF(beba) // data from the form
							gender_id: el.Gender ? "1" : "2", // if form the form 1 - muski, 2 - zenski, 3 - ne zelim da se izjansnim // data from the form
							title_id: el.Gender ? "1" : "2", // 1 - gospodin, 2 - gospodja // data from the form
							fname: el.FirstName, //"Marko", // data from the form
							lname: el.LastName, //"Markovic", // data from the form
							phone: null, //"00381641733488", // data from the form (use customer phone for the first pax)
							email: null, // data from the form (use customer email for the first pax)
							dob: el.DateOfBirth.split("T")[0], //"1984-11-25", // data from the pax form
							Baggage: el.AncillaryServiceInfos.map(
								(el2, ind2) => {
									return {
										traveler_no: ind+1, // check which traveler is in list
										segment: ind2+1, //el2.FlightSegments, // number of ancillary items is the same as number of segments
										ancillary_type: el2.AncillaryType, //"Baggage", //AncillaryServiceInfos[0][AncillaryType]
										description: el2.Description, //"30 Kg", //AncillaryServiceInfos[0][Description]
										amount: parseInt(el2.Amount), //AncillaryServiceInfos[0][Amount]
										currency_code: el2.CurrencyCode, //"RSD" //AncillaryServiceInfos[0][CurrencyCode]
										is_per_pax: el2.IsPerPassenger ? 1 : 0
									}
								}
							),
							Price: {
								base_price_display: (fareBreakdownForType.BasePriceFromItinerary / fareBreakdownForType.PassengerCount) + ((reservationTotalPrice - netTotalPrice) / totalNumberOfPax),//"11384", // AirItineraryPricingInfo > TotalPrice (46452)
								base_price_system: fareBreakdownForType.BasePriceFromItinerary / fareBreakdownForType.PassengerCount,
								cur: passengerCurrency,	//"RSD" // AirItineraryPricingInfo > CurrencyCode
								fee: (reservationTotalPrice - netTotalPrice) / totalNumberOfPax, //"1000.00", // AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > BasePrice (46452) - AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > BasePriceFromItinerary (45452)
								pax_type_count: ind+1, // brojac putnika po redu
								pax_type_id: passengerTypes[el.TypeCode][2], // 1 - ADT(odrasli), 2 - CHD(dete), 3 - INF(beba) // data from the form
								
								//"10384", //AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > TotalPrice
								tax: fareBreakdownForType.Tax / fareBreakdownForType.PassengerCount, //"35068.00", // AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > Tax
								total: fareBreakdownForType.TotalPrice / fareBreakdownForType.PassengerCount + ((reservationTotalPrice - netTotalPrice) / totalNumberOfPax), //"46452.00", // AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > TotalPrice
							},
							AncillaryServiceIds: el.AncillaryServiceIds,
							AncillaryServiceInfos: el.AncillaryServiceInfos
						}

					})

					let supply_ref = null;
					let pnr_ndc = null

					if (pnrResponse.Air.Provider!=="Amadeus") {
						supply_ref = pnrResponse.ThirdPartyBookingReferenceNumber.split(":")[1];
						pnr_ndc = bookResp.NonGdsReferences[0].Code;
					}

					const storeReservationObject=
					{
						"Reservation": {
							"pnr": bookResp.ReferenceNumber, //"ReferenceNumber": 5. response book, this PNR is uset in step 6 for read pnr
							"pnr_airline": bookResp.AirlineBookingReferences.length ? bookResp.AirlineBookingReferences[0].ConfirmationNumber : bookResp.ReferenceNumber, //  "ConfirmationNumber", 5. response book,
							"pnr_ndc": pnr_ndc, // NDC-4-Confirmation-Response : NonGdsReferences.Code
							"supplier_ref": supply_ref,
							"trip_ref": pnrResponse.UniqueTripIdentifier, // UniqueTripIdentifier, 5. read PNR
							"pcc": pnrResponse.Air.OfficeId, // Air->OfficeId // currenti missing data in response 
							"from": pnrResponse.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments[0].DepartureAirport, // FlightSegments-[FIRST]-DepartureAirport // first element in flight segments / "SectorSequence": 0,
							//"from_country_code": 72,//storeSearch.from_country_code, // get country id for the airport
							"to": pnrResponse.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments[pnrResponse.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments.length-1].ArrivalAirport, // FlightSegments-[LAST]-ArrivalAirport // last element in flight segments
							//"to_country_code": 185, // get country id for the airport
							"dep_date": pnrResponse.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments[0].DepartureDate, // FlightSegments-[FIRST]-DepartureDate first element in flight segments
							"ret_date": oneWay===true ? null : pnrResponse.Air.AirItinerary.OriginDestinationOptions[pnrResponse.Air.AirItinerary.OriginDestinationOptions.length-1].FlightSegments[0].DepartureDate, // null if is one way flight, FlightSegments-[LAST]-DepartureDate first element in flight segments, If return flight for data use "SectorSequence": 1, / 0 is for outbound (if one way then only this element exist) and 1 is for inbound (return flight)
							"portal_id": "1", // use one for now, end point will be created.
							"type_id": pnrResponse.Air.AirItinerary.OriginDestinationOptions.length > 1 ? "2" : "1", // rt -2 ow -1 / if "SectorSequence": AirItinerary->DirectionInd: "OneWay" or "Return" // if ther is more then one OriginDestinationOptions element then is return flight
							"payment_id": "1", // 1 - pay by invoice / 2 - pay by credit card - info missing from response ("Travellers"][0]["PaymentInfoObject"][0]["PaymentOption")
							"provider_id": providersArray.findIndex((element) => element === pnrResponse.Air.Provider)+1, // Air->Provider / 1-Amadeus, 2-LufthansaGroup, 3-TravelFusion, 4-Swiss, 5-AustrianAirlines
							"origin_id": "1", // 1- online reservation / 2 - phone reservation
							"status_id": "1", // 1 - reservation / 2- issued ticket / 3 - void ticket / use only 1
							//"total_org": pnrResponse.Air.AirItineraryPricingInfo.TotalPrice, // AirItineraryPricingInfo->TotalPrice
							//"fee_org": parseInt(pnrResponse.Air.AirItineraryPricingInfo.TotalPrice) - parseInt(PTC_Breakdown[0].TotalPrice), // calcuclate AirItineraryPricingInfo->TotalPrice  - AirItineraryPricingInfo->PTC_FareBreakdowns->[0]->TotalPrice  (10082 - 9582).
							"org_cur": pnrResponse.Air.AirItineraryPricingInfo.CurrencyCode, // // AirItineraryPricingInfo -> CurrencyCode
							"flight_serch_id": storeSearch.id, // get info form store request
							"ff_name": pnrResponse.Air.AirItineraryPricingInfo.FareFamily, // Air->[0]->AirItineraryPricingInfo->FareFamily / data is missing from response
							"user_id": checkoutForm.email, // if user is registed then add user id from the system
							"note": checkoutForm.note // last field (napomena) in customer data form (payment page), add new text field in form
						},
						
						"Segments": segmentsPrep,
						"SegmentsMoreInfo": null,
						"Customer": {
							"user_id": checkoutForm.email, // if user is logged in add ID
							"company_name": checkoutForm.companyName.length ? checkoutForm.companyName : "", // data from the form
							"company_vat": checkoutForm.pib.length ? checkoutForm.pib : "", // data from the form / pib
							"gender_id": checkoutForm.gender === "man" ? "1" : "2", // if form the form 1 - muski, 2 - zenski, 3 - ne zelim da se izjansnim
							"title_id": checkoutForm.gender === "man" ? "1" : "2", // 1 - gospodin, 2 - gospodja
							"fname": checkoutForm.name, // data from the form
							"lname": checkoutForm.lastname, // data from the form
							"address": checkoutForm.address1 + " " + checkoutForm.address2, // data from the form
							"city": checkoutForm.city, // data from the form
							"zip": checkoutForm.zip, // data from the form
							"country_id": "184", //get selected country id from the form
							"country": checkoutForm.country, // country text name
							"email": checkoutForm.email, // data from the form
							"phone": checkoutForm.phone, // data from the form format 00381641733488 (+ convert to 00)
							"phoneCode": checkoutForm.phoneCode, // data from the form format 00381641733488 (+ convert to 00)
							"mob": checkoutForm.mobile, // data from the form format 00381641733488 (+ convert to 00)
							"mobCode": checkoutForm.mobileCode, // data from the form format 00381641733488 (+ convert to 00)
							"dob": values[0]["year"] + "-" +  values[0]["month"] + "-" + values[0]["day"]
							 //"1979-30-04" // data from the form format 1979-30-04
							
						},
						"Travelers": travelersPrep,
						// if is invoice return null "PaymentCcInfo": null,
						"PaymentCcInfo": null,
						"ExRates": {    
							"ex_rate_to_eur": null, // ovde treba uzeti info sa Exchange Rates - Show rate endpointa // primer http://"+process.env.REACT_APP_API_URL+"/api/exrates?bank_id=1&currency=EUR&date=2021-09-09
							//https://office.airfantast.com/api/exrates?bank_id=1&currency=EUR&date=2022-06-06
							"ex_rate_to_rsd": null  // ovde treba uzeti info sa Exchange Rates - Show rate endpointa // primer http://"+process.env.REACT_APP_API_URL+"/api/exrates?bank_id=1&currency=EUR&date=2021-09-09
						}
					  }


					// final page pnrResponse data
					console.log(
						storeReservationObject,
						// JSON.stringify(storeReservationObject)
					);

					// log storage
					fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=9&type=response', {
						method: "POST",
						body: JSON.stringify(
							storeReservationObject
						)
					})

					//const storeURL = 'https://office.airfantast.com/api/storeReservaton';
					const storeURL = 'https://"+process.env.REACT_APP_API_URL+"/api/storeReservaton';
					const storeReq = await fetch(storeURL,{
							method: "POST",
							
							//headers: {
							//	Authorization: "Bearer " + token,
							//	"Content-Type": "application/json",
							//},
							//withCredentials: true,
							
							body: JSON.stringify(
								storeReservationObject
							)
						}
					);

					const storeResponse = await storeReq.json();
					//console.log(storeResponse);
					if (storeResponse.message) {
						if (storeResponse.message==="Booking stored OK!") {
							dispatch(setBuyerInfo(checkoutForm));
							dispatch(setStoreReservation(storeResponse));
							dispatch(setInfoWindowCloseRefresh(true));
							dispatch(setInfoWindow("Email poslat - PNR: " + bookResp.ReferenceNumber));
							dispatch(setQuaternaryFormContent("POTVRDA_REZERVACIJE"));
							dispatch(changeFormPosition(-3));
						}
					}

				}
			}
			*/
			dispatch(toggleLoadingStatus());
		} catch (e) {
			console.error(e);
		}

		//}, [checkoutForm, values, sessionID, dispatch, loggerReq, storeSearch.id, thomalexObject.SessionId, token, oneWay, passengerTypes, cDetails, cartDetails, isItTravelFusion, paymentType]);
	}, [checkoutForm, values, sessionID, dispatch, storeSearch.id, thomalexObject.SessionId, token, isItTravelFusion, paymentType, ancillarySelectAdvanced, ancillarySelectBagOutgoing, ancillarySelectBagReturn, ancillarySelectBasic]);


	const bookRequestHandlerTravelFusionCCConfirmation = useCallback(async () => {

		dispatch(toggleLoadingStatus());
		const bookRequestURL = "https://rest.resvoyage.com/api/v1/cart/book";

		const { address1, address2, city, country, email, mobile, mobileCode, zip } = checkoutForm;

		let cardInfo;

		if (paymentType === 2 && isItTravelFusion) {
			cardInfo = {
				"CardholderName": "Zeljko Ivanisevic",
				"CardType": "VI",
				"CardTypeCode": "VI",
				"CardNumber": "4111111111111111",
				"ExpirationMonth": "12",
				"ExpirationYear": "2023",
				"CVV": "123",
				"BankName": "BankName",
				"BankPhone": "0038164123456"
			}
		}




		let bookDataObject = {
			"TravelFusionConfirmPrice": true,
			"SessionId": sessionID,
			"NotesForAgent": "",
			"PurchaseInsurance": false,
			"CubaTravelReasonCode": null,
			"Travellers": [],
			"PaymentDetails": [{
				"TravelType": "AIR",
				"CorporateCreditCard": false,
				"PaymentOption": paymentType===1 ? "CallMe" : "CreditCard",
				"Address": {
					"CountryCode": "RS",
					"CountryName": country,
					"State": country,
					"RegionName": country,
					"ZIP": zip,
					"CityName": city,
					"StreetAddress": address1 + (address2.length ? " " + address2 : "")
				},
				"CardInfo": cardInfo
			}]
		};

		// cartDetails.ShoppingCart.Travellers
		//console.log(values);
		bookDataObject.Travellers = values.map((el, ind) => {
			// console.log("el", el);
			return {
				"IndexInSequence": ind,
				"Title": el.gender === "woman" ? "Mrs" : "Mr",
				"TypeCode": el.passType,
				"FirstName": el.name,
				"MiddleName": "",
				"LastName": el.lastname,
				"Email": ind === 0 ? email : "",
				"CountryCode": null,
				"Phone": ind === 0 ? mobile : "", // ili phone
				"PhoneCode": ind === 0 ? mobileCode : "",
				"SkypeID": null,
				"MealsNSeatReq": null,
				"Gender": el.gender === "woman" ? false : true,
				"DateOfBirthString": el.year + "-" + el.month + "-" + el.day, //"1979-04-30",
				"PaymentInfoObject": null,
				"BaggagePiecesQty": 0,
				"LoyalityProgram": null,
				"PassportExpDate": null,
				"PassportIssuingDate": null,
				"PassportNo": null,
				"PassportIssuingCountry": null,
				"CountryOfNationality": null
			}
		})

		//const rawToken = await fetch("https://"+process.env.REACT_APP_API_URL+"/api/flight_token?client_id=e4Lr08M1CDI");
		//const rawToken = await fetch("https://office.airfantast.com/api/flight_token?client_id=E10GH26U41A");
		const rawToken = await fetch("https://office.airfantast.com/api/flight_token?client_id=" + process.env.REACT_APP_TOKEN_PORTAL_CODE);
		const response = await rawToken.json();
		const thomalexToken = response.value;

		//console.log(bookDataObject);

		try {
			const bookReq = await fetch(bookRequestURL,
				{
					method: "POST",
					headers: {
						Authorization: "Bearer " + thomalexToken,
						"Content-Type": "application/json",
					},
					withCredentials: true,
					body: JSON.stringify(bookDataObject),
				});

			//const bookReq = await fetch(bookRequestURL);

			// log storage
			fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=7&type=request', {
				method: "POST",
				body: JSON.stringify(
					bookDataObject
				)
			})

			if (!bookReq.ok) {

				const bookErrParsed = await bookReq.json();
				console.log(bookErrParsed);


				// log storage
				fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=7&type=response', {
					method: "POST",
					body: JSON.stringify(
						bookErrParsed
					)
				})

				// error log request
				let storeErrorLogURL = "https://"+process.env.REACT_APP_API_URL+"/api/storeErrorLog";
				const storeErrorLogReq = await fetch(storeErrorLogURL, {
					method: "POST",
					headers: {
						Authorization: "Bearer " + token,
						"Content-Type": "application/json",
					},
					withCredentials: true,
					body: JSON.stringify({
						SearchQueryId: storeSearch.id,
						StepId: 7,
						PortalId: 1,
						IbeCode: 'e4Lr08M1CDI',
						Session: thomalexObject.SessionId,

						ErrorId: bookErrParsed.ErrorId === null ? "" : bookErrParsed.ErrorId,
						ErrorCode: bookErrParsed.ErrorCode,
						ErrorMessage: bookErrParsed.ErrorMessage,
						Request: JSON.stringify(bookDataObject),
						Response: JSON.stringify(bookErrParsed)
					})
				})
				const storeErrLog = await storeErrorLogReq.json();
				console.log("storeErrorLog", "7", storeErrLog.data);
				alert('Err msg: ' + storeErrLog.data.ErrorMessage + " - Err id: " + storeErrLog.data.id)

				return
				//throw new Error(bookReq);
			}

			const bookResp = await bookReq.json()
			//console.log(bookResp);

			// log storage
			fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=7&type=response', {
				method: "POST",
				body: JSON.stringify(
					bookResp
				)
			})

			if (bookResp.ErrorMessage) {
				alert(bookResp.ErrorMessage);
			}

			//console.log(bookResp);

			if (bookResp.IsSuccessful === true) {
				if (bookResp.ReferenceNumber) {
					//alert(bookResp.ReferenceNumber)
					loggerReq(7);


					const pnrURL = 'https://rest.resvoyage.com/api/v1/pnr/' + bookResp.ReferenceNumber + '/read';
					const pnrReq = await fetch(pnrURL,
						{
							method: "POST",
							headers: {
								Authorization: "Bearer " + thomalexToken,
								"Content-Type": "application/json",
							},
							withCredentials: true,
						}
					);

					//const pnrURL = '/pnr-read.json';
					//const pnrReq = await fetch(pnrURL);

					const pnrResponse = await pnrReq.json();
					//console.log(pnrResponse);
					loggerReq(8);

					// log storage
					fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=8&type=response', {
						method: "POST",
						body: JSON.stringify(
							pnrResponse
						)
					})

					dispatch(setPnrResponse(pnrResponse));

					//dispatch(setInfoWindow("Booking Number: " + pnrResponse.BookingReferenceNumber + " UniqueIdentifier: " + pnrResponse.UniqueTripIdentifier));


					const providersArray = ["Amadeus", "LufthansaGroup", "TravelFusion", "Swiss", "AustrianAirlines"];


					// let segmentsSource = pnrResponse.Air.AirItinerary.OriginDestinationOptions;

					//let segmentsSource = cartDetails.ShoppingCart.Air.AirItinerary.OriginDestinationOptions;

					//console.log(segmentSource)
					const segmentSource = cartDetails ? cartDetails.ShoppingCart.Air.AirItinerary.OriginDestinationOptions : null;
					const segmentsPrep = segmentSource.map(
						(el1, ind1) => {
							console.log("segment1 ind", el1, el1.SectorSequence, ind1 + 1)
							let returnEl = [...el1.FlightSegments];
							returnEl.forEach((el, index, arr) => { arr[index] = { ...el, type_id: ind1 + 1 } });
							console.log(returnEl);
							return returnEl;
						}
					).flat()
						.map((el, ind) => {
							console.log("segment2 - type_id", el);
							return {
								segment_number: ind + 1,
								type_id: el.type_id,
								from_iata: el.DepartureAirport,
								from_name: el.DepartureAirportName,

								to_iata: el.ArrivalAirport, //"IST", // ArrivalAirport last element in OriginDestinationOptions [0] FlightSegments
								to_name: el.ArrivalAirportName, //"Istanbul Int. Aeroport", // Get airport Name

								dep_time: el.DepartureDate, //"2022-08-12T09:15:00", // DepartureDate
								arr_time: el.ArrivalDate, //"2022-08-12T15:00:00", // ArrivalDate
								booking_class: el.BookingClass, //"Y", // BookingClass
								cabin_class: el.Cabin,  //"Economy", // Cabin
								flight_number: el.FlightNumber, //"2154", // FlightNumber
								route_number: el.RouteNumber, //"TK2154", // RouteNumber
								flight_duration: el.Duration, //"01:00:00", // Duration
								aircraft: el.Aircraft, //"AIRBUS INDUSTRIE A321 JET", // Aircraft
								seats_left: el.SeatsLeft, //null, //SeatsLeft
								op_airline_id: el.OperatingAirlineCode ? el.OperatingAirlineCode : el.MarketingAirlineCode, //"TK", // OperatingAirlineCode
								ma_airline_id: el.MarketingAirlineCode, //"TK", //MarketingAirlineCode
							}
						});



					let PTC_Breakdown = pnrResponse.Air.AirItineraryPricingInfo.PTC_FareBreakdowns;

					if (PTC_Breakdown.length === 0) {
						PTC_Breakdown = cDetails;
					}

					// console.log("segments", segmentsPrep);

					const netTotalPrice = PTC_Breakdown.reduce((accumulator, object) => {
						return accumulator + object.TotalPrice;
					}, 0);
					//   console.log('netTotalPrice', netTotalPrice);

					const reservationTotalPrice = pnrResponse.Air.AirItineraryPricingInfo.TotalPrice;
					//   console.log('reservationTotalPrice', reservationTotalPrice);

					const totalNumberOfPax = PTC_Breakdown.reduce((accumulator, object) => {
						return accumulator + object.PassengerCount;
					}, 0);
					// console.log('totalNumberOfPax', totalNumberOfPax);

					const passengerCurrency = pnrResponse.Air.AirItineraryPricingInfo.CurrencyCode;
					//   console.log('passengerCurrency', passengerCurrency);

					const travelersPrep = pnrResponse.Travellers.map((el, ind) => {

						const fareBreakdownForType = PTC_Breakdown.filter(el2 => el2.PassengerType === el.TypeCode)[0];
						//console.log('fareBreakdownForType', fareBreakdownForType);
						//console.log('gender', el, el.Gender);

						return {
							traveler_no: ind + 1, // set counter 1.2.3....
							type_id: passengerTypes[el.TypeCode][2], // 1 - ADT(odrasli), 2 - CHD(dete), 3 - INF(beba) // data from the form
							gender_id: el.Gender ? "1" : "2", // if form the form 1 - muski, 2 - zenski, 3 - ne zelim da se izjansnim // data from the form
							title_id: el.Gender ? "1" : "2", // 1 - gospodin, 2 - gospodja // data from the form
							fname: el.FirstName, //"Marko", // data from the form
							lname: el.LastName, //"Markovic", // data from the form
							phone: null, //"00381641733488", // data from the form (use customer phone for the first pax)
							email: null, // data from the form (use customer email for the first pax)
							dob: el.DateOfBirth.split("T")[0], //"1984-11-25", // data from the pax form
							Baggage: el.AncillaryServiceInfos.map(
								(el2, ind2) => {
									return {
										traveler_no: ind + 1, // check which traveler is in list
										segment: ind2 + 1, //el2.FlightSegments, // number of ancillary items is the same as number of segments
										ancillary_type: el2.AncillaryType, //"Baggage", //AncillaryServiceInfos[0][AncillaryType]
										description: el2.Description, //"30 Kg", //AncillaryServiceInfos[0][Description]
										amount: parseInt(el2.Amount), //AncillaryServiceInfos[0][Amount]
										currency_code: el2.CurrencyCode, //"RSD" //AncillaryServiceInfos[0][CurrencyCode]
										is_per_pax: el2.IsPerPassenger ? 1 : 0
									}
								}
							),
							Price: {
								base_price_display: (fareBreakdownForType.BasePriceFromItinerary / fareBreakdownForType.PassengerCount) + ((reservationTotalPrice - netTotalPrice) / totalNumberOfPax),//"11384", // AirItineraryPricingInfo > TotalPrice (46452)
								base_price_system: fareBreakdownForType.BasePriceFromItinerary / fareBreakdownForType.PassengerCount,
								cur: passengerCurrency,	//"RSD" // AirItineraryPricingInfo > CurrencyCode
								fee: (reservationTotalPrice - netTotalPrice) / totalNumberOfPax, //"1000.00", // AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > BasePrice (46452) - AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > BasePriceFromItinerary (45452)
								pax_type_count: ind + 1, // brojac putnika po redu
								pax_type_id: passengerTypes[el.TypeCode][2], // 1 - ADT(odrasli), 2 - CHD(dete), 3 - INF(beba) // data from the form

								//"10384", //AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > TotalPrice
								tax: fareBreakdownForType.Tax / fareBreakdownForType.PassengerCount, //"35068.00", // AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > Tax
								total: fareBreakdownForType.TotalPrice / fareBreakdownForType.PassengerCount + ((reservationTotalPrice - netTotalPrice) / totalNumberOfPax), //"46452.00", // AirItineraryPricingInfo > PTC_FareBreakdowns[ADT,CHD,INF] > TotalPrice
							},
							AncillaryServiceIds: el.AncillaryServiceIds,
							AncillaryServiceInfos: el.AncillaryServiceInfos
						}

					})

					let supply_ref = null;
					let pnr_ndc = null

					if (pnrResponse.Air.Provider !== "Amadeus") {
						supply_ref = pnrResponse.ThirdPartyBookingReferenceNumber.split(":")[1];
						pnr_ndc = bookResp.NonGdsReferences[0].Code;
					}

					const storeReservationObject =
					{
						"Reservation": {
							"pnr": bookResp.ReferenceNumber, //"ReferenceNumber": 5. response book, this PNR is uset in step 6 for read pnr
							"pnr_airline": bookResp.AirlineBookingReferences.length ? bookResp.AirlineBookingReferences[0].ConfirmationNumber : bookResp.ReferenceNumber, //  "ConfirmationNumber", 5. response book,
							"pnr_ndc": pnr_ndc, // NDC-4-Confirmation-Response : NonGdsReferences.Code
							"supplier_ref": supply_ref,
							"trip_ref": pnrResponse.UniqueTripIdentifier, // UniqueTripIdentifier, 5. read PNR
							"pcc": pnrResponse.Air.OfficeId, // Air->OfficeId // currenti missing data in response 
							"from": pnrResponse.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments[0].DepartureAirport, // FlightSegments-[FIRST]-DepartureAirport // first element in flight segments / "SectorSequence": 0,
							//"from_country_code": 72,//storeSearch.from_country_code, // get country id for the airport
							"to": pnrResponse.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments[pnrResponse.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments.length - 1].ArrivalAirport, // FlightSegments-[LAST]-ArrivalAirport // last element in flight segments
							//"to_country_code": 185, // get country id for the airport
							"dep_date": pnrResponse.Air.AirItinerary.OriginDestinationOptions[0].FlightSegments[0].DepartureDate, // FlightSegments-[FIRST]-DepartureDate first element in flight segments
							"ret_date": oneWay === true ? null : pnrResponse.Air.AirItinerary.OriginDestinationOptions[pnrResponse.Air.AirItinerary.OriginDestinationOptions.length - 1].FlightSegments[0].DepartureDate, // null if is one way flight, FlightSegments-[LAST]-DepartureDate first element in flight segments, If return flight for data use "SectorSequence": 1, / 0 is for outbound (if one way then only this element exist) and 1 is for inbound (return flight)
							"portal_id": "1", // use one for now, end point will be created.
							"type_id": pnrResponse.Air.AirItinerary.OriginDestinationOptions.length > 1 ? "2" : "1", // rt -2 ow -1 / if "SectorSequence": AirItinerary->DirectionInd: "OneWay" or "Return" // if ther is more then one OriginDestinationOptions element then is return flight
							"payment_id": paymentType, //"1", // 1 - pay by invoice / 2 - pay by credit card - info missing from response ("Travellers"][0]["PaymentInfoObject"][0]["PaymentOption")
							"provider_id": providersArray.findIndex((element) => element === pnrResponse.Air.Provider) + 1, // Air->Provider / 1-Amadeus, 2-LufthansaGroup, 3-TravelFusion, 4-Swiss, 5-AustrianAirlines
							"origin_id": "1", // 1- online reservation / 2 - phone reservation
							"status_id": "1", // 1 - reservation / 2- issued ticket / 3 - void ticket / use only 1
							//"total_org": pnrResponse.Air.AirItineraryPricingInfo.TotalPrice, // AirItineraryPricingInfo->TotalPrice
							//"fee_org": parseInt(pnrResponse.Air.AirItineraryPricingInfo.TotalPrice) - parseInt(PTC_Breakdown[0].TotalPrice), // calcuclate AirItineraryPricingInfo->TotalPrice  - AirItineraryPricingInfo->PTC_FareBreakdowns->[0]->TotalPrice  (10082 - 9582).
							"org_cur": pnrResponse.Air.AirItineraryPricingInfo.CurrencyCode, // // AirItineraryPricingInfo -> CurrencyCode
							"flight_serch_id": storeSearch.id, // get info form store request
							"ff_name": pnrResponse.Air.AirItineraryPricingInfo.FareFamily, // Air->[0]->AirItineraryPricingInfo->FareFamily / data is missing from response
							"user_id": checkoutForm.email, // if user is registed then add user id from the system
							"note": checkoutForm.note // last field (napomena) in customer data form (payment page), add new text field in form
						},

						"Segments": segmentsPrep,
						"SegmentsMoreInfo": null,
						"Customer": {
							"user_id": checkoutForm.email, // if user is logged in add ID
							"company_name": checkoutForm.companyName.length ? checkoutForm.companyName : "", // data from the form
							"company_vat": checkoutForm.pib.length ? checkoutForm.pib : "", // data from the form / pib
							"gender_id": checkoutForm.gender === "man" ? "1" : "2", // if form the form 1 - muski, 2 - zenski, 3 - ne zelim da se izjansnim
							"title_id": checkoutForm.gender === "man" ? "1" : "2", // 1 - gospodin, 2 - gospodja
							"fname": checkoutForm.name, // data from the form
							"lname": checkoutForm.lastname, // data from the form
							"address": checkoutForm.address1 + " " + checkoutForm.address2, // data from the form
							"city": checkoutForm.city, // data from the form
							"zip": checkoutForm.zip, // data from the form
							"country_id": "184", //get selected country id from the form
							"country": checkoutForm.country, // country text name
							"email": checkoutForm.email, // data from the form
							"phone": checkoutForm.phone, // data from the form format 00381641733488 (+ convert to 00)
							"phoneCode": checkoutForm.phoneCode, // data from the form format 00381641733488 (+ convert to 00)
							"mob": checkoutForm.mobile, // data from the form format 00381641733488 (+ convert to 00)
							"mobCode": checkoutForm.mobileCode, // data from the form format 00381641733488 (+ convert to 00)
							"dob": values[0]["year"] + "-" + values[0]["month"] + "-" + values[0]["day"]
							//"1979-30-04" // data from the form format 1979-30-04

						},
						"Travelers": travelersPrep,
						// if is invoice return null "PaymentCcInfo": null,
						"PaymentCcInfo": null,
						"ExRates": {
							"ex_rate_to_eur": null, // ovde treba uzeti info sa Exchange Rates - Show rate endpointa // primer http://"+process.env.REACT_APP_API_URL+"/api/exrates?bank_id=1&currency=EUR&date=2021-09-09
							//https://office.airfantast.com/api/exrates?bank_id=1&currency=EUR&date=2022-06-06
							"ex_rate_to_rsd": null  // ovde treba uzeti info sa Exchange Rates - Show rate endpointa // primer http://"+process.env.REACT_APP_API_URL+"/api/exrates?bank_id=1&currency=EUR&date=2021-09-09
						}
					}


					// final page pnrResponse data
					console.log(
						storeReservationObject,
						// JSON.stringify(storeReservationObject)
					);

					// log storage
					fetch('https://storage.airfantast.com/thomalex-logs/store_request.php?searchId=' + storeSearch.id + '&step=9&type=response', {
						method: "POST",
						body: JSON.stringify(
							storeReservationObject
						)
					})

					//const storeURL = 'https://office.airfantast.com/api/storeReservaton';
					const storeURL = 'https://"+process.env.REACT_APP_API_URL+"/api/storeReservaton';
					const storeReq = await fetch(storeURL, {
						method: "POST",

						//headers: {
						//	Authorization: "Bearer " + token,
						//	"Content-Type": "application/json",
						//},
						//withCredentials: true,

						body: JSON.stringify(
							storeReservationObject
						)
					}
					);

					const storeResponse = await storeReq.json();
					//console.log(storeResponse);
					if (storeResponse.message) {
						if (storeResponse.message === "Booking stored OK!") {
							dispatch(setBuyerInfo(checkoutForm));
							dispatch(setStoreReservation(storeResponse));
							dispatch(setInfoWindowCloseRefresh(true));
							dispatch(setInfoWindow("Email poslat - PNR: " + bookResp.ReferenceNumber));
							dispatch(setQuaternaryFormContent("POTVRDA_REZERVACIJE"));
							dispatch(changeFormPosition(-3));
						}
					}

				}
			}
			dispatch(toggleLoadingStatus());
		} catch (e) {
			console.error(e);
		}


	}, [checkoutForm, values, sessionID, dispatch, loggerReq, storeSearch.id, thomalexObject.SessionId, token, oneWay, passengerTypes, cDetails, cartDetails, isItTravelFusion, paymentType]);


	const activateChoiceYes = useCallback(async () => {
		bookRequestHandlerTravelFusionCCConfirmation();

		dispatch(setChoiceWindow(false));
		dispatch(setChoiceOption(false));
	}, [dispatch, bookRequestHandlerTravelFusionCCConfirmation])

	const activateChoiceNo = useCallback(async () => {
		dispatch(setChoiceWindow(false));
		dispatch(setChoiceOption(false));
	}, [dispatch])

	// if choice window is closed
	useEffect(() => {
		if (choiceOption === "YES") {
			activateChoiceYes();
		}
		if (choiceOption === "NO") {
			activateChoiceNo();
		}
	}, [choiceOption, activateChoiceYes, activateChoiceNo])


	return (
		<div className={[classes.finalFormHolder, classes['finalFormPosition' + overViewState]].join(' ')} ref={scrollToRef}>
			{/* overview screen */}
			<div className={[classes.finalFormElement, classes.checkout1].join(" ") + " flightDetails"}>
				<div className={[classes.backButton + " backButton"]} onClick={handleFormReset}>
					<span style={{ marginRight: '-6px', transform: 'scaleX(-1)' }}>
						<ArrowLeft />
					</span>
					<span>
						<ArrowLeft />
					</span>
				</div>
				<div className={classes.resultCard + " resultCard grayBg"}>
					<div className={classes.resultCardHeader + " resultCardHeader"}>
						Cena karte
					</div>
					{detailsRender}
					<div className={classes.totalPriceHolder + " thinBorderTop totalPrice bold"}>
						<span>Ukupno za naplatu</span>
						<span>{totalPriceRender}</span>
					</div>

				</div>
				<div className={classes.priceButton + " resultFooterButtonRight black"}
					onClick={() => {
						setOverViewState(2);
						loggerReq(4);
						window.scrollTo({ top: 0 });
					}}
				>Prihvatam cenu<br />
					{totalPriceRender}</div>
				<br />
			</div>

			{/* first form screen */}

			<div className={[classes.finalFormElement, classes.checkout2].join(" ") + " flightDetails"}>
				<div className={[classes.backButton + " backButton"]} onClick={() => {
					setOverViewState(1);
					loggerReq();
					window.scrollTo({ top: 0 });
				}}>
					<ArrowLeft />
				</div>
				<div className={classes.resultCard + " resultCard grayBg"}>
					<div className={classes.resultCardHeader + " resultCardHeader"}>
						Upišite podatke putnika
					</div>
					{passengerInputs}
					{ancilleryOutputPerPaxFalse
						? (
							<>
								<HorizontalDivider gray relative />
								<div className={classes.passengerInputHolder}>
									<div className={[classes.ancillaryTitle, 'darkText'].join(' ')}>
										Po rezervaciji
									</div>
									<div className={[classes.ancillaryInputHolder, classes.ancillaryInputHolderVisible, 'darkText'].join(' ')} style={{ overflow: 'visible' }}>
										{ancilleryOutputPerPaxFalse}
									</div>
								</div>
							</>
						)
						: null}
				</div>

				<div className={classes.priceButton + " resultFooterButtonRight black"}
					onClick={() => {
						setOverViewState(3);
						loggerReq(5);
						window.scrollTo({ top: 0 }); copyFormOwnerData()
					}}
					disabled={!nextStep}
				>Sledeći korak</div>
				<br />
			</div>

			{/* second form screen */}
			<div className={[classes.finalFormElement, classes.checkout3].join(" ") + " flightDetails"}>
				<div className={[classes.backButton + " backButton"]} onClick={() => {
					setOverViewState(2);
					loggerReq(4);
					window.scrollTo({ top: 0 });
				}}>
					<ArrowLeft />
				</div>
				<div className={classes.resultCard + " resultCard grayBg"}>
					<div className={classes.resultCardHeader + " resultCardHeader"}>
						Opcija plaćanja
					</div>
					<div className={classes.resultCardBody}>
						<div className={classes.passengerRecapTitle + " bold optionsHeadline"}>
							Broj putnika:
						</div>
						<div className={classes.passengerRecapNumbers}>
							<div className={classes.passengetRecapNumber}>
								<span className={"darkText light"}>Odrasli</span>
								<span className={classes.passengerNumber + " oswald passengerNumber bold"}>{numberOfAdults < 10 ? "0" + numberOfAdults : numberOfAdults}</span>
							</div>
							<div className={classes.passengetRecapNumber}>
								<span className={"darkText light"}>Deca</span>
								<span className={classes.passengerNumber + " oswald passengerNumber bold"}>{numberOfChildren < 10 ? "0" + numberOfChildren : numberOfChildren}</span>
							</div>
							<div className={classes.passengetRecapNumber}>
								<span className={"darkText light"}>Bebe</span>
								<span className={classes.passengerNumber + " oswald passengerNumber bold"}>{numberOfBabies < 10 ? "0" + numberOfBabies : numberOfBabies}</span>
							</div>
						</div>
						<br />

						<div className={classes.detailFareHolder + " light darkText"}>
							<div className={classes.detailFareTitleControll} onClick={() => toggledetailFareControl()}>
								<div className={classes.detailFareTitle}>
									Detaljan prikaz
								</div>
								<div className={[classes.detailFareDropDownArrow, detailFareOpened === true ? classes.detailFareArrowRotated : null, "ancillaryDropDownArrow"].join(" ")}>
									<ArrowRight />
								</div>
							</div>
							<div className={[classes.detailFarePriceHolder, detailFareOpened === true ? classes.detailFarePriceVisible : null].join(" ")}>
								{detailsRender}
								{detailsAncillaryHeader}
								{detailsAncillaryRender}
							</div>
						</div>
						<>
							<div className={classes.priceSummary + " darkText light"}>
								<span>Avio karte sa taksama ukupno</span><span>
									{ticketPriceTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")},00&nbsp;
									{cartDetails ? cartDetails.ShoppingCart.Air.AirItineraryPricingInfo.CurrencyCode : ""}
								</span>
							</div>
							{(ancillaryPriceTotal > 0)
								? (
									<div className={classes.priceSummary + " darkText light"}>
										<span>Dodatne usluge ukupno</span><span>
											{ancillaryPriceTotal.toLocaleString("sr-RS", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}&nbsp;
											{cartDetails ? cartDetails.ShoppingCart.Air.AirItineraryPricingInfo.CurrencyCode : ""}
										</span>
									</div>)
								: null}
						</>
						<div className={classes.passengerRecapPrice + " bold optionsHeadline"} style={{ marginTop: "0.5rem" }}>
							<span>Ukupno za naplatu:</span>
							<span>{(ticketPriceTotal + ancillaryPriceTotal).toLocaleString("sr-RS", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}&nbsp;
								{cartDetails ? cartDetails.ShoppingCart.Air.AirItineraryPricingInfo.CurrencyCode : ""}
							</span>
						</div>
						<br />
						<HorizontalDivider gray relative />
						<br />
						<div className={classes.paymentTypeSelectorHolder}>
							<div className={[classes.paymentTypeSelector, "paymentSelector", (paymentType === 1 ? "active" : "") +
								(cartProvider ? (cartProvider === 'TravelFusion' ? classes.paymentTypeSelectorDisabled : "") : "")].join(" ")}
								onClick={() => {
									if (cartProvider) {
										if (cartProvider === 'TravelFusion') return;
									}
									setPaymentType(1);
								}}
							>
								<BankIcon />
								<span className='light'>Plaćanje&nbsp;preko računa</span>
							</div>
							<div className={classes.paymentTypeSelector + " paymentSelector" + (paymentType === 2 ? " active" : "")}
								onClick={() => { setPaymentType(2); /*setcheckoutFormValid(true)*/ }}
							>
								<CreditCardIcon />
								<span className='light'>Kreditnom karticom</span>
							</div>
						</div>
						<br />
						{paymentType === 2 ? (
							<div className={classes.ccform}>
								<div className={classes.passengerInputElement + " light darkText"}>
									<div className={classes.billingInputLabel}>
										Ime na <br />kartici
									</div>
									<div className={classes.passengerInput}>
										<input className={"passengerInput"} type="text" name="ccname" value={checkoutForm["ccname"]} onChange={handleCheckoutState} />
									</div>
								</div>
								<div className={classes.passengerInputElement + " light darkText"}>
									<div className={classes.billingInputLabel}>
										Broj<br />kartice
									</div>
									<div className={classes.passengerInput}>
										<input className={"passengerInput"} type="tel" inputMode="numeric" name="ccnr" pattern="[0-9\s]{13,19}" autoComplete="cc-number" maxLength="19" placeholder="xxxx xxxx xxxx xxxx" value={checkoutForm["ccnr"]} onChange={handleCheckoutState} />
									</div>
								</div>
								<div className={classes.passengerInputElement + " light darkText"}>
									<div className={classes.billingInputLabel}>
										Ističe
									</div>
									<div className={classes.passengerInput}>
										<div style={{ display: "flex", justifyContent: "space-between" }}>
											<div className={classes.inputContainer}>
												<span>Mesec</span>
												<input
													className={classes.roundedInput}
													type="number"
													inputMode="numeric"
													min={1}
													max={12}
													name="ccmonth"
													onChange={handleCheckoutState}
													placeholder="--"
												/>
											</div>
											<div className={classes.inputContainer} style={{ marginLeft: "2rem" }}>
												<span>Godina</span>
												<input
													className={classes.roundedInput}
													type="number"
													inputMode="numeric"
													autoComplete="off"
													name="ccyear"
													onChange={handleCheckoutState}
													min={parseInt((new Date().getFullYear()).toString().slice(-2))}
													max={parseInt((new Date().getFullYear()).toString().slice(-2)) + 20}
													placeholder="--"
												/>
											</div>
										</div>
									</div>
								</div>
								<div className={classes.passengerInputElement + " light darkText"}>
									<div className={classes.billingInputLabel}>
										CVV/CVV2
									</div>
									<div className={classes.passengerInput}>
										<div style={{ display: "flex", width: "80%" }}>
											<div className={classes.inputContainer}>
												<input
													className={classes.roundedInput}
													type="number"
													inputMode="numeric"
													name="ccv"
													onChange={handleCheckoutState}
													maxLength={4}
													placeholder="---"
												/>
											</div>
											<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
												<path id="Path_211" data-name="Path 211" d="M16.4,10h3.2v3.2H16.4Zm0,6.4h3.2V26H16.4ZM18,2A16,16,0,1,0,34,18,16.006,16.006,0,0,0,18,2Zm0,28.8A12.8,12.8,0,1,1,30.8,18,12.817,12.817,0,0,1,18,30.8Z" transform="translate(-2 -2)" fill="#707070" />
											</svg>
										</div>
									</div>
								</div>
								<br />
							</div>
						) : ''}
						<div className={classes.paymentTypeSelectorHolder}>
							<div className={classes.legalEntitySelector + " legalEntitySelector" + (legalEntity === 1 ? " active" : "")}
								onClick={() => { setLegalEntityType(1) }}
							>
								<span className={(legalEntity === 1 ? 'bold' : 'light')}>Fizičko lice</span>
							</div>
							<div className={classes.legalEntitySelector + " legalEntitySelector" + (legalEntity === 2 ? " active" : "")}
								onClick={() => { setLegalEntityType(2); }}
							>
								<span className={(legalEntity === 2 ? 'bold' : 'light')}>Pravno lice</span>
							</div>
						</div>
						<br />
						<div className={classes.billingInputHolder}>
							{finalCheckoutForm}
						</div>
					</div>
				</div>

				<div className={classes.priceButton + " resultFooterButtonRight black"}
					disabled={!checkoutFormValid}
					onClick={() => {
						if (paymentType === 1) {
							bookRequestHandler();
							setcheckoutFormValid(false);
						} else {
							if (isItTravelFusion) {
								bookRequestHandlerTravelFusionCC();

							} else {
								bookRequestHandler();
							}
							setcheckoutFormValid(false);
							//startIframeRequest();
						}
					}}
				>
					Potvrđujem plaćanje<br />
					{/*totalPriceRender*/}
					{(ticketPriceTotal + ancillaryPriceTotal).toLocaleString("sr-RS", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}&nbsp;
								{cartDetails ? cartDetails.ShoppingCart.Air.AirItineraryPricingInfo.CurrencyCode : ""}
				</div>
				<br />
			</div>
		</div>

	)
};

export default CheckoutForm;