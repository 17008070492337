import React, { useState } from "react";
import { useDispatch } from "react-redux";

import CancelRounded from "../../Icons/CancelRounded";
import Button from "../../UI/Button";
import Background from "../../UI/Background";

import SignupModule from "./SignupModule";
import LoginModule from "./LoginModule";
import ForgotPasswordModule from "./ForgotPasswordModule";

import { setInfoWindow, toggleLoadingStatus } from "../../../store/ui-slice";
import {
  authSuccess,
  //  setTokenExpiration
} from "../../../store/auth-slice";

import { useHistory } from "react-router-dom";

import classes from "./AuthHolder.module.css";

const AuthHolder = (props) => {
  const history = useHistory();

  const [formType, setFormType] = useState("login");

  const dispatch = useDispatch();

  const setLoading = () => {
    dispatch(toggleLoadingStatus());
  };

  const deActivateAuthWindow = () => {
    history.push("/");
  };

  const handleChangeForgot = () => {
    setFormType("forgot");
  };

  const handleChangeLogin = () => {
    setFormType("login");
  };

  const handleChangeRegister = () => {
    setFormType("register");
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setLoading();
    const elements = e.target.elements;
    const objects = Object.keys(elements).reduce((obj, field) => {
      if (isNaN(field)) {
        obj[field] = elements[field].value;
      }
      return obj;
    }, {});
    const submitURL = "https://"+process.env.REACT_APP_API_URL+"/api/register";
    fetch(submitURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: objects.email,
        password: objects.password,
        c_password: objects.confirmPassword,
      }),
    })
      .then((res) => {
        if (res.ok) {
          console.log(res);
          return res.json();
        } else {
          let errorMessage = "Registracija nije uspela!";
          setLoading();
          throw new Error(errorMessage);
        }
      })
      .then((data) => {
        console.log(data);
        //if (data.success) {
        dispatch(setInfoWindow(data.message));
        //alert(data.message);
        //}
        setLoading();
      })
      .catch((err) => {
        dispatch(setInfoWindow(err.message));
        //alert(err.message);
      });
  };

  const handleLoginSubmit = async (e) => {
    setLoading();
    e.preventDefault();
    const elements = e.target.elements;
    const objects = Object.keys(elements).reduce((obj, field) => {
      if (isNaN(field)) {
        obj[field] = elements[field].value;
      }
      return obj;
    }, {});
    const submitURL = "https://"+process.env.REACT_APP_API_URL+"/api/login";
    fetch(submitURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: objects.email,
        password: objects.password,
      }),
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          //const data = await res.json();
          let errorMessage = "Authentication failed!";
          setLoading();
          throw new Error(errorMessage);
        }
      })
      .then((data) => {
        if (data.success) {
          console.log("AUTH SUCCESS");
          dispatch(
            authSuccess({ token: data.data.token, userId: data.data.email, displayName: data.data.display_name ? data.data.display_name : "Ažurirajte profil" })
          );

          const tokenObject = JSON.parse(
            Buffer.from(data.data.token.split(".")[1], "base64")
          );
          const expiresIn = tokenObject.exp - tokenObject.iat;
          const expirationDate = new Date(tokenObject.exp * 1000);

          console.log(tokenObject, expiresIn, expirationDate);

          //dispatch(setTokenExpiration(expirationDate));
        } else {
          //alert(data.message);
          dispatch(setInfoWindow(data.message));
        }
        setLoading();
      })
      .catch((err) => {
        //alert(err.message);
        dispatch(setInfoWindow(err.message));
      });
  };

  const handleForgotSubmit = async (e) => {
    setLoading();
    e.preventDefault();
    const elements = e.target.elements;
    const objects = Object.keys(elements).reduce((obj, field) => {
      if (isNaN(field)) {
        obj[field] = elements[field].value;
      }
      return obj;
    }, {});
    const submitURL = "https://"+process.env.REACT_APP_API_URL+"/api/forgot-password";
    fetch(submitURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: objects.email,
      }),
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          //const data = await res.json();
          let errorMessage = "Password retreive failed!";
          setLoading();
          throw new Error(errorMessage);
        }
      })
      .then((data) => {
        console.log(data);
        if (data.success) {
          console.log("AUTH SUCCESS");
          dispatch(
            authSuccess({ token: data.data.token, userId: data.data.email })
          );

          /*
          const tokenObject = JSON.parse(
            Buffer.from(data.data.token.split(".")[1], "base64")
          );
          const expiresIn = tokenObject.exp - tokenObject.iat;
          const expirationDate = new Date(tokenObject.exp * 1000);

          console.log(tokenObject, expiresIn, expirationDate);
            */
          //dispatch(setTokenExpiration(expirationDate));
        } else {
          dispatch(setInfoWindow(data.message));
          //alert(data.message);
        }
        setLoading();
      })
      .catch((err) => {
        dispatch(setInfoWindow(err.message));
        //alert(err.message);
      });
  };

  return (
    <div className={"authHolder"}>
      <Background />
      <div className="closeIcon" onClick={deActivateAuthWindow}>
        <CancelRounded />
      </div>
      {formType === "register" && (
        <SignupModule handleFormSubmit={handleRegisterSubmit} />
      )}
      {formType === "login" && (
        <LoginModule handleFormSubmit={handleLoginSubmit} />
      )}
      {formType === "forgot" && (
        <ForgotPasswordModule handleFormSubmit={handleForgotSubmit} />
      )}
      <div className={classes.authChooser}>
        {formType !== "login" && (
          <Button onClick={handleChangeLogin}>Ulogujte se</Button>
        )}
        {formType !== "register" && (
          <Button onClick={handleChangeRegister}>Registrujte se</Button>
        )}
        {formType !== "forgot" && (
          <Button onClick={handleChangeForgot}>Zaboravljena lozinka</Button>
        )}
      </div>
    </div>
  );
};

export default AuthHolder;
