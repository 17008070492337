import { useSelector, useDispatch } from "react-redux";
import Button from "../../UI/Button";
import HorizontalDivider from "../../UI/HorizontalDivider";
import VerticalDivider from "../../UI/VerticalDivider";

import { changeClass } from "../../../store/form-slice";

import classes from "./ClassModule.module.css";

const ClassModule = (props) => {
  const classState = useSelector((state) => state.form.travelClass);

  //const [classState, changeClassState] = useState(classValue);

  const dispatch = useDispatch();

  const changeClassHandler = (props) => {
    //console.log(props);
    /*
    if (props.active) {
      dispatch(changeClass(5));
      return;
    };
    */    
    dispatch(changeClass(props.classValue))
    //changeClassState(props.classValue);
  };

  return (
    <div className={classes.classModule}>
      <div className={classes.title}>Izaberite klasu</div>
      <div className={classes.module}>
        <div className={classes.verticalModule}>
          <Button
            onClick={changeClassHandler}
            active={classState === 1}
            classValue={1}
          >
            Ekonomska
          </Button>
          <Button
            onClick={changeClassHandler}
            active={classState === 2}
            classValue={2}
          >
            Premium
          </Button>
        </div>
        <VerticalDivider />
        <div className={classes.verticalModule}>
          <Button
            onClick={changeClassHandler}
            active={classState === 3}
            classValue={3}
          >
            Biznis
          </Button>
          <Button
            onClick={changeClassHandler}
            active={classState === 4}
            classValue={4}
          >
            Prva
          </Button>
        </div>
      </div>

      <HorizontalDivider />
    </div>
  );
};

export default ClassModule;
