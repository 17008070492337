import React from "react";
import useForm from "../../Utils/useForm";
import { loginForm } from "../../Utils/formConfig";

import Button from "../../UI/Button";

export default function LoginModule(props) {
  const { renderFormInputs, isFormValid } = useForm(loginForm);

  return (
    <div className="authModuleHolder">
      <h2>Prijava</h2>
      <form autoComplete="off" onSubmit={props.handleFormSubmit}>
        {renderFormInputs()}
        <Button
          type="submit"
          filled
          className={!isFormValid() ? " disabled" : ""}
          onClick={() => {}}
        >
          Prijavite se
        </Button>
      </form>
    </div>
  );
}
