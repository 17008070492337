import React from "react";

import "./HorizontalDivider.css";

const HorizontalDivider = (props) => {
  let classSelector = "horizontalDivider";
  if (props.secondary) {
    classSelector += " secondary";
  }
  if (props.gray) {
    classSelector += " gray";
  }
  if (props.thick) {
    classSelector += " thick";
  }
  if (props.relative) {
    classSelector += " relative";
  }
  
  return <div className={classSelector}></div>;
};

export default HorizontalDivider;
