const UserIcon = (props) => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <path
        id="user-icon"
        data-name="Path 25"
        d="M22,2A20,20,0,1,0,42,22,20.007,20.007,0,0,0,22,2ZM12.14,34.56C13,32.76,18.24,31,22,31s9.02,1.76,9.86,3.56a15.851,15.851,0,0,1-19.72,0Zm22.58-2.9C31.86,28.18,24.92,27,22,27s-9.86,1.18-12.72,4.66a16,16,0,1,1,25.44,0ZM22,10a7,7,0,1,0,7,7A6.982,6.982,0,0,0,22,10Zm0,10a3,3,0,1,1,3-3A3,3,0,0,1,22,20Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
};

export default UserIcon;
