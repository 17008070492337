import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../UI/Button";
import CancelRounded from "../../Icons/CancelRounded";
import Input from "../../UI/InputField";
import HorizontalDivider from "../../UI/HorizontalDivider";
import DateInput from "../../UI/DateInput";
import Select from "react-select";
import AsyncSelect from "react-select/async";
//import Dropdown from "react-dropdown";

import { registerLocale } from "react-datepicker";
import srLatn from "date-fns/locale/sr-Latn";

import { useHistory } from "react-router-dom";
import { authLogout } from "../../../store/auth-slice";

import {
  setInfoWindow, setInfoWindowCloseRefresh,
} from '../../../store/ui-slice';

import {
  setProfile
} from '../../../store/auth-slice';

import classes from "./UserProfile.module.css";

/*
const arrowClosed = (
  <svg
    height="20"
    width="20"
    viewBox="0 0 20 20"
    aria-hidden="true"
    focusable="false"
  >
    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
  </svg>
);
*/

const UserProfile = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  registerLocale("srLatn", srLatn);

  const token = useSelector((state) => state.auth.token);

  const helperArr = useSelector((state) => state.form.helperList);
  const countryArr = useSelector((state) => state.form.countryList);

  const [countryDropDown, setCountryDropDown] = useState([]);
  const [titleDropDown, setTitleDropDown] = useState([]);
  const [genderDropDown, setGenderDropDown] = useState([]);

  const deactivateProfile = () => {
    history.push("/");
  };

  const handleLogout = () => {
    dispatch(authLogout());
  };

  const inputChangeHandler = (e) => {
    const val = e.target.value;
    //console.log(val);
    switch (e.target.name) {
      case "fname":
        changeFnameValue(val);
        break;
      case "lname":
        changeLnameValue(val);
        break;
      case "dname":
        changeDisplayNameValue(val);
        break;
      case "street":
        changeStreetValue(val);
        break;
      case "city":
        changeCityValue(val);
        break;
      case "zip":
        changeZipValue(val);
        break;
      case "phone":
        changePhoneValue(val);
        break;
      case "cname":
        changeCompanyNameValue(val);
        break;
      case "cpib":
        changeCompanyVATValue(val);
        break;

      default:
        break;
    }
  };

  const [companyNameValue, changeCompanyNameValue] = useState("");
  const [companyVATValue, changeCompanyVATValue] = useState("");

  const [displayNameValue, changeDisplayNameValue] = useState("");
  const [fnameValue, changeFnameValue] = useState("");
  const [lnameValue, changeLnameValue] = useState("");
  const [streetValue, changeStreetValue] = useState("");
  const [cityValue, changeCityValue] = useState("");
  const [zipValue, changeZipValue] = useState("");
  const [phoneValue, changePhoneValue] = useState("");
  const [titleValue, changeTitleValue] = useState();
  const [genderValue, changeGenderValue] = useState();
  const [countryValue, changeCountryValue] = useState();

  const handleSave = async () => {
    let strDate = "";
    if (dobYear.length && dobMonth.length && dobDay.length) {
      strDate = dobYear + "-" + dobMonth + "-" + dobDay;
    }
    const dataForSave = {
      company_name: companyNameValue.length ? companyNameValue : "",
      company_vat: companyVATValue.length ? companyVATValue : "",
      title_id: titleValue.value,
      gender_id: genderValue.value,
      fname: fnameValue,
      lname: lnameValue,
      display_name: displayNameValue,
      address: streetValue,
      city: cityValue,
      zip: zipValue,
      country_id: countryValue.value,
      phone: phoneValue,
      dob: strDate,
    };
    //console.log(dataForSave);
    const profileSaveURL = "https://"+process.env.REACT_APP_API_URL+"/api/profile_update";
    const saveRes = await fetch(profileSaveURL, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      body: JSON.stringify(dataForSave),
    });
    const resp = await saveRes.json();
    if (resp.success && resp.message.includes("Customer profile successfully")) {
      dispatch(setInfoWindow("Profil uspešno sačuvan"));
    } else {
      dispatch(setInfoWindow("Greška pri snimanju profila"));
    }
  };

  const [dobDay, changeDobDay] = useState("");
  const [dobMonth, changeDobMonth] = useState("");
  const [dobYear, changeDobYear] = useState("");

  const dobInputChangeHandler = (e) => {
    const nm = e.target.name;
    let val = e.target.value;
    let val2 = parseInt(val) < 10 ? "0" + parseInt(val) : val;
    switch (nm) {
      case "day":
        if (val2.length > 2) {
          val2 = parseInt(val2).toString().substring(0, 2);
        }
        if (parseInt(val2) > 31) {
          val2 = "31";
        }
        changeDobDay(val2);
        break;
      case "month":
        if (val2.length > 2) {
          val2 = parseInt(val2).toString().substring(0, 2);
        }
        if (parseInt(val2) > 12) {
          val2 = "12";
        }
        changeDobMonth(val2);
        break;
      case "year":
        val2 = parseInt(val) < 1000 ? "0" + parseInt(val) : val;
        val2 = parseInt(val2) < 100 ? "00" + parseInt(val2) : val2;
        val2 = parseInt(val2) < 10 ? "000" + parseInt(val2) : val2;
        if (val2.length > 4) {
          val2 = parseInt(val2).toString().substr(0, 4);
        }
        if (parseInt(val2) > new Date().getFullYear()) {
          val2 = new Date().getFullYear()
        }
        changeDobYear(val2);
        break;
      default:
    }
  };

  const blurHandler = (e) => {
    if (fnameValue.length && lnameValue.length && !displayNameValue.length) {
      changeDisplayNameValue(fnameValue + " " + lnameValue);
    }
  };

  /*
  const populateUserData = useCallback((data) => {
    console.log(data);

    if (data.fname) changeFnameValue(data.fname);
    if (data.lname) changeLnameValue(data.fname);
    if (data.display_name) changeDisplayNameValue(data.display_name);
    if (data.address) changeStreetValue(data.address);
    if (data.city) changeCityValue(data.city);
    if (data.zip) changeZipValue(data.zip);
    if (data.phone) changePhoneValue(data.phone);
    if (data.dob) {
      const splitedDob = data.dob.split("-");
      changeDobYear(splitedDob[0]);
      changeDobMonth(splitedDob[1]);
      changeDobDay(splitedDob[2]);
    }

    if (data.company_name) changeCompanyNameValue(data.company_name);
    if (data.company_vat) changeCompanyVATValue(data.company_vat);

    if (data.title_name) {
      changeTitleValue(titleDropDown.filter(el => el.label === data.title_name)[0]);
    }
    if (data.gender_name) {
      changeGenderValue(genderDropDown.filter(el => el.label === data.gender_name)[0]);
    }
    if (data.country_name) {
      changeCountryValue(countryDropDown.filter(el => el.label === data.country_name)[0]);
    }
  },[titleDropDown, genderDropDown, countryDropDown]);
  */
  /*
  const loadUserProfile = useCallback(async () => {
    console.log("loadUserProfile");
    const loadProfileUrl = "https://"+process.env.REACT_APP_API_URL+"/api/show_profile";
    const loadRes = await fetch(loadProfileUrl, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      withCredentials: true,
    });
    const reply = await loadRes.json();
    if (reply) {
      if (reply.success) {
        if (reply.message.includes("profile listed")) {
          const data = reply.data;
          populateUserData(data);
        }
      }
    }
  }, [token, populateUserData]);
  */

  useEffect(() => {
    const loadUserProfile = async () => {
      const loadProfileUrl = "https://"+process.env.REACT_APP_API_URL+"/api/show_profile";
      const loadRes = await fetch(loadProfileUrl, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        withCredentials: true,
      }).catch(e => { throw new Error(e) });
      const reply = await loadRes.json();
      if (reply) {
        if (reply.success) {
          if (reply.message) {
            if (reply.message.includes("profile listed")) {
              const data = reply.data;
              console.log(data);
              dispatch(setProfile(data));
              if (data.fname) changeFnameValue(data.fname);
              if (data.lname) changeLnameValue(data.lname);
              if (data.display_name) changeDisplayNameValue(data.display_name);
              if (data.address) changeStreetValue(data.address);
              if (data.city) changeCityValue(data.city);
              if (data.zip) changeZipValue(data.zip);
              if (data.phone) changePhoneValue(data.phone);
              if (data.dob) {
                const splitedDob = data.dob.split("-");
                changeDobYear(splitedDob[0]);
                changeDobMonth(splitedDob[1]);
                changeDobDay(splitedDob[2]);
              }

              if (data.company_name) changeCompanyNameValue(data.company_name);
              if (data.company_vat) changeCompanyVATValue(data.company_vat);

              if (data.country_id) {
                const foundCountry = countryArr.countries.filter(
                  (el) => el.id === data.country_id
                )[0];
                if (foundCountry) {
                  changeCountryValue({
                    value: foundCountry.id,
                    label: foundCountry.serbian,
                  });
                } else {
                  changeCountryValue({
                    value: data.country_id,
                    label: data.country_name,
                  });
                }
              }
              if (data.gender_id) {
                const foundGender = helperArr.customer_gender.filter(
                  (el) => el.id === data.gender_id
                )[0];
                changeGenderValue({
                  value: foundGender.id,
                  label: foundGender.name,
                });
              }
              if (data.title_id) {
                const foundTitle = helperArr.customer_titles.filter(
                  (el) => el.id === data.title_id
                )[0];
                changeTitleValue({
                  value: foundTitle.id,
                  label: foundTitle.name,
                });
              }
            }
          }
        }
      }
    };

    if (helperArr && countryArr) {
      setCountryDropDown(
        countryArr.countries.map((el) => {
          return { value: el.id, label: el.serbian };
        })
      );
      setGenderDropDown(
        helperArr.customer_gender.map((el) => {
          return { value: el.id, label: el.name };
        })
      );
      setTitleDropDown(
        helperArr.customer_titles.map((el) => {
          return { value: el.id, label: el.name };
        })
      );
      loadUserProfile();
    }
  }, [token, helperArr, countryArr, dispatch]);

  //async load countries
  const loadOptions = async (inputVal, callback) => {
    if (inputVal.length < 2) return;
    const resp = await fetch(
      "https://"+process.env.REACT_APP_API_URL+"/api/getc?data=" + inputVal
    );
    const data = await resp.json();
    const sortedData = data.map((el) => {
      const splited = el.split(" - ");
      return { value: splited[0], label: splited[1] };
    });
    callback(sortedData);
  };

  const handleInputChange = (newValue) => {
    //console.log(newValue);
  };

  //console.log(countryValue);

  return (
    <React.Fragment>
      <div id="profile" className={classes.profileHolder}>
        <div className="closeIcon" onClick={deactivateProfile}>
          <CancelRounded />
        </div>
        <h2>Profil</h2>
        <div className={classes.profileData}>
          {/*const { label, type, name, handleChange, errorMessage, isValid, value } =*/}
          <div className={classes.profileSelectHolder}>
            Titula
            <Select
              value={
                titleValue
                  ? titleDropDown[
                  titleDropDown
                    .map((el) => {
                      return el.value;
                    })
                    .indexOf(titleValue.value)
                  ]
                  : null
              }
              placeholder={"Izaberite titulu..."}
              classNamePrefix={"profileSelect"}
              options={titleDropDown}
              onChange={(value) => {
                //console.log(value, titleValue);
                changeTitleValue(value);
              }}
            />
            {/*
            <Dropdown
              className={"dropdownComponent"}
              placeholder="Izaberite titulu..."
              options={titleDropDown}
              onChange={(value) => {
                console.log(value);
                changeTitleValue(value);
              }}
              value={
                titleValue
                  ? typeof titleValue === "string"
                    ? titleValue
                    : titleValue.label
                  : ""
              }
              arrowClosed={arrowClosed}
              arrowOpen={arrowClosed}
            />
            */}
            <HorizontalDivider secondary />
          </div>
          <Input
            label={"Ime"}
            name={"fname"}
            type={"text"}
            handleChange={inputChangeHandler.bind(this)}
            value={fnameValue}
            blur={blurHandler.bind(this)}
          />
          <Input
            label={"Prezime"}
            name={"lname"}
            type={"text"}
            handleChange={inputChangeHandler.bind(this)}
            value={lnameValue}
            blur={blurHandler.bind(this)}
          />
          <Input
            label={"Profilno ime"}
            name={"dname"}
            type={"text"}
            handleChange={inputChangeHandler.bind(this)}
            value={displayNameValue}
          />
          <div className={classes.profileSelectHolder}>
            Pol
            <Select
              value={
                genderValue
                  ? genderDropDown[
                  genderDropDown
                    .map((el) => {
                      return el.value;
                    })
                    .indexOf(genderValue.value)
                  ]
                  : null
              }
              placeholder={"Izaberite pol..."}
              classNamePrefix={"profileSelect"}
              options={genderDropDown}
              onChange={(value) => changeGenderValue(value)}
            />
            {/*
            <Dropdown
              className={"dropdownComponent"}
              placeholder="Izaberite pol..."
              options={genderDropDown}
              onChange={(value) => {
                changeGenderValue(value);
              }}
              value={
                genderValue
                  ? typeof genderValue === "string"
                    ? genderValue
                    : genderValue.label
                  : ""
              }
              arrowClosed={arrowClosed}
              arrowOpen={arrowClosed}
            />
            */}
            <HorizontalDivider secondary />
          </div>

          <Input
            label={"Ulica"}
            name={"street"}
            type={"text"}
            handleChange={inputChangeHandler.bind(this)}
            value={streetValue}
          />
          <Input
            label={"Grad"}
            name={"city"}
            type={"text"}
            handleChange={inputChangeHandler.bind(this)}
            value={cityValue}
          />
          <Input
            label={"Poštanski broj"}
            name={"zip"}
            type={"text"}
            handleChange={inputChangeHandler.bind(this)}
            value={zipValue}
          />

          <div className={classes.countrySelectHolder}>
            Država
            {/*
            <Dropdown
              className={"dropdownComponent"}
              placeholder={"Izaberite državu..."}
              options={countryDropDown}
              onChange={(value) => {
                console.log(value);
                changeCountryValue(value);
              }}
              value={
                countryValue
                  ? typeof countryValue === "string"
                    ? countryValue
                    : countryValue.label
                  : ""
              }
              arrowClosed={arrowClosed}
              arrowOpen={arrowClosed}
            />
            */}
            <AsyncSelect
              cacheOptions
              placeholder={"Izaberite državu..."}
              classNamePrefix={"profileSelect"}
              //options={countryDropDown}
              onChange={(value) => {
                //console.log(value);
                changeCountryValue(value);
              }}
              value={
                countryValue
                  ? countryDropDown
                    .map((el) => {
                      return el.value;
                    })
                    .indexOf(countryValue.value) >= 0
                    ? countryDropDown[
                    countryDropDown
                      .map((el) => {
                        return el.value;
                      })
                      .indexOf(countryValue.value)
                    ]
                    : countryValue
                  : null
              }
              defaultOptions={countryDropDown}
              loadOptions={loadOptions}
              onInputChange={handleInputChange}
            />
            <HorizontalDivider secondary />
          </div>

          <Input
            label={"Telefon"}
            type={"phone"}
            name={"phone"}
            handleChange={inputChangeHandler.bind(this)}
            value={phoneValue}
          />
          <div className={classes.datePickerHolder}>
            Datum rođenja
            <DateInput
              inputChangeHandler={dobInputChangeHandler.bind(this)}
              dayValue={dobDay}
              monthValue={dobMonth}
              yearValue={dobYear}
            />
            {/*
            <DatePicker
              dateFormat="yyyy-MM-dd"
              locale="srLatn"
              calendarStartDay={1}
              selected={dobValue}
              onChange={(date) => changeDOBValue(date)}
              fixedHeight
              maxDate={new Date()}
              placeholderText="Godina-Mesec-Dan"
              strictParsing
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              popperPlacement="top-end"
            />
            <HorizontalDivider secondary />
            */}
          </div>
          <div className={classes.companyHolder}>
            <Input
              label={"Naziv kompanije"}
              name={"cname"}
              type={"text"}
              handleChange={inputChangeHandler.bind(this)}
              value={companyNameValue}
            />

            <Input
              label={"PIB kompanije"}
              name={"cpib"}
              type={"text"}
              handleChange={inputChangeHandler.bind(this)}
              value={companyVATValue}
            />
          </div>
        </div>
        <Button onClick={handleSave} filled={true}>
          Snimite izmene profila
        </Button>
        <br />
        <Button onClick={handleLogout} filled={true}>
          Izlogujte se
        </Button>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
