import classes from "./LoadingAirfantast.module.css";
const LoadingAirfantast = () => {
  return (
    <svg
      version="1.0"
      id="Layer_1"
      xmlns="https://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="512px"
      height="512px"
      viewBox="0 0 512 512"
      style={{enableBackground:"new 0 0 512 512"}}
      className={classes.svg}
    >
      <path
        id="logo_runway"
        className={classes.runway}
        d="M257.25,233.23c5.56-15.7-3.99-32.85-3.99-32.85l-67.01,115.87c15.44,0,29.65-8.41,37.08-21.96
	L257.25,233.23z"
      />
      <g className={classes.plane}>
        <path
          id="logo_plane_path1"
          d="M271.71,321.49l-4.05-27.68h-0.06l-3.59-24.18l-32.09,6.16c-3.32,0.64-6.57,1.12-9.67,1.42
		c-2.26,0.22-4.07,0.31-6.23,0.31c-0.04,0-0.08,0-0.12,0c-4.32,0-9.18-0.51-12.65-4.11c-2.58-2.68-3.79-6.32-5.46-11.35
		c-0.51-1.54-1.09-3.28-1.77-5.18c-3.34-9.36-5.92-18.06-0.8-21.71c2.15-1.53,6.61-3.5,12.4,0.57c2.89,2.03,5.51,5.1,8.04,8.06
		c1.89,2.21,5.01,5.86,6.46,6.39l37.43-9.82l-2.2-14.5l-6.39-32.22l5.05-0.31c14.51-0.88,23.9,2.62,27.17,10.1l0.18,0.42l6,28.69
		l10.68-2.8c9.42-2.5,19.24-1.72,27.64,2.2c4.02,1.88,7.43,4.27,9.35,6.57l0,0c3.64,4.37,3.03,8.26,2.35,10.19
		c-1.54,4.34-6.3,7.6-13.06,8.95l-31.42,6.03l4.69,33.47c0.79,4.42,0.35,12.64-11.2,18.91c-5.79,3.14-11.84,4.41-12.1,4.46
		L271.71,321.49z"
        />
        <path
          id="logo_plane_path2"
          d="M333.64,241.36c-1.35-1.63-4.09-3.66-7.82-5.4c-7.68-3.59-16.45-4.1-24.65-1.93l-15.17,3.98
		l-6.86-32.8c-3.28-7.52-15.69-7.89-22.85-7.46l5.42,27.36l2.82,18.53l-41.85,10.98c-7.33,1.03-16.35-21.94-24.88-15.84
		c-2.43,1.73-0.28,9.13,2.4,16.63c4.74,13.27,4.23,17.73,15.82,17.71c2.01,0,3.68-0.09,5.81-0.29c3.11-0.3,6.2-0.77,9.26-1.36
		l36.6-7.03l1.12,7.4l2.6,17.57h0.06l3.92,26.83c0,0,22.39-4.59,19.82-18.34l-5.28-37.72l35.52-6.82
		C332.96,251.84,338.54,247.24,333.64,241.36"
        />
      </g>
    </svg>
  );
};

export default LoadingAirfantast;