const PassangerIcon = () => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="40.971"
      height="41.702"
      viewBox="0 0 40.971 41.702"
    >
      <g
        id="Group_53"
        data-name="Group 53"
        transform="translate(-5.147 -124.03)"
      >
        <g
          id="Group_52"
          data-name="Group 52"
          transform="translate(23.117 124.03)"
        >
          <path
            id="Path_55"
            data-name="Path 55"
            d="M90.236,166.263v10.845c-.638-.044-.6.346-.6.756q.011,6.913,0,13.826a2.085,2.085,0,0,1-4.075.658,3.389,3.389,0,0,1-.1-.959q-.008-6.67,0-13.339v-.86h-.966v.739q0,6.843,0,13.687a2.1,2.1,0,0,1-2.794,2.138,2.13,2.13,0,0,1-1.383-2.114q-.005-6.843,0-13.687v-.624l-.207.048a3.464,3.464,0,0,1-.349-1.022c-.027-3.12-.016-6.24-.016-9.36v-.732l-.243-.07a2.38,2.38,0,0,0-.247.725c.007,1.526.068,3.051.076,4.577.008,1.567.019,3.136-.055,4.7a1.324,1.324,0,0,1-1.3,1.437,1.512,1.512,0,0,1-1.59-1.343,3.106,3.106,0,0,1-.059-.619q-.065-4.689-.121-9.378a5.874,5.874,0,0,1,.806-3.181,5.053,5.053,0,0,1,3.972-2.332,24.794,24.794,0,0,1,8.839.179,4.621,4.621,0,0,1,3.85,4.47c.08,3.537-.009,7.079-.069,10.618a1.538,1.538,0,0,1-1.587,1.586c-.855-.034-1.367-.624-1.366-1.675,0-2.938.043-5.876.048-8.814a4.194,4.194,0,0,0-.209-.925Z"
            transform="translate(-94.174 -152.214)"
            className="passanger-icon"
          />
          <path
            id="Path_56"
            data-name="Path 56"
            d="M32.012,218.376c0,.476.015.953,0,1.429-.126,3.479-.271,6.956-.382,10.435-.042,1.319,0,2.64-.007,3.96,0,1-.5,1.609-1.32,1.612s-1.325-.6-1.326-1.6q0-4.064,0-8.128v-.735c-.686-.275-.613.178-.614.538q0,4.029-.005,8.058a4.3,4.3,0,0,1-.051.829,1.3,1.3,0,0,1-2.515.157,2.9,2.9,0,0,1-.094-.891,62.554,62.554,0,0,0-.078-7.91c-.341-2.6-.084-5.188-.458-7.794a5.238,5.238,0,0,0-.1.72c0,1.783.017,3.566.009,5.349,0,.848-.529,1.33-1.193,1.1a1.1,1.1,0,0,1-.695-1.167q-.033-1.875-.051-3.751c-.008-.834-.021-1.668-.006-2.5a3.027,3.027,0,0,1,2.575-3.134,16.09,16.09,0,0,1,5.795-.045,3.2,3.2,0,0,1,2.729,3.322c-.013,1.088-.037,2.176-.054,3.264-.014.9-.024,1.806-.037,2.709-.013.885-.341,1.336-.966,1.335s-.887-.368-.886-1.288c0-1.737.014-3.474.012-5.21a4.709,4.709,0,0,0-.1-.682Z"
            transform="translate(-21.117 -194.129)"
            className="passanger-icon"
          />
          <path
            id="Path_57"
            data-name="Path 57"
            d="M102.529,131.29a3.63,3.63,0,1,1,3.6-3.623A3.629,3.629,0,0,1,102.529,131.29Z"
            transform="translate(-111.715 -124.03)"
            className="passanger-icon"
          />
        </g>
        <path
          id="Path_58"
          data-name="Path 58"
          d="M39.681,196.105a2.282,2.282,0,0,1-2.266-2.322,2.324,2.324,0,0,1,2.338-2.267A2.278,2.278,0,0,1,42,193.855,2.246,2.246,0,0,1,39.681,196.105Z"
          transform="translate(-9.057 -52.194)"
          className="passanger-icon"
        />
        <g
          id="Group_54"
          data-name="Group 54"
          transform="translate(-41.029 -239.109)"
        >
          <path
            id="Path_59"
            data-name="Path 59"
            d="M84.856,416.684l-.122,1.261H81.52l-.1-1.262a11.819,11.819,0,0,1-1.321,1.169,2.45,2.45,0,0,1-.955.151c.047-.319-.02-.745.161-.937.661-.7,1.393-1.344,2.121-1.982a4.133,4.133,0,0,1,3.393-.014c.729.637,1.46,1.278,2.123,1.98.188.2.142.621.2.94a2.4,2.4,0,0,1-.951-.133A11.663,11.663,0,0,1,84.856,416.684Z"
            transform="translate(0 -18.16)"
            className="passanger-icon"
          />
          <path
            id="Path_60"
            data-name="Path 60"
            d="M92.507,394.481a1.652,1.652,0,0,1,1.757-1.677,1.7,1.7,0,0,1,1.68,1.691,1.762,1.762,0,0,1-1.739,1.743A1.7,1.7,0,0,1,92.507,394.481Z"
            transform="translate(-11.074)"
            className="passanger-icon"
          />
          <path
            id="Path_61"
            data-name="Path 61"
            d="M106.075,439.771l1.114-.945c1.386.829,1.454,1.037.835,2.446a3.144,3.144,0,0,1-.511,1.058,1.318,1.318,0,0,1-1.055.332c-.206-.059-.31-.618-.376-.969-.03-.16.144-.357.223-.538C106.624,440.428,106.623,440.428,106.075,439.771Z"
            transform="translate(-22.319 -38.144)"
            className="passanger-icon"
          />
          <path
            id="Path_62"
            data-name="Path 62"
            d="M86.36,438.408l1.234,1.049c-.707.416-.489.925-.222,1.443.077.15.211.333.179.466-.084.347-.163.848-.4.963a1.038,1.038,0,0,1-.98-.288,7.958,7.958,0,0,1-.9-2C85.018,439.164,85.9,438.96,86.36,438.408Z"
            transform="translate(-5.041 -37.798)"
            className="passanger-icon"
          />
        </g>
      </g>
    </svg>
  );
};

export default PassangerIcon;