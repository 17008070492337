import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import CalendarIcon from "../../Icons/CalendarIcon";
import CloseSecondary from "../../UI/CloseSecondary";
import SecondaryTitle from "../../UI/SecondaryTitle";
import HorizontalDivider from "../../UI/HorizontalDivider";

import { registerLocale } from "react-datepicker";
import srLatn from "date-fns/locale/sr-Latn";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./CalendarChooser.css";

import ArrowRight from "../../Icons/ArrowRight";
import ArrowLeft from "../../Icons/ArrowLeft";
import SelectTouchIcon from "../../Icons/SelectTouchIcon";
import CalendarDayChooserObject from "./ModuleObjects/CalendarDayChooserObject";
import Button from "../../UI/Button";

import { useDispatch, useSelector } from "react-redux";
import {
  setSecondaryFormContent,
  changeFormPosition,
} from "../../../store/ui-slice";

import { changeDate } from "../../../store/form-slice";

const MonthsShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Maj",
  "Jun",
  "Jul",
  "Avg",
  "Sep",
  "Okt",
  "Nov",
  "Dec",
];
const WeekDays = [
  "Nedelja",
  "Ponedeljak",
  "Utorak",
  "Sreda",
  "Četvrtak",
  "Petak",
  "Subota",
];

const CalendarChooser = (props) => {
  const oneWay = useSelector((state) => state.form.oneWay);

  /* get values from store */
  const startDateSelected = useSelector((state) => state.form.startDate);
  const endDateSelected = useSelector((state) => state.form.endDate);

  const startFlex = useSelector((state) => state.form.startFlex);
  const endFlex = useSelector((state) => state.form.endFlex);

  const dispatch = useDispatch(); 
  registerLocale("srLatn", srLatn);

  /* set init state */
  const [dayOneConfirmed, setDayOneConfirmed] = useState(
    startDateSelected !== null && startFlex !== null ? true : false
  );
  const [dayTwoConfirmed, setDayTwoConfirmed] = useState(
    endDateSelected !== null && endFlex !== null ? true : false
  );

  const [startDate, setStartDate] = useState(startDateSelected);
  const [dateRange, setDateRange] = useState([
    startDateSelected,
    endDateSelected,
  ]);
  const [, endDate] = dateRange;
  //console.log(startDate, endDate);
  const [calendarDisabled, setCalendarDisabled] = useState(false);
  //const [showDate, setShowDate] = useState(new Date("2021/11/2"));

  const [date1Flex, setDate1Flex] = useState(
    startFlex !== null ? startFlex : 0
  );
  const [date2Flex, setDate2Flex] = useState(endFlex !== null ? endFlex : 0);

  const [domReady, setDomReady] = React.useState(false);

  useEffect(() => {
    setDomReady(true);
  }, []);

  useEffect(() => {
    if (!oneWay) {
      if (dayOneConfirmed && dayTwoConfirmed) {
        setCalendarDisabled(true);
      } else {
        setCalendarDisabled(false);
      }
    } else {
      if (dayOneConfirmed) {
        setCalendarDisabled(true);
      } else {
        setCalendarDisabled(false);
      }
    }
  }, [dayOneConfirmed, dayTwoConfirmed, oneWay]);

  const departureDateHandler = (nextValue) => {
    //console.log('departure',nextValue);
    //setTimeout(scrollToBottom,150);
    setStartDate(nextValue);
    /*
    setTimeout(function () {
      setDayOneConfirmed(true);
      changeDepartureDateFlexHandler(0);
      handleConfirmDepartureDate(true);
    }, 250)
    */
  };

  const destinationDateHandler = (nextValue) => {
    console.log(nextValue);
    //setTimeout(scrollToBottom,150);
    if (nextValue[1] === null) {
      setDateRange([startDate, nextValue[0]]);
    } else {
      setDateRange(nextValue);
    }
    /*
    setTimeout(function () {
      setDayTwoConfirmed(true);
      changeDestinationDateFlexHandler(0);
      handleConfirmArrivalDate(true);
    }, 250)
    */
  };

  const handleResetAllDates = () => {
    //console.log('handle reset all ')
    setDateRange([null, null]);
    setStartDate(null);
    setDayOneConfirmed(false);
    setDayTwoConfirmed(false);
    dispatch(changeDate({ type: "startDate", day: null, flex: null }));
    dispatch(changeDate({ type: "endDate", day: null, flex: null }));
  };

  const handleResetArrivalDate = () => {
    const tempDateRange = [...dateRange];
    tempDateRange[1] = null;
    setDateRange(tempDateRange);
    setDayTwoConfirmed(false);
    dispatch(changeDate({ type: "endDate", day: null, flex: null }));
  };

  const handleConfirmDepartureDate = (setDeparture) => {
    //console.log('confirm departure', setDeparture)
    setDayOneConfirmed(setDeparture === null ? false : setDeparture);
    if (setDeparture !== null) {
      dispatch(
        changeDate({
          type: "startDate",
          day: new Date(startDate).getTime(),
          flex: date1Flex,
        })
      );
    } else {
      dispatch(
        changeDate({
          type: "startDate",
          day: null,
          flex: null,
        })
      );
    }
  };

  const handleConfirmArrivalDate = (setArrival) => { 
    //console.log('handleConfirmArrivalDate', 'setArrival', setArrival, endDate)
    setDayTwoConfirmed(setArrival === null ? false : setArrival);
    if (setArrival !== null) {
      dispatch(
        changeDate({
          type: "endDate",
          day: new Date(endDate).getTime(),
          flex: date2Flex,
        })
      );
    } else {
      dispatch(
        changeDate({
          type: "endDate",
          day: null,
          flex: null,
        })
      );
    }
  };

  const closeCalendarHandler = () => {
    dispatch(setSecondaryFormContent(""));
    dispatch(changeFormPosition(0));
  };

  const changeDepartureDateFlexHandler = (data) => {
    //console.log('departure flex', data);
    setDate1Flex(data);
  };
  const changeDestinationDateFlexHandler = (data) => {
    setDate2Flex(data);
  };

  let titleText = "Polazak";
  if (dayOneConfirmed && !oneWay) {
    titleText = "Povratak";
  }

  const curDate = new Date();
  const headerMonth = MonthsShort[curDate.getMonth()];
  const headerToday =
    "Danas: " +
    WeekDays[curDate.getDay()] +
    " " +
    curDate.getDate() +
    ". " +
    MonthsShort[curDate.getMonth()] +
    " " +
    curDate.getFullYear() +
    ".";

  let footerContent = (
    <div className="calendarChooser_infoMessage">
      Obeležite željeni datum polaska
      <SelectTouchIcon />
    </div>
  );

  let firstDate,
    firstDayName,
    firstFullDate,
    secondDate,
    secondDayName,
    secondFullDate;

  if (startDate !== null) {
    firstDate = new Date(startDate);
    firstDayName = WeekDays[firstDate.getDay()];
    firstFullDate =
      firstDate.getDate() +
      ". " +
      MonthsShort[firstDate.getMonth()] +
      " " +
      firstDate.getFullYear() +
      ".";
    if (endDate !== null) {
      secondDate = new Date(endDate);
      secondDayName = WeekDays[secondDate.getDay()];
      secondFullDate =
        secondDate.getDate() +
        ". " +
        MonthsShort[secondDate.getMonth()] +
        " " +
        secondDate.getFullYear() +
        ".";
    }
    footerContent = (
      <div style={{ width: "100%" }}>
        <CalendarDayChooserObject
          type="polazak"
          checkSelectedDate={startDate}
          dayName={firstDayName}
          fullDate={firstFullDate}
          handleConfirmDate={handleConfirmDepartureDate}
          onReset={handleResetAllDates}
          onChangeFlexDate={changeDepartureDateFlexHandler.bind(this)}
          isConfirmed={dayOneConfirmed}
          flex={date1Flex}
        />
        {dayOneConfirmed && endDate === null && !oneWay && (
          <div className="calendarChooser_infoMessage">
            Obeležite željeni datum povratka
            <SelectTouchIcon />
          </div>
        )}
        {endDate !== null && dayOneConfirmed && !oneWay && (
          <CalendarDayChooserObject
            type="povratak"
            checkSelectedDate={endDate}
            dayName={secondDayName}
            fullDate={secondFullDate}
            handleConfirmDate={handleConfirmArrivalDate}
            onReset={handleResetArrivalDate}
            onChangeFlexDate={changeDestinationDateFlexHandler.bind(this)}
            isConfirmed={dayTwoConfirmed}
            flex={date2Flex}
          />
        )}
        {((dayOneConfirmed && oneWay) ||
          (dayOneConfirmed && dayTwoConfirmed && !oneWay)) && (
          <div style={{ marginTop: "1rem" }}>
            <Button filled onClick={closeCalendarHandler}>
              Dalje
            </Button>
          </div>
        )}
      </div>
    );
  }

  const customHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) =>
    ReactDOM.createPortal(
      <div className="react-calendar__navigation" style={{opacity: (dayOneConfirmed && dayTwoConfirmed) ? 0.3 : 1}}>
        <div className="react-calendar__navigation__label__prevMonthLabelText">
          {
            MonthsShort[
              new Date(date).getMonth() < 11 ? new Date(date).getMonth() ===0 ? 11 : new Date(date).getMonth() - 1 : 10
            ]
          }
        </div>
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          {<ArrowLeft />}
        </button>
        <div className="react-calendar__navigation__label__labelText">
          {MonthsShort[new Date(date).getMonth()] +
            " " +
            new Date(date).getFullYear().toString().substr(-2)}
        </div>
        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          {<ArrowRight />}
        </button>
        <div className="react-calendar__navigation__label__nextMonthLabelText">
          {
            MonthsShort[
              new Date(date).getMonth() >= 0 ? new Date(date).getMonth() === 11 ? 0 : new Date(date).getMonth() + 1 : 11
            ]
          }
        </div>
      </div>,
      document.getElementById("navigationPlaceholder")
    );

  const calendarRef = useRef(null);

  /*
  const scrollToBottom = () => {
    calendarRef.current?.scrollIntoView({behavior: "smooth"})
    //window.scrollTo(0,document.body.scrollHeight);
  };
  */

  return (
    <div className={`calendarChooser ${calendarDisabled ? " disabled" : ""}`}>
      <CloseSecondary />
      <SecondaryTitle icon={<CalendarIcon />} text={titleText} />
      <div className="calendarChooser_header light">
        <div className="calendarChooser_header_month">
          {/*headerMonth + " " + curDate.getFullYear() */}
        </div>
        <div
          className="calendarChooser_header_fullToday"
          //          onClick={clickNowHandler}
        >
          {headerToday}
        </div>
        <HorizontalDivider thick />
      </div>
      <div className="calendarChooser_wrapper">
        {oneWay && (
          <DatePicker
            calendarClassName="calendarChooser_custom"
            selected={null}
            startDate={startDate}
            onChange={departureDateHandler}
            isClearable={true}
            inline
            locale="srLatn"
            calendarStartDay={1}
            renderCustomHeader={domReady ? customHeader : () => {}}
            fixedHeight
            minDate={new Date()}
            disabledKeyboardNavigation={true}
          />
        )}
        {!dayOneConfirmed && !oneWay && (
          <DatePicker
            calendarClassName="calendarChooser_custom"
            selected={startDate}
            startDate={startDate}
            onChange={departureDateHandler}
            isClearable={true}
            inline
            locale="srLatn"
            calendarStartDay={1}
            renderCustomHeader={domReady ? customHeader : () => {}}
            fixedHeight
            minDate={new Date()}
            disabledKeyboardNavigation={true}
          />
        )}
        {dayOneConfirmed && !oneWay && (
          <DatePicker
            calendarClassName="calendarChooser_custom"
            selected={null}
            startDate={startDate}
            endDate={endDate}
            selectsRange={true}
            onChange={destinationDateHandler}
            isClearable={true}
            inline
            locale="srLatn"
            calendarStartDay={1}
            renderCustomHeader={domReady ? customHeader : () => {}}
            fixedHeight
            minDate={startDate}
            disabledKeyboardNavigation={true}
          />
        )}
      </div>
      <div id="navigationPlaceholder" className="calendarChooser_custom"></div>
      <div className="calendarChooser_divider">
        <HorizontalDivider thick />
      </div>
      <div className="calendarChooser_footer">{footerContent}</div>
      <div className="calendarScrollTo" ref={calendarRef} />
    </div>
  );
};

export default CalendarChooser;
