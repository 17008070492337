import React from "react";

import "./CalendarSelector.css";

const MonthsShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Maj",
  "Jun",
  "Jul",
  "Avg",
  "Sep",
  "Okt",
  "Nov",
  "Dec",
];

const CalendarSelector = (props) => {
  let calendarDay = "--";
  let calendarMonth = "---";
  let calendarYear = "--";

  let flexibleText = props.flexibleText;

  //console.log(props.title, props.data);

  if (props.data) {
    if (props.data.date !== null /*&& props.data.flex !== null*/) {
      const dataDate = new Date(props.data.date);
      const day = dataDate.getDate();
      calendarDay = day < 10 ? "0" + day : day;
      calendarMonth = MonthsShort[dataDate.getMonth()].toUpperCase();
      calendarYear = dataDate.getFullYear().toString().slice(-2);
      flexibleText =
        props.data.flex === 0 || props.data.flex===null
          ? "Tačan datum"
          : "+ / - " + props.data.flex + " Dana";
    }
  }

  return (
    <div className="calendarSelector" onClick={props.onClick}>
      <div className="calendarSelector_title">{props.title}</div>
      <div className={`calendarSelector_date oswald bold ${props.data.date!==null ? 'text-highlighted': ''}`}>
        <div className="calendarSelector_date_day">{calendarDay}</div>
        <div className="calendarSelector_date_month">{calendarMonth}</div>
        <div className="calendarSelector_date_year">{calendarYear}</div>
      </div>
      <div className={`calendarSelector_flexibleDays ${props.data.flex===null || props.data.flex===0 ? 'text-highlighted' : ''}`}>{flexibleText}</div>
    </div>
  );
};

export default CalendarSelector;
