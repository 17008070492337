const MainLogo = () => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="79.267"
      height="70.057"
      viewBox="0 0 79.267 70.057"
    >
      <defs>
        <filter
          id="Path_3"
          x="0.971"
          y="0"
          width="78.296"
          height="70.057"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="6" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Component_2_1"
        data-name="Component 2 – 1"
        transform="translate(0 0)"
      >
        <path
          id="Path_1"
          data-name="Path 1"
          d="M29.829,39.659l.924-.179a1.908,1.908,0,0,0-.1.193l-3.875,6.991h-.055L25.32,49.2A22.075,22.075,0,0,1,5.96,60.66L17.8,40.128a13.657,13.657,0,0,0,3.64.427H21.5a32.626,32.626,0,0,0,3.268-.165,48.129,48.129,0,0,0,5.061-.731"
          transform="translate(-5.96 -1.117)"
          fill="#fff"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M37.486,27.677,34.921,32.31,20.58,36.074l13.8-23.924Z"
          transform="translate(-1.455 -9.119)"
          fill="#fff"
        />
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_3)">
          <path
            id="Path_3-2"
            data-name="Path 3"
            d="M78.3,31.9A11.717,11.717,0,0,0,74.392,29.2a18.052,18.052,0,0,0-12.309-.968l-7.573,2L51.08,13.756c-1.636-3.78-7.837-3.966-11.412-3.741l2.7,13.742,1.412,9.3-20.9,5.518c-3.668.517-8.166-11.023-12.428-7.959-1.214.875-.145,4.59,1.2,8.344,2.362,6.659,2.111,8.9,7.9,8.887a27.428,27.428,0,0,0,2.9-.146c1.557-.146,3.1-.385,4.618-.677L45.367,43.5l.567,3.714,1.293,8.821h.026l1.953,13.477S60.394,67.2,59.1,60.3L56.462,41.361l17.745-3.422c3.76-.769,6.544-3.077,4.09-6.036"
            transform="translate(-4.46 -9.95)"
            fill="#ffc316"
          />
        </g>
      </g>
    </svg>
  );
};

export default MainLogo;
