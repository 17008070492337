const MenuIcon = () => {
	return (
		<svg
		  xmlns="https://www.w3.org/2000/svg"
		  width="40"
		  height="26.667"
		  viewBox="0 0 40 26.667"
		>
		  <path
			id="hamburger-icon"
			data-name="hamburger-icon"
			d="M3,32.667H43V28.222H3ZM3,21.556H43V17.111H3ZM3,6v4.444H43V6Z"
			transform="translate(-3 -6)"
		  />
		</svg>
	  );
}

export default MenuIcon;