const BankIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20">
			<path 
			className="payIcon bankIcon"
			d="M6.5,10h-2v7h2Zm6,0h-2v7h2ZM21,19H2v2H21Zm-2.5-9h-2v7h2Zm-7-6.74L16.71,6H6.29L11.5,3.26M11.5,1,2,6V8H21V6Z" transform="translate(-2 -1)" />
		</svg>
	);
};

export default BankIcon;