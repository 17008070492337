import React from "react";

import "./AirportSelector.css";

const AirportSelector = (props) => {
  let airportShortCode = "---";
  let airportCity = "Odaberite";
  let airportCountry = "aerodrom";

  if (props.airportData !== false) {
    airportShortCode = props.airportData.shortcode;
    airportCity = props.airportData.city;
    airportCountry = props.airportData.country;
  }

  // temp click handler
  const clickHandler = () => {
    props.clickAirport();
  };

  return (
    <div className={"airportSelector"} onClick={clickHandler}>
      <div className={"airportSelector_title"}>{props.headline}</div>
      <div className={"airportSelector_airportCode oswald"}>
        {airportShortCode}
      </div>
      <div className={"airportSelector_destination_city bold"}>{airportCity}</div>
      <div className={"airportSelector_destination_country bold"}>{airportCountry}</div>
    </div>
  );
};

export default AirportSelector;
