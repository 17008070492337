import React from "react";
import CancelRounded from "../Icons/CancelRounded";

import { useDispatch } from "react-redux";
import {
  setSecondaryFormContent,
  changeFormPosition,
} from "../../store/ui-slice";

import classes from "./CloseSecondary.module.css";

const CloseSecondary = (props) => {
  const dispatch = useDispatch();

  const resetSecondaryHandler = () => {
    dispatch(setSecondaryFormContent(""));
    dispatch(changeFormPosition(0));
  };

  return (
    <div className={classes.closeSecondary} onClick={resetSecondaryHandler}>
      <CancelRounded />
    </div>
  );
};

export default CloseSecondary;
