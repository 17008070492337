const PlaneDeparture = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
    >
      <path
        className="resultIcon"
        d="M35,25.1V21.8L20.975,13.55V4.475a2.475,2.475,0,1,0-4.95,0V13.55L2,21.8v3.3l14.025-4.125V30.05L11.9,32.525V35l6.6-1.65L25.1,35V32.525L20.975,30.05V20.975Z"
        transform="translate(35 -2) rotate(90)"
      />
    </svg>
  );
};

export default PlaneDeparture;