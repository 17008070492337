import React from "react";
import Button from "../../UI/Button";
import HorizontalDivider from "../../UI/HorizontalDivider";
import VerticalDivider from "../../UI/VerticalDivider";

import { useSelector, useDispatch } from "react-redux";
import { changeDirection } from "../../../store/form-slice";

import classes from "./DirectionModule.module.css";

const DirectionModule = (props) => {
  const dispatch = useDispatch();
  const oneWay = useSelector((state) => state.form.oneWay);

  const changeDirectionHandler = (props) => {
    if (props.active) return;
    dispatch(changeDirection());
  };

  return (
    <div className={classes.directionModule}>
      <Button active={!oneWay} onClick={changeDirectionHandler}>
        Povratni let
      </Button>
      <VerticalDivider />
      <Button active={oneWay} onClick={changeDirectionHandler}>
        Jedan pravac
      </Button>
      <HorizontalDivider />
    </div>
  );
};

export default DirectionModule;
