import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { setThomalexObject } from "../../../store/form-slice.js";
import { changeFormPosition, setSecondaryFormContent } from "../../../store/ui-slice.js";

import ManIcon from "../../Icons/ManIcon.js";
import CancelRounded from "../../Icons/CancelRounded.js";

import VerticalDivider from "../../UI/VerticalDivider.js";

import classes from "./ResultModule.module.css";

const ResultModuleHeader = () => {
  const startAirportSelected = useSelector(
    (state) => state.form.startAirportSelected
  );
  const destAirportSelected = useSelector(
    (state) => state.form.destAirportSelected
  );
  const startDate = useSelector((state) => state.form.startDate);
  const endDate = useSelector((state) => state.form.endDate);
  const adults = useSelector((state) => state.form.adults);

  const dispatch = useDispatch();

  const handleCancelClick = () => {
    dispatch(setSecondaryFormContent(""))
    dispatch(setThomalexObject(null));
    dispatch(changeFormPosition(0))
  }

  return (
    <div className={classes.resultHeader}>
      <div className={classes.resultHeaderElement}>
        {startAirportSelected.shortcode} - {destAirportSelected.shortcode}
      </div>
      <VerticalDivider />
      <div className={classes.resultHeaderElement}>
        {new Date(startDate)
          .toLocaleDateString("sr-Latn-RS", {
            year: "2-digit",
            month: "short",
            day: "2-digit",
          })
          .replace(/\./g, "")}
        &nbsp;-&nbsp;
        {new Date(endDate)
          .toLocaleDateString("sr-Latn-RS", {
            year: "2-digit",
            month: "short",
            day: "2-digit",
          })
          .replace(/\./g, "")}
      </div>
      <VerticalDivider />
      <div className={classes.resultHeaderElement}>
		  <ManIcon className={classes.manIcon} />
		  <div className={classes.adultsHolder}>
		  	{adults < 10 ? "0" + adults : adults}
		  </div>
		  <div className={classes.cancelIcon} onClick={handleCancelClick}>
		  	<CancelRounded />
		  </div>
	  </div>
    </div>
  );
};

export default ResultModuleHeader;
