import React, { useState } from "react";
import PassangerIcon from "../../Icons/PassangerIcon";
import PassangerChooserObject from "./ModuleObjects/PassangerChooserObject";
import HorizontalDivider from "../../UI/HorizontalDivider";
import Button from "../../UI/Button";
import CloseSecondary from "../../UI/CloseSecondary";
import SecondaryTitle from "../../UI/SecondaryTitle";

import { useDispatch, useSelector } from "react-redux";
import { changePassangers } from "../../../store/form-slice";
import {
  setSecondaryFormContent,
  changeFormPosition,
} from "../../../store/ui-slice";
import "./PassangerChooser.css";

const PassangerChooser = (props) => {
  const dispatch = useDispatch();

  const adults = useSelector((state) => state.form.adults);
  const children = useSelector((state) => state.form.children);
  const babies = useSelector((state) => state.form.babies);

  const [odrasli, setOdrasli] = useState(adults);
  const [deca, setDeca] = useState(children);
  const [bebe, setBebe] = useState(babies);

  const changeOdrasliHandler = (nr) => {
    const setTo = odrasli + nr;
    if (setTo>0) {
      setOdrasli(setTo);
    }
  };
  const changeDecaHandler = (nr) => {
    const setTo = deca + nr;
    if (setTo>=0) {
      if (nr>0 && (deca < odrasli*2)) {
        setDeca(setTo);
      } else if (nr<0) {
        setDeca(setTo);
      }
    }
  };
  const changeBebeHandler = (nr) => {
    const setTo = bebe + nr;
    if (setTo>=0) {
      if (nr>0 && (bebe < odrasli)) {
        setBebe(setTo);
      } else if (nr<0) {
        setBebe(setTo);
      }
    }
  };

  const submitPassangerHandler = () => {
    dispatch(
      changePassangers({
        adults: odrasli,
        children: deca,
        babies: bebe,
      })
    );
    dispatch(setSecondaryFormContent(""));
    dispatch(changeFormPosition(0));
  };

  return (
    <div className="passangerChooser">
      <CloseSecondary />
      <SecondaryTitle
        icon={<PassangerIcon />}
        text={"Izaberite broj putnika"}
      />
      <div className="passangerChooser_objectHolder">
        <PassangerChooserObject
          title="Odrasli (12+)"
          onChangePassanger={changeOdrasliHandler.bind(this)}
          data={odrasli}
        />
        <PassangerChooserObject
          title="Deca (2-12)"
          onChangePassanger={changeDecaHandler.bind(this)}
          data={deca}
        />
        <PassangerChooserObject
          title="Bebe (2-12)"
          onChangePassanger={changeBebeHandler.bind(this)}
          data={bebe}
        />
        <HorizontalDivider />
      </div>
      <div className="mainButtonHolder">
        <Button filled={true} onClick={submitPassangerHandler}>
          Potvrdite broj putnika
        </Button>
      </div>
    </div>
  );
};

export default PassangerChooser;
