/**
 * creates and returns a validation rule object that
 * is used by useForm hook to validate the form inputs
 *
 * @param {string} ruleName - name of the validation rule
 * @param {string} errorMessage - message to display
 * @param {function} validateFunc - validation function
 */
function createValidationRule(ruleName, errorMessage, validateFunc) {
  return {
    name: ruleName,
    message: errorMessage,
    validate: validateFunc,
  };
}

export function requiredRule(inputName) {
  return createValidationRule(
    "required",
    `${inputName} polje je obavezno`,
    (inputValue, formObj) => inputValue.length !== 0
  );
}

export function emailRule(inputName) {
  return createValidationRule(
    "isEmail",
    `${inputName} nije validan`,
    (inputValue, formObj) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(inputValue).toLowerCase())
  );
}

export function minLengthRule(inputName, minCharacters, label) {
  return createValidationRule(
    "minLength",
    `${label} treba da sadrži bar ${minCharacters} karaktera`,
    (inputValue, formObj) => inputValue.length >= minCharacters
  );
}

export function maxLengthRule(inputName, maxCharacters, label) {
  return createValidationRule(
    "minLength",
    `${label} ne sme da sadrži više od ${maxCharacters} karaktera`,
    (inputValue, formObj) => inputValue.length <= maxCharacters
  );
}

export function passwordMatchRule() {
  return createValidationRule(
    "passwordMatch",
    `lozinke se ne podudaraju`,
    (inputValue, formObj) => inputValue === formObj.password.value
  );
}
