import React from "react";
import Button from "../../UI/Button";

import classes from "./ResultModule.module.css";

const ResultModuleFilters = (props) => {
  return (
    <div className={classes.resultFilters}>
    {/*
      <Button outline onClick={() => {}} className="filterButton">
        Filter 1
      </Button>
      <Button outline onClick={() => {}} className="filterButton">
        Filter 2
      </Button>
      <Button outline onClick={() => {}} className="filterButton">
        Filter 3
      </Button>
    */}
    </div>
  );
};

export default ResultModuleFilters;
