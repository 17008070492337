const CreditCardIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
			<path d="M20,4H4A1.985,1.985,0,0,0,2.01,6L2,18a1.993,1.993,0,0,0,2,2H20a1.993,1.993,0,0,0,2-2V6A1.993,1.993,0,0,0,20,4Zm0,14H4V12H20ZM20,8H4V6H20Z" 
			transform="translate(-2 -4)" 
			className="payIcon ccIcon"
			/>
		</svg>
	);
};

export default CreditCardIcon;
