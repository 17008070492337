import React from "react";
import ReactDOM from "react-dom";

import "./SideDrawer.css";

const Backdrop = (props) => {
  return (
    <div
      className={"sideDrawer-backdrop"}
      onClick={props.closeSidebarHandler}
    />
  );
};
const MenuContent = (props) => {
  const activeThemeClass =
    "sideDrawer-menu " +
    props.activeTheme +
    (props.sideMenuVisible ? " active" : "");

  return (
    <div className={activeThemeClass}>
      <div>MENU</div>
    </div>
  );
};

const portalElement = document.getElementById("overlays");

const SideDrawer = (props) => {
  const sideMenuShown = props.sideMenuShown;

  const closeSidebarHandler = props.closeSidebarHandler;

  return (
    <React.Fragment>
      {sideMenuShown &&
        ReactDOM.createPortal(
          <Backdrop closeSidebarHandler={closeSidebarHandler} />,
          portalElement
        )}
      {ReactDOM.createPortal(
        <MenuContent
          activeTheme={props.activeTheme}
          sideMenuVisible={sideMenuShown}
        >
          MENU
        </MenuContent>,
        portalElement
      )}
    </React.Fragment>
  );
};

export default SideDrawer;
