import { configureStore } from '@reduxjs/toolkit';
import uiReducer from './ui-slice';
import formReducer from './form-slice';
import authReducer from './auth-slice';

export default configureStore({
  reducer: {
    ui: uiReducer,
    form: formReducer,
    auth: authReducer
  },
});
