import React, { useCallback, useEffect, useState } from "react";

import ArrowRight from "../../Icons/ArrowRight";
import CabinLuggageIcon from "../../Icons/CabinLuggageIcon";
import CalendarIcon from "../../Icons/CalendarIcon";
import ClockIcon from "../../Icons/ClockIcon";
import SandClock from "../../Icons/SandClock";
import SandClockFilled from "../../Icons/SandClockFIlled";

import classes from "./ResultModule.module.css";
import { useSelector } from "react-redux";

const FlightDetail = (props) => {
  const itineraryID = useSelector((state) => state.form.flightID);
  const sessionID = useSelector((state) => state.form.sessionID);

  const [additionalData, setAdditionalData] = useState({});

  const getTermsOfTariff = useCallback(async () => {
    if (itineraryID && sessionID) {
      const termsRes = await fetch(
        `https://${process.env.REACT_APP_API_URL}/api/v1/air/references/flight/rules?itineraryId=${itineraryID}&sessionId=${sessionID}`
      );
      const response = await termsRes.json();
      setAdditionalData(response);
    }
  }, [itineraryID, sessionID]);

  useEffect(() => {
    getTermsOfTariff();
  }, [getTermsOfTariff]);

  const departureDate = new Date(
    props.firstSegment.DepartureDate
  ).toLocaleDateString("sr-Latn-RS", {
    month: "long",
    day: "2-digit",
    weekday: "long",
    year: "numeric",
  });

  // departure segments map
  let departureFlightSegmentDisplay = props.flightSegmentData.map((el, ind) => {
    let nextFlight, nextAirports, nextFlightDay;
    let nextFlightOutput;

    if (ind <= props.flightSegmentData.length - 2) {
      let nextDD = new Date(
        props.segmentArray.FlightSegments[ind + 1].DepartureDate
      ).getTime();
      let thisAD = new Date(
        props.segmentArray.FlightSegments[ind].ArrivalDate
      ).getTime();
      let diff = nextDD - thisAD;
      nextFlight = new Date(diff)
        .toLocaleTimeString("sr-Latn-RS", {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "UTC",
        })
        .replace(":", ".");
      nextAirports =
        props.segmentArray.FlightSegments[ind + 1].DepartureAirport +
        "-" +
        props.segmentArray.FlightSegments[ind + 1].ArrivalAirport;
      nextFlightOutput = (
        <>
          <SandClock />
          <div className={classes.nextFlightText}>
            Vreme do sledećeg leta: <br />
            <strong className="oswald">{nextAirports}&nbsp;</strong>{" "}
            {nextFlight}
            <ArrowRight />
          </div>
        </>
      );
    } else {
      let thisAD = new Date(props.firstSegment.DepartureDate).getTime();
      let nextDD = new Date(
        props.segmentArray.FlightSegments[
          props.flightSegmentData.length - 1
        ].ArrivalDate
      ).getTime();
      let diff = nextDD - thisAD;
      nextFlightDay = new Date(diff).toLocaleDateString("sr-Latn-RS", {
        day: "numeric",
      });
      nextFlight = new Date(diff)
        .toLocaleTimeString("sr-Latn-RS", {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "UTC",
        })
        .replace(":", ".");
      nextFlightOutput = (
        <>
          <SandClockFilled />
          &nbsp;
          <div className={classes.nextFlightText}>
            <strong>
              Ukupno vreme putovanja:{" "}
              {nextFlightDay > 0 ? nextFlightDay + "d " : ""}
              {nextFlight}
            </strong>
          </div>
        </>
      );
    }

    return (
      <div className={classes.segment} key={ind}>
        <div className={[classes.detailsBlock + " detailsBlock"]}>
          <span className={"bold"}>
            {el.MarketingAirlineName} | {el.RouteNumber}
          </span>
          <br />
          <span>{el.Aircraft}</span>
        </div>
        <hr className={[classes.detailsBreak + " detailsBreak"]} />
        <div className={[classes.detailsBlock + " detailsBlock"]}>
          <span>Od: </span>
          <>
            {props.airportNames[el.DepartureAirport] ? (
              <>
                {`${props.airportNames[el.DepartureAirport].slice(
                  0,
                  props.airportNames[el.DepartureAirport].indexOf(
                    el.DepartureAirport
                  )
                )}`}
                <b>{el.DepartureAirport}</b>
                {props.airportNames[el.DepartureAirport].slice(
                  props.airportNames[el.DepartureAirport].indexOf(
                    el.DepartureAirport
                  ) + el.DepartureAirport.length
                )}
              </>
            ) : (
              el.DepartureAirportName
            )}
          </>
        </div>
        <div className={classes.resultBreak}></div>
        <div className={[classes.detailsBlockFlex + " detailsBlock"]}>
          <CalendarIcon />
          <span>
            {new Date(el.DepartureDate)
              .toLocaleDateString("sr-Latn-RS", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
              .replaceAll(".", "-")
              .slice(0, -1)}
          </span>
          <ClockIcon />
          <span>
            {new Date(el.DepartureDate)
              .toLocaleTimeString("sr-Latn-RS", {
                hour: "2-digit",
                minute: "2-digit",
              })
              .replace(":", ".")}
          </span>
        </div>
        <div className={classes.resultBreak}></div>
        <div className={[classes.detailsBlock + " detailsBlock"]}>
          <span>Do: </span>
          <>
            {props.airportNames[el.ArrivalAirport] ? (
              <>
                {`${props.airportNames[el.ArrivalAirport].slice(
                  0,
                  props.airportNames[el.ArrivalAirport].indexOf(
                    el.ArrivalAirport
                  )
                )}`}
                <b>{el.ArrivalAirport}</b>
                {props.airportNames[el.ArrivalAirport].slice(
                  props.airportNames[el.ArrivalAirport].indexOf(
                    el.ArrivalAirport
                  ) + el.ArrivalAirport.length
                )}
              </>
            ) : (
              el.ArrivalAirportName
            )}
          </>
          {/*<strong>{props.airportNames[el.ArrivalAirport] ? props.airportNames[el.ArrivalAirport] : el.ArrivalAirportName}</strong>*/}
        </div>
        <div className={classes.resultBreak}></div>
        <div className={[classes.detailsBlockFlex + " detailsBlock"]}>
          <CalendarIcon />
          <span>
            {new Date(el.ArrivalDate)
              .toLocaleDateString("sr-Latn-RS", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
              .replaceAll(".", "-")
              .slice(0, -1)}
          </span>
          <ClockIcon />
          <span>
            {new Date(el.ArrivalDate)
              .toLocaleTimeString("sr-Latn-RS", {
                hour: "2-digit",
                minute: "2-digit",
              })
              .replace(":", ".")}
          </span>
        </div>
        <div className={classes.resultBreak}></div>
        <div className={[classes.detailsBlock + " detailsBlock"]}>
          Vreme leta:{" "}
          <strong className={"oswald"}>
            {el.DepartureAirport}-{el.ArrivalAirport}
          </strong>{" "}
          {el.Duration.split(":")
            .map((el, ind) => {
              return parseInt(el) > 0 ? el + (ind > 0 ? "m" : "h ") : "";
            })
            .join("")
            .replace(/(\d{1})\./g, "$1d ")}
        </div>
        <div className={classes.resultBreak}></div>
        <div
          className={[classes.detailsNextFlight + " detailsBlock nextFlight"]}
        >
          {nextFlightOutput}
        </div>
        <div className={classes.resultBreak}></div>
      </div>
    );
  });

  return (
    <React.Fragment>
      <div className={classes.resultCardHeader + " resultCardHeader"}>
        <h4>
          {props.headerLabel} / <span>{departureDate}</span>
        </h4>
      </div>
      <div className={classes.detailsBody}>
        {departureFlightSegmentDisplay}
        <div className={[classes.class + " resultClass bold"]}>
          {props.classType}
        </div>
        <span className={classes.cabinLuggage + " luggage"}>
          <CabinLuggageIcon />
          <span>Ručni prtljag {props.cabinLuggage}x8kg</span>
        </span>
        {props.givenBaggage}
        <br />
        {props.airlineBaggageInfo}
        {props.seatMap ? <br /> : null}
        {props.seatMap}
        {props.seatMap ? <br /> : null}
      </div>
      <div className={[classes.roundedButton + " roundedButton bold"]}>
        Uslovi tarife
      </div>
    </React.Fragment>
  );
};

export default FlightDetail;
